import { useDispatch } from 'react-redux';
import { addComment } from "../../store/comments";
import { APP_COLORS } from "../../styles";

import Button from "../common/Button";
import TextField from "../common/textField";

function AddCommentBox({ style, queryId, onClose }){
    
  const dispatch = useDispatch();
  return (
    <div style={{...addCommentContainerStyles, ...style}}>
        <TextField
            multiLine
            name={'comment'}
            type={'text'}
            placeholder={'Add Comment'}
            // onTextChange={handleChange}
            onEnter={(text) => {
                console.log(text)
                let isValid = text && text.length;
                if(isValid){
                    dispatch(addComment({
                        query_id : queryId,  
                        text: text
                    }))
                    onClose()
                }else{

                }
            }}
            style={{ 
                background: APP_COLORS.SECONDARY_BACKGROUND,
                color: APP_COLORS.PRIMARY_TEXT , 
                marginLeft:6,
                width:'calc( 100% - 16px )'   
            }}
            
            autocomplete={"off"}
        />
    </div>
  );
}
export default AddCommentBox;

const addCommentContainerStyles = {
  position: 'relative',
  marginTop:10,
  bottom:0,
}
