import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import { getAllApprovedQueries , selectApprovedQueries, selectApprovedQueriesSearchString, selectIsFetchingApprovedQueries} from '../store/query';
import { ROLE } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';
import {  Outlet } from 'react-router-dom';



export default function ApprovedQueries({ }) {
  const dispatch = useDispatch();
  let role = useSelector(selectUserData).role;
  let approvedQueriesSearchString = useSelector(selectApprovedQueriesSearchString)
  let approvedQueries = useSelector(selectApprovedQueries);
  let isFetchingApproved = useSelector(selectIsFetchingApprovedQueries);

  
  useEffect(() => {
    if (role && (role == ROLE.Admin || role == ROLE.Management || ROLE.Contractor)) {
      dispatch(getAllApprovedQueries())
    }
  }, [])

  const onSearchEnter= (queryString) => {
    console.log('APPROVED QUERIES',queryString)
    dispatch(getAllApprovedQueries(queryString))

  }

  return (<div style={{ ...pageStyles, ...styles.container }}>
      <Section
        title={'Approved Queries'}
        content={
          <>
          { approvedQueries && 
          <QueryListView 
          role={role} 
          data={approvedQueries}  
          isFetching = {isFetchingApproved}
          onSearchEnter = {onSearchEnter}
          searchString = {approvedQueriesSearchString}
          />}
          <Outlet/>
          </>
        }
      />

  </div>)
}


const styles = {
  container: {

  },
}
