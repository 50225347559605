import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import { getQueriesByDateRange,getAllInventory, selectAllInventory, selectIsFetchingInventory, selectInventorySearchString, getInventoryById } from '../store/query';
import { ROLE } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';
import {  Outlet, useNavigate } from 'react-router-dom';
import { getInventoryManagers, selectIM } from '../store/app';

export default function Inventory ({}){
  const dispatch = useDispatch();
  let user = useSelector(selectUserData);
  let role = user?.role;
  let searchString = useSelector(selectInventorySearchString);
  let inventory = useSelector(selectAllInventory);
  let isFetchingInventory = useSelector(selectIsFetchingInventory);
  let navigate = useNavigate()

  useEffect(() => {
    if (role && (role == ROLE.Admin || role ==  ROLE.PropertyManager || user.role == ROLE.DispositionManager )) {
      dispatch(getAllInventory())
    }
    if(role && role == ROLE.InventoryManager){
      dispatch(getInventoryById('',user.id))
    }
  }, [])

  const onSearchEnter = (queryString) => {
    console.log('Inventory', queryString)
    if (role && (role == ROLE.Admin || role ==  ROLE.PropertyManager )) {
      dispatch(getAllInventory(queryString))
    }
    if(role && role == ROLE.InventoryManager){
      dispatch(getInventoryById(queryString,user.id))
    }
  }
  const onDateFilter = (start, end, view ) => {
    if(start && end){
      dispatch(getQueriesByDateRange({ start,end,view, userId:user.id, role}))
    }else if(!start && !end){
      if (role && (role == ROLE.Admin || role ==  ROLE.PropertyManager || user.role == ROLE.DispositionManager )) {
        dispatch(getAllInventory(''))
      }
      if(role && role == ROLE.InventoryManager){
        dispatch(getInventoryById('',user.id))
      }
    }
  }

  return (<div style={{ ...pageStyles, ...styles.container }}>
    <Section
      title={'Inventory'}
      content = {
        <>
        {inventory && 
        <QueryListView 
        role={role} 
        data={inventory}  
        isFetching = {isFetchingInventory}
        onSearchEnter={onSearchEnter}
        searchString = {searchString}
        onDateFilter={onDateFilter}
        />}
        <Outlet/>
        </>
      }
      rightButtons={[
        user?.role == ROLE.InventoryManager && { 
          onClick: () => { navigate('/dashboard/queries/add', { state: {inventory:true}})},
          label: 'Add a Inventory'}
        ]}
      />
</div>)
}

const styles = { 
  container:{
   
},
}

