import { createSlice } from '@reduxjs/toolkit'
import { deleteCSVCollectionInfo } from './app';
import * as api from '../services/firebase/api';
import { deleteDoc, doc, serverTimestamp, } from "firebase/firestore";
import { transformCSVData } from './utils';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../services/firebase/config';
import { selectUserData } from './user';
import { ROLE } from '../constants';


const initialState = {
    uploadingCSVStatus : null,
    csv_queries: {},
    csvHotDeals:[]
}

const csvSlice = createSlice({
  name: 'csv',
  initialState,
  reducers: {
    setUploadingCSVStatus( state, action) {
        state.uploadingCSVStatus = action.payload.status
      },
      resetUploadingCSV(state, action) {
        state.uploadingCSVStatus = null
      },
      setCSVQueries(state,action) {
        let {id, data} = action.payload
        state.csv_queries[id] = data
      },
      deleteCSVQueries(state,action) {
        state.csv_queries[action.payload]  = null
      },
      setDeleteJobStatus(state, action) {
        state.deleteJobStatus = action.payload
      },
      addedToCSVHotDeal(state, action) {
        let {id, item, removeItem } = action.payload
        let csvCollection = state.csv_queries[id]
        let index = csvCollection.findIndex(c => c.index.value == removeItem.index.value);
        csvCollection.splice(index, 1)
        state.csv_queries[id] = csvCollection

        let csvHotDeals = state.csvHotDeals 
        state.csvHotDeals = [...csvHotDeals, item ]
      },
      setHotDeals(state, action) {
        state.csvHotDeals = action.payload
      }
   
  },
  
})

export default csvSlice.reducer

export const {

    //CSV UPLOADING QUERIES
  setUploadingCSVStatus,
  resetUploadingCSV,
  //CSV
  setCSVQueries,
  deleteCSVQueries,
  setDeleteJobStatus,
  addedToCSVHotDeal,
  setHotDeals

} = csvSlice.actions





//************************************  ///************************************ */
//****************************** CSV QUERIES// *///***************************** */
//************************************ *///************************************* */


export const addFileToFirebase = ( jsonToUpload, name ) => async( dispatch, getState ) => {
    
    dispatch(setUploadingCSVStatus({status:'processing'}))
    
    console.log('data', jsonToUpload)
    let promises = []
    let collectionName
    let milliseconds =  Date.now()
    for(let index = 0; index< jsonToUpload.length; index ++){
      let element = jsonToUpload[index];
      // element['old_query'] = true;
      
      collectionName = name.split('_')[0].replace(/\s+/g, '').substring(0,10)
      collectionName = collectionName +'_'+ milliseconds
      promises.push( api.addDocument({ collectionName: collectionName , data : element}))
    }
    Promise.all(promises)
    .then(async(newPromises) => {
      console.log(newPromises)
  
      await api.setDocById({collection:'csv_queries_collections', data:{
        id: collectionName,
        name: name,
        created : serverTimestamp(),
      }})
    
      dispatch(setUploadingCSVStatus({status:'completed'}))
  
    }).catch((err) => {
      console.log(err)
      dispatch(setUploadingCSVStatus({status:'failed'}))
    })
  }
  
  export const getCSVQueries = (id) => async (dispatch, getState) => {
    console.log(id)
      let results = await api.getAllDocs({ collectionName: `${id}` })
      let data = transformCSVData(results)
      console.log(data)
      dispatch(setCSVQueries({ id, data }))
  }
  
  export const deleteCSVCollection = (collection) => async (dispatch, getState) => {
    
    dispatch(setDeleteJobStatus({processing:true}))
    
    //delete entire collection 
      let deleteFn = httpsCallable( functions,'recursiveDelete');
      deleteFn({ path: collection.id })
          .then(function(result) {
              console.log('Delete success: ' + JSON.stringify(result));
              dispatch(setDeleteJobStatus({completed:true}))
              dispatch(deleteCSVQueries(collection.id))
              dispatch(deleteCSVCollectionInfo(collection))
          })
          .catch(function(err) {
            console.log('Delete failed, see console,');
              console.warn(err);
              dispatch(setDeleteJobStatus({failed:true}))
          });
  }
  

  export const fetchCSVHotDeals = () => async (dispatch, getState) => {
    let userData = selectUserData(getState())
    let results;
    if(userData.role != ROLE.VirtualRealtor){
        if(userData.role == ROLE.InventoryManager){ 
            results = api.getAllDocs({collectionName:'csv_hotdeals', wheres: [['hot_deal_by.id', '==', userData.id ]], order: ("hot_deal_on")})
        }else {
            results = api.getAllDocs({collectionName:'csv_hotdeals', wheres: [['hot_deal_by.id', '==', userData.id ]], order: ("hot_deal_on")})
        }
    }
    if(results.length){
        let transformedData = transformCSVData(results)
        dispatch(setHotDeals(transformedData))
    }
    
  }

  export const addToCSVHotdeals = ( {id,data} ) => async (dispatch, getState) => {
    // dispatch(setAddingToCSV)
    console.log(data)
    let userData = selectUserData(getState())
    let dataToAdd = {
      ...data,
      hot_deal_by : {
        name: userData?.firstnme + ' ' + userData?.lastname,
        id : userData?.id
      },
      hot_deal_on : serverTimestamp(),
      
    }
    
    let result = await api.addDocument({collectionName:'csv_hotdeals', data:dataToAdd })
    await deleteDoc( doc( db, id , data.id ));
    console.log(result)
    let date = new Date()
    let localItem = {
      ...dataToAdd,
      id: result,
      hot_deal_on : {local:true, time: date }
    }
    let transformedData = transformCSVData( [localItem])
  
    dispatch(addedToCSVHotDeal(  {id, item: transformedData[0].rowData, removeItem: data } ))
  
  }
  
  export const selectUploadingCSVStatus = (state) => state.csv.uploadingCSVStatus
  export const selectCSVCollection = (state,id) => state.csv.csv_queries[id] || []
  export const selectDeleteJobStatus = (state,id) => state.csv.deleteJobStatus
  export const selectCSVHotDeals = (state, id) => state.csv.csvHotDeals 

