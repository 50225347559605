import React from "react";
import IconFamilies from '../../assets/glyphmapIndex.json';
import WithTooltip from "./WithTooltip";

export default function Icon({ family, name, styles, onClick, tooltipTitle, className, tooltipStyles, id }) {
  return (
    <WithTooltip tooltipText={tooltipTitle} tooltipStyles={tooltipStyles} id={id || name}>
      <div
        style={{ 
          fontFamily: family, 
          position: 'relative', 
          cursor: 'pointer', 
          ...styles }}
        className={className}
        onClick={onClick}>
        {String.fromCodePoint(IconFamilies[family][name])}
      </div>
    </WithTooltip>
  )
}
