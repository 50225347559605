import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ROLE } from './constants';
import { getAuth, onAuthStateChanged, } from 'firebase/auth';
import Loading from './components/common/Loading';
import DashboardPage from "./pages/dashboardpage";
import DashboardIndex from "./pages/dashboardIndex";
import LoginPage from './pages/loginpage';
import ManageUsers from './pages/ManageUsers';
import Queries from './pages/Queries';
import AddQuery from './pages/AddQuery';
import EditQuery from './pages/EditQuery';
import ApprovedQueries from './pages/ApprovedQueries';
import ApprovedContracts from './pages/ApprovedContracts';
import SentContracts from './pages/SentContracts';
import SignedContracts from './pages/SignedContracts';
import Inventory from './pages/Inventory';
import HotDeals from './pages/HotDeals';
import InventoryContracts from './pages/InventoryContracts';
import FullView from './pages/FullView';
import CSVInventory from './pages/CSVInventory'

import {
  selectIsLoggedIn,
  setLoggedInUserData,
  userAuthenticated,
  logout,
  selectUserData,
  selectIsFetchingUserData
} from "./store/user";
import CSVCollectionView from "./components/CSVCollectionView";
import CSVHotdeals from "./pages/CSVHotdeals";
import Listings from "./pages/Listings";

export const LOGIN_PAGE_ROUTE = "/login";
export const HOME_PAGE_ROUTE = "/dashboard";



export const AppRoutes = () => {

  const authentication = getAuth();
  let dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      console.log('AUTH USER BECAUSE TOKEN EXISTS');
      dispatch(userAuthenticated())
    } else {
      dispatch(logout())
    }

    onAuthStateChanged(authentication, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(localStorage.getItem('userId'))
        console.log(uid)
        if (uid == localStorage.getItem("userId") || !localStorage.getItem('userId')) {
          console.log('SESSION AND FIREBASE AUTH ID SAME SO FETCH USER DATA')
          dispatch(setLoggedInUserData(uid));
        }
        // ...
      } else {
        // User is signed out
        // ...
        dispatch(logout())
      }
    });
  }, [])

  return (
    <Routes>
      <Route exact path={LOGIN_PAGE_ROUTE} element={<LoginPage />} />
      <Route
        exact
        path={HOME_PAGE_ROUTE}
        element={
          <PrivateRoute roles={[
            ROLE.Admin,
            ROLE.Contractor,
            ROLE.PropertyManager,
            ROLE.VirtualRealtor,
            ROLE.Management,
            ROLE.InventoryManager,
            ROLE.DispositionManager,
            ROLE.TeamLeads]}>
            <DashboardPage />
          </PrivateRoute>
        }>
        <Route
          index
          exact
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.Management,
              ROLE.PropertyManager,
              ROLE.Contractor,
              ROLE.InventoryManager,
              ROLE.VirtualRealtor,
              ROLE.DispositionManager,
              ROLE.TeamLeads,
            ]}>
              <DashboardIndex />
            </PrivateRoute>}
        />
        <Route
          path={HOME_PAGE_ROUTE + '/users'}
          element={
            <PrivateRoute roles={[ROLE.Admin]}>
              <ManageUsers />
            </PrivateRoute>}
        />
        <Route
          path={HOME_PAGE_ROUTE + '/queries'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.VirtualRealtor,
              ROLE.Management,
              ROLE.Contractor,
              ROLE.InventoryManager,
              ROLE.PropertyManager,
              ROLE.TeamLeads,
              ROLE.DispositionManager,
            ]}>
              <Queries />
            </PrivateRoute>}>
          <Route
            path={HOME_PAGE_ROUTE + '/queries/add'}
            element={
              <PrivateRoute roles={[
                ROLE.Admin,
                ROLE.Management,
                ROLE.VirtualRealtor,
                ROLE.Contractor,
                ROLE.InventoryManager,
                ROLE.PropertyManager,
                ROLE.TeamLeads,
                ROLE.DispositionManager,
              ]}>
                <AddQuery />
              </PrivateRoute>
            } />
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/edit/:id'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager,
              ROLE.Contractor,
              ROLE.InventoryManager,
              ROLE.DispositionManager,
            ]}>
              <EditQuery />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/approved_queries'}
          element={
            <PrivateRoute roles={[ROLE.Admin]}>
              <ApprovedQueries />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/approved_contracts'}
          element={
            <PrivateRoute roles={[ROLE.Admin]}>
              <ApprovedContracts />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/sent_contracts'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin]}>
              <SentContracts />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/signed_contracts'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager
            ]}>
              <SignedContracts />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/inventory'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager,
              ROLE.InventoryManager,
              ROLE.DispositionManager,
            ]}>
              <Inventory />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/hot_deals'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager,
              ROLE.InventoryManager,
              ROLE.DispositionManager,
            ]}>
              <HotDeals />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/listings'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager,
              ROLE.InventoryManager,
              ROLE.DispositionManager,
            ]}>
              <Listings />
            </PrivateRoute>}>
        </Route>

        <Route
          path={HOME_PAGE_ROUTE + '/inventory_contracts'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager,
              ROLE.DispositionManager,
            ]}>
              <InventoryContracts />
            </PrivateRoute>}>
        </Route>

        <Route
          path={HOME_PAGE_ROUTE + '/csv_inventory'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.Contractor,
              ROLE.PropertyManager,
              ROLE.Management,
              ROLE.DispositionManager,
              ROLE.InventoryManager
            ]}>
              <CSVInventory />
            </PrivateRoute>}>
          <Route
            path={HOME_PAGE_ROUTE + '/csv_inventory/:id'}
            element={
              <PrivateRoute roles={[
                ROLE.Admin,
                ROLE.Contractor,
                ROLE.PropertyManager,
                ROLE.Management,
                ROLE.DispositionManager,
                ROLE.InventoryManager
              ]}>
                <CSVCollectionView  />
              </PrivateRoute>}>
          </Route>

        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/csv_hotdeals'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.PropertyManager,
              ROLE.DispositionManager,
              ROLE.InventoryManager
            ]}>
              <CSVHotdeals />
            </PrivateRoute>}>
        </Route>


        <Route
          path={HOME_PAGE_ROUTE + '/query/:id'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.Contractor,
              ROLE.PropertyManager,
              ROLE.Management,
              ROLE.DispositionManager,
              ROLE.InventoryManager
            ]}>
              <FullView />
            </PrivateRoute>}>
        </Route>
        <Route
          path={HOME_PAGE_ROUTE + '/csv/:id'}
          element={
            <PrivateRoute roles={[
              ROLE.Admin,
              ROLE.Contractor,
              ROLE.PropertyManager,
              ROLE.Management,
              ROLE.DispositionManager,
              ROLE.InventoryManager
            ]}>
              <CSVCollectionView />
            </PrivateRoute>}>
        </Route>

      </Route>
      <Route path="/" element={<Navigate replace to={LOGIN_PAGE_ROUTE} />} />
    </Routes>)
};


function PrivateRoute({ roles, children }) {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const user = useSelector(selectUserData);
  const userHasRequiredRole = user && roles.includes(user.role) ? true : false;

  if (!localStorage.getItem("userId")) {
    return <Navigate to="/login" />;
  }
  // if (!isLoggedIn) {
  //   return <Navigate to="/login" />;
  // }
  if (isLoggedIn && !user) {
    return <div style={
      {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}> <Loading /></div>
  }

  if (isLoggedIn && !userHasRequiredRole) {
    return <div> You are not authorized for this page. </div> // build your won access denied page (sth like 404)
  }

  return children
}