import React, { useState, useEffect, } from 'react';
import { useLocation } from "react-router-dom"
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import { filterDateResults } from '../../utils';
import { ROLE, TABS } from '../../constants';
import QueryCard from './QueryCard';
import Loader from '../common/Loading';
import Button from '../common/Button';
import Dialogue from '../common/Dialogue';
import AddRemarks from '../modals/AddRemarks';
import AddImagesForm from '../modals/AddImagesForm';
import ApproveQueryForm from '../modals/ApproveQueryForm';
import AddToInventoryForm from '../modals/AddToInventoryForm';
import SendContractForm from '../modals/SendContractForm';
import SignContractForm from '../modals/SignContractForm';
import ApproveContract from '../modals/ApproveContract';
import MoveToHotDealsForm from '../modals/MoveToHotDealsForm';
import MoveToInventoryContracts from '../modals/AddToInventoryContracts';
import SearchContainer from '../common/SearchContainer';
import DateRangePicker from '../common/DateRangePicker';
import { APP_COLORS } from '../../styles';
import { selectIM, selectPM, getPropertyManagers, selectVR, getInventoryManagers } from '../../store/app';
import MoveToListingForm from '../modals/MoveToListingForm';
import BasicSearch from '../common/BasicSearch';

export default function QueryListView({
  data,
  role,
  isFetching,
  activeTab,
  onSearchEnter,
  searchString,
  onBulkInventory,
  onDateFilter
}) {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '240px',
    }),
    input: (provided, state) => ({
      ...provided,
      color: APP_COLORS.PRIMARY_TEXT
    }),
    control: (provided, state) => ({
      ...provided,
      padding: 5,
      background: 'inherit',
      border: `1px solid ${APP_COLORS.BORDER_COLOR}`,
      borderRadius: '10px',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
      background: APP_COLORS.PAGE_BACKGROUND,
      color: APP_COLORS.PRIMARY_TEXT
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      ...provided,
      background: isFocused ? APP_COLORS.SECONDARY_BACKGROUND : ''
    }),
    placeholder: (styles) => ({ ...styles, }),
    singleValue: (styles, { data }) => ({ ...styles, color: APP_COLORS.PRIMARY_TEXT }),

  }

  const dispatch = useDispatch();
  const location = useLocation();
  let view = null;

  if (location.pathname == '/dashboard/queries') {
    if (activeTab == TABS.QUERY.id) {
      view = 'queries'
    } else if (activeTab == TABS.MYQUERIES.id) {
      view = 'myQueries'
    } else if (activeTab == TABS.ALLQUERIES.id) {
      view = 'allQueries'
    } else if (activeTab == TABS.OLDQUERIES.id) {
      view = 'oldQueries'
    }

  } else if (location.pathname == '/dashboard/approved_queries') {
    view = 'approved_queries'
  } else if (location.pathname == '/dashboard/approved_contracts') {
    view = 'approved_contracts'
  } else if (location.pathname == '/dashboard/sent_contracts') {
    view = 'sent_contracts'
  } else if (location.pathname == '/dashboard/signed_contracts') {
    view = 'signed_contracts'
  } else if (location.pathname == '/dashboard/inventory') {
    view = 'inventory'
  } else if( location.pathname == '/dashboard/listings' ){
    view = 'listings'
  } else if (location.pathname == '/dashboard/inventory_contracts') {
    view = 'inventory_contracts'
  } else if (location.pathname == '/dashboard/hot_deals') {
    view = 'hot_deals'
  }

  const lightBoxOptions = {
    settings: {
      autoplaySpeed: 0,
    },
    button: {
      showAutoplayButton: false
    }
  }
  let [scrollToTopButton, setScrollToTopButton] = useState(false)

  const [images, setImages] = useState([])
  const { openLightbox, closeLightbox } = useLightbox()


  const [dataList, setData] = useState(data)
  let [rangedData, setRangedData] = useState(null)
  let [dateFilters, setDateFilters] = useState(null)

  const pageItems = 200;
  let [count, setCount] = useState(0)
  let [pageNumber, setPageNumber] = useState(1);

  let [selectedItem, setIsSelected] = useState(null);
  let [allChecked, setAllChecked] = useState(false)
  let [checkedItem, setCheckedItem] = useState({});

  let [showDialogue, setShowDialogue] = useState(null);

  useEffect(() => {
    document.addEventListener('scroll', showScrollButton)
    if ((view == 'hot_deals') && (role == ROLE.Admin)) {
      dispatch(getPropertyManagers())
    }
    return () => document.removeEventListener('scroll', showScrollButton)
  }, [])

  let inventoryManagers = useSelector(selectIM)
  let [imValue, setIMValue] = useState({ value: 'all', label: 'All Inventory Managers', abbr: '' })
  let [dropdownIMList, setDropDownIMList] = useState([]);
  let [imFilteredData, setImFilteredData] = useState(null);

  let propertyManagers = useSelector(selectPM)
  let [pmValue, setPMValue] = useState({ value: 'all', label: 'All Property Managers', abbr: '' })
  let [dropdownPMList, setDropDownPMList] = useState([]);
  let [pmFilteredData, setPmFilteredData] = useState(null);

  let [officeValue, setOfficeValue] = useState({ value: 'all', label: 'All', abbr: '' })
  let [officeFilteredData, setOfficeFilteredData] = useState(null);

  let virtualRealtors = useSelector(selectVR);
  let [vRList, setVRList] = useState([])
  let [vRSelection, setVRSelection] = useState({ value: 'all', label: 'All Virtual Realtors', abbr: '' })
  let [byVrFilteredData, setByVrFilteredData] = useState(null)


  const showScrollButton = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 400) {
      setScrollToTopButton(true);
    } else {
      setScrollToTopButton(false);
    }
  }

  useEffect(() => {
    if (images.length) {
      openLightbox(images);
    }
  }, [images])

  const showLightBox = (index, images) => {
    let imageSources = [];
    images?.forEach(image => {
      imageSources.push({ src: image })
    })
    setImages(imageSources);
  }

  useEffect(() => {
    if (inventoryManagers.length) {
      let array = []
      array.push({ value: 'all', label: 'All Inventory Managers', abbr: '' })
      inventoryManagers.forEach(im => {
        array.push({ value: im.id, label: im.name, abbr: '' })
      })
      setDropDownIMList(array);
    }else{
        dispatch(getInventoryManagers())
    }
    if (propertyManagers.length) {
      let array = []
      array.push({ value: 'all', label: 'All Property Managers', abbr: '' })
      propertyManagers.forEach(pm => {
        array.push({ value: pm.id, label: pm.name, abbr: '' })
      })
      setDropDownPMList(array);
    }
    if (virtualRealtors.length) {
      let array = []
      array.push({ value: 'all', label: 'All Virtual Realtors', abbr: '' })
      virtualRealtors.forEach(vr => {
        array.push({ value: vr.id, label: vr.firstname + ' ' + vr.lastname, abbr: '' })
      })
      setVRList(array);
    }
  }, [inventoryManagers, propertyManagers, virtualRealtors])


  useEffect(() => {
    let paginatedData;
    let dataToUse = (byVrFilteredData || imFilteredData || pmFilteredData || officeFilteredData) || data
    setCount(Math.ceil(dataToUse?.length / pageItems));
    paginatedData = dataToUse?.slice((pageNumber - 1) * pageItems, (pageNumber * pageItems))
    setData(paginatedData)
    
    if (selectedItem && selectedItem.itemId) {
      window.scrollTo({
        top: selectedItem.scrollPos,
        behavior: 'instant',
      });
    } else {
      window.scroll({
        top: 0,
        behavior: 'instant'
      });
    }

  }, [imFilteredData, pmFilteredData, officeFilteredData, byVrFilteredData, pageNumber, data, ,])

  const searchText = (queryString) => {
    onSearchEnter(queryString)
  }

  const onDateRangeSelected = (start, end) => {
      onDateFilter(start, end, view)
      // let dateRanged = filterDateResults(view, start, end, data)
      if (start) {
        setDateFilters({ start: start?.toLocaleDateString(), end: end?.toLocaleDateString() })
      }
      // setRangedData(dateRanged)
    
  }

  const handleIMSelection = (selectedOption) => {
    console.log(selectedOption)
    setIMValue(selectedOption)
    if (selectedOption.value == 'all') {
      setImFilteredData(null)
    } else {
      let dataToUse =  data
      dataToUse.filter(d => d.inventory_manager.id == selectedOption.value)
      setImFilteredData(dataToUse.filter(d => d.inventory_manager.id == selectedOption.value))
    }
  }

  const handlePMSelection = (selectedOption) => {
    console.log(selectedOption)
    setPMValue(selectedOption)
    if (selectedOption.value == 'all') {
      setPmFilteredData(null)
    } else {
      let dataToUse =  data
      setPmFilteredData(dataToUse.filter(d => d.property_manager?.id == selectedOption.value))
    }
  }

  const handleOfficeSelection = (selectedOption) => {
    console.log(selectedOption)
    setOfficeValue(selectedOption)
    let dataToUse;
    if (selectedOption.value == 'all') {
      setOfficeFilteredData(null)
    } else if (selectedOption.value == 'nadra') {
      dataToUse =  data
      setOfficeFilteredData(dataToUse.filter(d => !d.office || d.office == 'nadra'))
    } else {
      dataToUse =  data
      setOfficeFilteredData(dataToUse.filter(d => d.office == selectedOption.value))
    }
  }

  const handleVRSelection = (selectedOption) => {
    console.log(selectedOption)
    setVRSelection(selectedOption)
    if (selectedOption.value == 'all') {
      setByVrFilteredData(null)
    } else {
      let dataToUse =  data
      setByVrFilteredData(dataToUse.filter(d => d.added_by?.id == selectedOption.value))
    }
  }

  const onApproveClicked = (id) => {
    setShowDialogue({ show: true, type: 'approve_queries', itemId: id });
  }

  const onAddRemarks = (id, view, oldRemarks) => {
    setShowDialogue({ show: true, type: 'remarks_dialog', itemId: id, view: view, text: oldRemarks });
  }

  const onInventoryClicked = (id, view) => {
    console.log(view)
    setShowDialogue({ show: true, type: 'inventory', itemId: id, view: view });
  }
  const onApproveContractClicked = (id) => {
    setShowDialogue({ show: true, type: 'approve_contracts', itemId: id, view: view });

  }
  const onSendContractClicked = (id) => {
    setShowDialogue({ show: true, type: 'send_contract', itemId: id });
  }

  const onSignedContractClicked = (id) => {
    setShowDialogue({ show: true, type: 'sign_contract', itemId: id, view:view });
  }

  const onMoveToHotDealsClicked = (id, remarks) => {
    setShowDialogue({ show: true, type: 'hot_deals', itemId: id, text: remarks });
  }

  const onMoveToInventoryContracts = (id) => {
    setShowDialogue({ show: true, type: 'inventory_contracts', itemId: id });
  }

  const onMoveToListing = (id) => {
    setShowDialogue({ show: true, type: 'listings', itemId: id });
  }

  const onAddImages = (id, activeProperty) => {
    setShowDialogue({ show: true, type: 'add_images', view: view, itemId: id, activeProperty: activeProperty });
  }



  return (
    <div
      style={{ width: "100%", minHeight: '60vh' }}
      onClick={(e) => {
        setIsSelected(null)
      }}
    >
      {role != ROLE.VirtualRealtor && <>
        {role != ROLE.TeamLeads && view != 'oldQueries' &&
            <SearchContainer
              view = {view}
              role = {role}
              onEnter={searchText}
              searchString={searchString}
            />
          }
         {role != ROLE.TeamLeads && view == 'oldQueries' && 
          <BasicSearch
            data = {data}  
          />
         } 
        <div style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, }}>
        {role != ROLE.TeamLeads && view != 'oldQueries' &&
            <div style={{ width: '25%' }}>
              <DateRangePicker
                onSelected={onDateRangeSelected}
              />
            </div>
          }
         
          {(view == 'inventory' || view == 'listings' ||  view == 'hot_deals') &&
            (role == ROLE.Admin || role == ROLE.PropertyManager || role == ROLE.DispositionManager )
            &&
            <div>
              <Select
                styles={customStyles}
                name={'inventory_managers'}
                value={imValue}
                onChange={handleIMSelection}
                options={dropdownIMList}
              />
            </div>
          }
           {(view == 'hot_deals') &&
            (role == ROLE.Admin)
            &&
            <div>
              <Select
                styles={customStyles}
                name={'property_managers'}
                value={pmValue}
                onChange={handlePMSelection}
                options={dropdownPMList}
              />
            </div>
          }

          {(view == 'queries' || view == 'allQueries') &&
            (role == ROLE.Admin || role == ROLE.PropertyManager || role == ROLE.Management || role == ROLE.DispositionManager)
            &&
            <div>
              <Select
                styles={customStyles}
                name={'office'}
                value={officeValue}
                onChange={handleOfficeSelection}
                options={[
                  { value: 'all', label: 'All', abbr: '' },
                  { value: 'royal', label: 'Royal', abbr: '' },
                  { value: 'nadra', label: 'Nadra', abbr: '' }
                ]}
              />
            </div>
          }
          {(view == 'queries' || view == 'allQueries') &&
            (role == ROLE.Admin || role == ROLE.Management)
            &&
            <div>
              <Select
                styles={customStyles}
                name={'byVr'}
                value={vRSelection}
                onChange={handleVRSelection}
                options={vRList}
              />
            </div>
          }
        </div>
      </>}

      <div style={{
        fontSize: '12px',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: APP_COLORS.SECONDARY_TEXT
      }}>
        {searchString ?
          <div style={{ fontStyle: 'italic' }}>
            Showing search results for <b>{`${`'` + searchString + `'`}`}</b>. Found {data.length} items. <br />
            {/* (Total Items : {data.length}) */}
          </div> :
            imFilteredData ?
              <div style={{ fontStyle: 'italic' }}>
                <b> Found {imFilteredData.length} items for {imValue.label}.  <br />
                Showing {dataList.length} </b> items.
              </div>
              :
              officeFilteredData ?
                <div style={{ fontStyle: 'italic' }}>
                  <b> Found {officeFilteredData.length} items for {officeValue.label}. <br />
                  Showing {dataList.length} </b> items. 
                </div>
                :
                byVrFilteredData ?
                <div style={{ fontStyle: 'italic' }}>
                  <b> Found {byVrFilteredData.length} items for {vRSelection.label}. <br />
                  Showing {dataList.length} </b> items.
                </div>
                  :
                <div style={{ fontStyle: 'italic' }}>
                  Showing <b> {dataList?.length} </b> items. <br />
                  (Total Items : {data?.length})
                </div>
        }
        <div>
          <ThemeProvider theme={darkTheme}>
            <Pagination
              count={count}
              variant='outlined'
              shape='rounded'
              onChange={(e, page) => {
                console.log(page)
                setPageNumber(page)
              }}
            />
          </ThemeProvider>
        </div>
      </div>


      {view == 'queries' &&
        <div style={{
          display: 'flex',
          width: '100%',
          gap: 10,
          alignItems: 'center',
          padding: '10px 0px 0px 0px',
          color: APP_COLORS.SECONDARY_TEXT
        }}>
          <div style={{
            width: '20px',
            height: '20px',
            border: `1px solid ${APP_COLORS.LINK_ORANGE}`,
            borderRadius: '50%',
            background: Object.values(checkedItem).filter(v => v == true).length == dataList?.length && APP_COLORS.LINK_ORANGE,
            cursor: 'pointer'
          }}
            onClick={() => {
              let previousChecked = { ...checkedItem }
              dataList.forEach(item => {
                previousChecked[item.id] = allChecked ? false : true
              })
              setAllChecked(!allChecked)
              setCheckedItem(previousChecked)
            }}
          />
          <div> {Object.values(checkedItem).filter(v => v == true).length} item(s) selected. </div>
          {Object.values(checkedItem).filter(v => v == true).length > 0 &&
            <Button
              label={'Inventory selected'}
              onClick={() => {
                let selectedItems = [];
                for (let key in checkedItem) {
                  if (checkedItem[key]) {
                    selectedItems.push(key)
                  }
                }
                console.log(selectedItems)
                onBulkInventory(selectedItems)
              }}
              style={{ fontSize: '12px', background:'none', border: `1px solid ${APP_COLORS.LINK_ORANGE}`}}
            />}
        </div>
      }

      {!isFetching && dataList && dataList.length ?
        <div>
          {dataList.map((queryItem, i) => (
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              color: APP_COLORS.PRIMARY_TEXT
            }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                {view == 'queries' &&
                  <div style={{
                    width: '20px',
                    height: '20px',
                    border: `1px solid ${APP_COLORS.LINK_ORANGE}`,
                    borderRadius: '50%',
                    background: checkedItem[queryItem.id] && APP_COLORS.LINK_ORANGE,
                    cursor: 'pointer'
                  }}
                    onClick={() => {
                      let previousChecked = { ...checkedItem }
                      let isChecked = previousChecked[queryItem.id]
                      if (!isChecked) {
                        previousChecked = {
                          ...previousChecked,
                          [queryItem.id]: true
                        }
                      } else {
                        previousChecked[queryItem.id] = !isChecked
                      }
                      console.log(previousChecked)
                      setCheckedItem(previousChecked)
                    }
                    }
                  />}
                <div style={{ fontWeight: 'bold' }}> {(i + 1) + ((pageNumber - 1) * pageItems)} </div>
                {/* <div style={{ fontWeight: 'bold' }}> {(i + 1) } </div> */}
              </div>
              {/* <div style={{ marginTop: 20, fontWeight: 'bold' }}> {(i + 1)} </div> */}
              <QueryCard
                key={i}
                item={queryItem}
                onOpenImageGallery={(idx, images) => { showLightBox(idx, images) }}
                role={role}
                view={view}
                onApproveClicked={onApproveClicked}
                onAddRemarks={onAddRemarks}
                onInventoryClicked={onInventoryClicked}
                onApproveContractClicked={onApproveContractClicked}
                onSendContractClicked={onSendContractClicked}
                onSignContractClicked={onSignedContractClicked}
                onMoveToHotDealsClicked={onMoveToHotDealsClicked}
                onMoveToInventoryContracts={onMoveToInventoryContracts}
                onMoveToListing = {onMoveToListing}
                onAddImages={onAddImages}
                onItemClick={((itemId) => {
                  setIsSelected({ itemId: itemId, scrollPos: window.scrollY })
                  // setCheckedItem((previous)=>({
                  //   ...previous,
                  //   [i] : !previous[i] 
                  // }))
                })}
                isSelected={queryItem.id == selectedItem?.itemId}
              />
            </div>
          ))}
        </div>
        :
        <div style={{ color:'#fff', padding: 30, fontWeight: 'bold' }}> No results </div>
      }
      {isFetching && <div style={{ display: 'flex', flexDirection: 'row', }}> <Loader />  </div>}

      {images?.length ? <SRLWrapper elements={images} options={lightBoxOptions} /> : null}

      {scrollToTopButton &&
        <div style={{ position: 'fixed', zIndex: 2, bottom: 10, right: 10 }}>
          <Button
            style={{ padding: 10, color:'black',fontWeight:'bolder' }}
            icon={{ name: 'arrow-up', family: "BootstrapIcons" }}
            onClick={() => {
              window.scroll({
                top: 0,
                behavior: 'smooth'
              })
            }}

          />
        </div>}

      {showDialogue && showDialogue.type == 'remarks_dialog' &&
        <Dialogue
          headerText={'Add Remarks'}
          content={
            <AddRemarks
              id={showDialogue.itemId}
              view={showDialogue.view}
              onClose={() => setShowDialogue(null)}
              text={showDialogue.text}
            />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />
      }

      {showDialogue && showDialogue.type == 'approve_queries' &&
        <Dialogue
          headerText={'Approve Query'}
          content={<ApproveQueryForm id={showDialogue.itemId} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'approve_contracts' &&
        <Dialogue
          headerText={'Approve for contract sending.'}
          content={<ApproveContract id={showDialogue.itemId} view={showDialogue.view} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'send_contract' &&
        <Dialogue
          headerText={'Move to Contract Sent.'}
          content={<SendContractForm id={showDialogue.itemId} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}


      {showDialogue && showDialogue.type == 'sign_contract' &&
        <Dialogue
          headerText={'Sign Contract'}
          content={<SignContractForm view={showDialogue.view} id={showDialogue.itemId} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'inventory' &&
        <Dialogue
          headerText={'Add to Inventory'}
          content={<AddToInventoryForm id={showDialogue.itemId} view={showDialogue.view} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'hot_deals' &&
        <Dialogue
          headerText={'Move to Hot-Deals'}
          content={<MoveToHotDealsForm id={showDialogue.itemId} view={showDialogue.view} text={showDialogue.text} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'listings' &&
        <Dialogue
          headerText={'Move to Listings'}
          content={<MoveToListingForm id={showDialogue.itemId} view={showDialogue.view} text={showDialogue.text} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'inventory_contracts' &&
        <Dialogue
          headerText={'Move to Inventory Contracts'}
          content={<MoveToInventoryContracts id={showDialogue.itemId} view={showDialogue.view} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

      {showDialogue && showDialogue.type == 'add_images' &&
        <Dialogue
          headerText={'Add Images'}
          content={<AddImagesForm
            id={showDialogue.itemId}
            view={showDialogue.view}
            onClose={() => setShowDialogue(null)}
            activeProperty={showDialogue.activeProperty} />}
            show={showDialogue.show}
            onClose={() => setShowDialogue(null)}
        />}
    </div>
  )
}
  // const columns = [
  //   {
  //     field:'images',
  //     headerName: 'Images',
  //     width: '300',
  //     renderCell: (params) =>  (
  //     <div style={{display:'flex', alignContent:'center',}}>
  //     {params?.value?.map((image,idx) => {
  //       return <img key={idx} style={{width:'50px', height:'50px', marginRight:'10px'}} src={image} onClick={()=>{showLightBox(idx, params?.value )}} />
  //     })}
  //     </div>) // renderCell will render the component}
  //   },
  //   {field:'phone_no', headerName:'Phone', },
  //   {field:'fullname', headerName:"Full Name",width:150 },
  //   {field:'email', headerName:'Email', width:200},
  //   {field:'address', headerName:'Property Address', width:400 },
  //   {field:'price', headerName:'Offered Price', width:100 },
  //   {field:'asking_price', headerName:'Demand Price', width:100},
  //   {field:'occupied', headerName:'Occupied', width:100},
  //   {field:'moving_toff', headerName:'Moving Time-off', width:100},
  //   { field:'added_by',
  //     headerName: 'Agent Info',
  //     width:200,
  //     valueFormatter: (params) => {return params.value.firstname +' '+params.value.lastname + ' ('+  params.value.role + ')'}
  //   },
  //   { field:'created',
  //   headerName: 'Created at' ,
  //   width:200,
  //   renderCell: (params) =>  (<Moment format={'DD-MMM-YY @ hh:mm A'}>{params.value.local ?  params.value :  params.value.toDate() }</Moment>)
  //   },
  //   {field:'mailing_address', headerName:'Mailing Address', width:300},
  //   { field:'remarks', headerName: 'Remarks', width:400 },
  // ]