import React, { useEffect, useRef } from "react";
import Icon from "./Icon";

function Dialog({ children, onClose, style, showClose, closeButtonStyles }) {
  let scrollPos = useRef()
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    scrollPos.current = window.scrollY;
    document.body.style.overflowY = "hidden"
    return () => {
      document.body.style.overflowY = "scroll"
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [])

  const onKeyDown = (key) => {
    if (key.code === "Escape") {
      onClose()
    }
  }
  return (
    <div style={styles.background}
      onClick={onClose}
      onMouseEnter={(e) => e.stopPropagation() && e.preventDefault()}
      onMouseLeave={(e) => e.stopPropagation() && e.preventDefault()}>
      <div style={{ ...styles.container, style }} onClick={(e) => e.stopPropagation()}>
        {showClose && <Icon family='EvilIcons' name='close' styles={{ ...styles.closeButton, ...closeButtonStyles }} onClick={onClose} />}
        {children}
      </div>
    </div>
  );
}
export default Dialog;

const styles = {
  background: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backgroundColor: 'rgba(20,20,20,0.9)',
    // pointerEvents: 'none',

  },
  container: {
    position: 'relative',
    backgroundColor: '#1f1f1f',
    boxShadow: '0 0 30px rgba(0,0,0,0.5)',
    borderRadius: 10,
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    padding: 10,
    cursor: 'pointer',
    lineHeight: '20px',
    top: 0,
    right: 0,
    color: '#999',
    fontSize: 22,
    width: 22,
    height: 20
  },

}
