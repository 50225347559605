import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Icon from '../common/Icon';
import Button from '../common/Button';
import FileUploader from '../common/fileUploader';

import Loader from '../common/Loading';
import { addImagesToProperty, selectIsUploadingImages, selectIsQueryEdited, resetIsEdited, selectIsEditingQuery } from '../../store/query';

export default function AddImagesForm ({ id, view,  onClose, activeProperty }){
  const dispatch = useDispatch();
  
  const { openLightbox, closeLightbox } = useLightbox()
  const options = {
    settings: {
      autoplaySpeed: 0,
    },
    button: {
      showAutoplayButton: false
    }
  }
  let [imageFiles, setImageFiles] = useState([])
  let [previewImages, setPreviewImages] = useState([])

  let uploadingImages = useSelector(selectIsUploadingImages)
  let isEditingQuery = useSelector(selectIsEditingQuery)
  let queryUpdated = useSelector(selectIsQueryEdited)

  useEffect(()=>{
    return dispatch(resetIsEdited(false))
  },[])

  useEffect(()=>{
    if(queryUpdated)
    onClose()      
  },[queryUpdated])
  
  const onAddImages = ()=>{
    dispatch(addImagesToProperty(id, view, activeProperty, imageFiles))
  }
  
  const onFilesDropped = (acceptedFiles) => {
    setImageFiles(prev => ([...prev, ...acceptedFiles]))
    let previewUrls = [];
    acceptedFiles?.forEach((file, index) => {
      let url = URL.createObjectURL(file);
      previewUrls.push({ src: url })
    })
    setPreviewImages(prev => ([
      ...prev,
      ...previewUrls
    ]))
  }

  const onImageCancel = (index) => {
    let images = [...imageFiles];
    let pImages = [...previewImages];
    images.splice(index, 1)
    pImages.splice(index, 1)
    setImageFiles(images)
    setPreviewImages(pImages)
  }


  return (
  <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',}}>
      <FileUploader
              onFilesDropped={onFilesDropped}
              text={'Click or Drag n Drop Images Here!'}
        />
      <div style={imageContainer}>
              {previewImages.length ? previewImages.map((image, idx) => (
                <div key={idx} style={{ height: "200px", width: '200px', position: 'relative' }} >
                  <div style={{
                    background: 'white',
                    left: '90%', top: 2,
                    borderRadius: '50%',
                    position: 'absolute',
                    filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6))',
                    zIndex: 99,
                  }}>
                    <Icon family='BootstrapIcons' name='x' onClick={(e) => { onImageCancel(idx); }} styles={{ color: 'black', }} /></div>
                  <img src={image.src} onClick={e => { openLightbox(idx)}} style={{ width: '100%', height: '100%' }} />

                </div>
              )
              ) : null}
            </div>      
      <div style={{display:"flex", justifyContent:"center",paddingTop:20}}>
       {isEditingQuery ?
            <><span> Updating Data </span><Loader /> </> :
            uploadingImages ?
              <> <span> Uploading Images...</span> <Loader /> </>
              :
              <Button
                label={'Add Images'}
                onClick={onAddImages}
                style={{ textAlign: "center", fontSize:'14px', width: '100%' }}
              />
          }
      </div>

    <SRLWrapper elements={previewImages} options={options} />
    </div>
  )
}


const imageContainer = {
    marginTop:20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'center',
    flexWrap: 'wrap',
    width: '100%',
    gap: 10,
    height: "300px",
    overflowY: 'scroll'
  }