import React, { useState, useRef, useEffect } from 'react';
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import Icon from '../common/Icon';
import TextField from '../common/textField';
import FileUploader from '../common/fileUploader';
import Button from '../common/Button';
import Select1 from '../common/Select';
import { APP_COLORS } from '../../styles';
import { validate, checkForErrors, US_STATES } from '../../utils';

export default function AddPropertyInfo({ data, onAddorEdit, onClose, index, onImagePreview, images }) {
  
  let [links, setLinks] = useState( data?.links || [] );

  let [propertyData, setPropertyData] = useState({
    address: data?.address || "",
    state: data?.state || "",
    occupation: data?.occupation || 'self',
    asking_price: data?.asking_price || '',
    zestimate: data?.zestimate || '',
    price: data?.price || '',
    area: data?.area || '',
    condition: data?.condition || "average",
    remarks: data?.remarks || "",
  });

  let [errors, setErrors] = useState({})
  let errorRef = useRef(errors);


  let [imageFiles, setImageFiles] = useState( data?.imageFiles || [])
  let [previewImages, setPreviewImages] = useState( data?.images || [])

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '240px',
    }),
    input:(provided, state)=>({
      ...provided,
      color:APP_COLORS.PRIMARY_TEXT
    }),
    control: (provided, state) => ({
      ...provided,
      padding: 5,
      background: 'inherit',
      border:`1px solid ${APP_COLORS.BORDER_COLOR}`,
      borderRadius: '10px',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
      background:APP_COLORS.PAGE_BACKGROUND,
      color:APP_COLORS.PRIMARY_TEXT
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      ...provided,
      background: isFocused ? APP_COLORS.SECONDARY_BACKGROUND : ''
    }),
    placeholder: (styles) => ({ ...styles,  }),
    singleValue: (styles, { data }) => ({ ...styles, color: APP_COLORS.PRIMARY_TEXT }),
    
  }



  const handleStateChange = (selectedOption) => {
    console.log(selectedOption)
    setPropertyData(prev => ({
      ...prev,
      state: selectedOption
    }))
  }

  const handleChange = (target) => {
    const { name, value } = target;
    console.log({name, value})
    let returnedErrors = validate(name, value)
    // console.log(returnedErrors[name])
    setErrors(errors => ({
      ...errors,
      [name]: returnedErrors[name]
    }));
    setPropertyData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const handleAdd = () => {
    for (const key in propertyData) {
      let returnedErrors = validate(key, propertyData[key])
      errorRef.current[key] = returnedErrors[key]
      setErrors(errors => ({
        ...errors,
        [key]: returnedErrors[key]
      }));
    }
    let hasErrors = checkForErrors(errorRef.current)
    if (!hasErrors) {
        console.log( propertyData,links)
        propertyData = {...propertyData, links:links } 
        if(images){
          propertyData = {...propertyData, imageFiles:imageFiles, previewImages: previewImages}
          propertyData.images = data?.images
        }
        onAddorEdit(propertyData, index)
    } else {
      toast.error('Fill Required Fields!')
    }
  }

  const addLink = ( ) => {
    let allLinks = [...links];
    if(allLinks.length < 4){
      setLinks(prev => ([
       ...prev,
       '',
     ]))
    }else{
      toast.error('Can only add max 4!')
    }
  }
  const removeLink = ( ) => {
    let allLinks = [...links]
    allLinks.splice(allLinks.length-1, 1)
    setLinks(allLinks)
  }

  const setLinkText = (target, idx) => {
    const { name, value } = target;
    let allLinks = [...links]
    allLinks[idx] = value
    setLinks(allLinks)
  }


  const onFilesDropped = (acceptedFiles) => {
    setImageFiles(prev => ([...prev, ...acceptedFiles]))
    let previewUrls = [];
    acceptedFiles?.forEach((file, index) => {
      let url = URL.createObjectURL(file);
      previewUrls.push({ src: url })
    })
    setPreviewImages(prev => ([
      ...prev,
      ...previewUrls
    ]))
  }
  const onImageCancel = (index) => {
    let images = [...imageFiles];
    let pImages = [...previewImages];
    images.splice(index, 1)
    pImages.splice(index, 1)
    setImageFiles(images)
    setPreviewImages(pImages)
  }

  return (
    <div style={{ padding: 20 , border:`1px dashed ${APP_COLORS.LINK_ORANGE}`, borderRadius:10}}>
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',}}>
          <h4>{index == null ? 'Add a new property' :  `Editing Property ${index+1}` } </h4>
          <div style={{fontSize:'20px', cursor:'pointer', padding:'10px', color:APP_COLORS.LINK_ORANGE}} onClick={onClose}>x</div>
          </div>
          
          <div style={{display:'flex', flexDirection:'column', }}>
            <div style={{display:'flex',  flexDirection:'row', gap:10, justifyContent:'space-between', alignItems:'center' }}>
              <div >Add Links </div>
              <div style={{display:'flex', flexDirection:'row', gap:10}}>
              <Button
                label={'- Remove Link'}
                onClick={removeLink}
                style={{ fontSize: "12px", padding: 10, width: "100px",  background: APP_COLORS.LINK_ORANGE }}
              />
              <Button
                label={'+ Add Link'}
                onClick={addLink}
                style={{ fontSize: "12px", padding: 10, width: "80px",  background: APP_COLORS.LINK_ORANGE }}
              />
              </div>
            </div>
            
            <div style={{display:'flex', flexDirection:'column-reverse', margin:10, maxHeight:250, padding:20, }}>
            {links.map(( l ,idx ) => (
             <TextField
             key={idx}
             name={'link'}
             text= {l} 
             type={'text'}
             placeholder={'Add link'}
             onTextChange={(target)=> setLinkText( target, idx )}
             onEnter={() => { }}
             autoFocus={true}
             style={{ color: APP_COLORS.PRIMARY_TEXT }}
             autocomplete={"off"}
             // onCancel = {} 
            // onFocus = {}
            // onClear = {} 
            />
            ))}
            </div>  

            {images && 
            <div style={{display:'flex', flexDirection:'row', margin:10,  padding:20, }}>
            <FileUploader
              onFilesDropped={onFilesDropped}
              text={'Click or Drag n Drop Images Here!'}
            />
            <div style={styles.imageContainer}>
              {previewImages.length ? previewImages.map((image, idx) => (
                <div key={idx} style={{ height: "200px", width: '200px', position: 'relative' }} >
                  <div style={{
                    background: 'white',
                    left: '90%', top: 2,
                    borderRadius: '50%',
                    position: 'absolute',
                    filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6))',
                    zIndex: 99,
                  }}>
                    <Icon family='BootstrapIcons' name='x' onClick={(e) => { onImageCancel(idx); }} styles={{ color: 'black', }} /></div>
                  <img src={image.src || image} onClick={e => { onImagePreview(previewImages,idx) }} style={{ width: '100%', height: '100%' }} />

                </div>
              )
              ) : null}
            </div>
            </div>
            }
            
          </div>

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Address  <sup style={{ color: APP_COLORS.LINK_ORANGE }}>*</sup> </div>
              <TextField
                name={'address'}
                text={propertyData.address}
                type={'text'}
                placeholder={'Enter Complete Address'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.address}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> State </div>
              <Select
                styles={customStyles}
                name={'state'}
                value={propertyData.state}
                onChange={handleStateChange}
                options={US_STATES}
              />
              {/* <TextField
                name={'zipcode'}
                text={''}
                type={'text'}
                placeholder={'Enter Zip code'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              /> */}
              <span style={styles.errors}>{errors.state}</span>
            </div>
          </div>

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Asking Price </div>
              <TextField
                name={'asking_price'}
                text={propertyData.asking_price}
                type={'text'}
                placeholder={'Enter client demand price'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.asking_price}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Offered Price  </div>
              <TextField
                name={'price'}
                text={propertyData.price}
                type={'text'}
                placeholder={'Enter our price '}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.price}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Zestimate </div>
              <TextField
                name={'zestimate'}
                text={propertyData.zestimate}
                type={'text'}
                placeholder={'Enter zillow or any other online estimate'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.zestimate}</span>
            </div>

          </div>
          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Condition </div>
              <Select1
                onChange={(e) => handleChange(e.target)}
                name={'condition'}
                value={propertyData.condition}
                style={{ background: APP_COLORS.META_BACKGROUND, color: APP_COLORS.PRIMARY_TEXT, height: '35px' }}
                options=
                {[
                  { id: 'good', text: 'Good ', value: 'good', },
                  { id: 'average', text: 'Average', value: 'average', },
                  { id: 'repair', text: 'Repair Required', value: 'repair', },
                ]}
              />
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Area of Property</div>
              <TextField
                name={'area'}
                text={propertyData.area}
                type={'text'}
                placeholder={'Enter property area in sqft or acres'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.area}</span>
            </div>

          </div>
          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Occupation </div>
              <Select1
                onChange={(e) => handleChange(e.target)}
                name={'occupation'}
                value={propertyData.occupation}
                style={{ background: APP_COLORS.META_BACKGROUND, color: APP_COLORS.PRIMARY_TEXT, height: '35px' }}
                options=
                {[
                  { id: 'self', text: 'Self', value: 'self', },
                  { id: 'tennant', text: 'Tennant', value: 'tennant', },
                  { id: 'unoccupied', text: 'Unoccupied', value: 'unoccupied', },
                ]}
              />
            </div>

            {propertyData.occupation !== 'unoccupied' &&
              <div style={styles.fieldContainer}>
                <div style={{ padding: '10px 0px' }}> Moving T/O </div>
                <TextField
                  name={'moving_toff'}
                  text={propertyData.moving_toff}
                  type={'text'}
                  placeholder={'Enter the moving time off'}
                  onTextChange={handleChange}
                  onEnter={() => { }}
                  autoFocus={true}
                  style={{ color: APP_COLORS.PRIMARY_TEXT }}
                  autocomplete={"off"}
                // onCancel = {} 
                // onFocus = {}
                // onClear = {} 
                />
                <span style={styles.errors}>{errors.moving_toff}</span>
              </div>}
          </div>
          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Remarks </div>
              <TextField
                name={'remarks'}
                text={propertyData.remarks}
                type={'text'}
                placeholder={'Enter any remarks, description'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.remarks}</span>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button
                label={ index == null ? 'Add Property' : 'Edit Property'}
                onClick={handleAdd}
                style={{ textAlign: "center", width: '20%' }}
              />
          </div>
        <ToastContainer
          position="top-right"
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        </div>
  )
}


const styles = {

  fieldPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 30
  },
  fieldContainer: {
    width: '100%',
  },
  errors: {
    padding: 5,
    fontSize: '12px',
    color: 'red',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: 10,
    height: "300px",
    overflowY: 'scroll'
  }
}