import { configureStore , getDefaultMiddleware} from '@reduxjs/toolkit';
import usersReducer from './user';
import queriesReducer from './query';
import appReducer from './app';
import commentsReducer from './comments';
import csvReducer from './csv';


export default configureStore({
  reducer: {
    users: usersReducer,
    queries : queriesReducer,
    app: appReducer,
    comments: commentsReducer,
    csv : csvReducer,
  },
  middleware: [...getDefaultMiddleware({immutableCheck: false, serializableCheck: false,})]
});
