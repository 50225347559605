import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getInventoryCSVCollections, selectCSVInventoryCollections,  } from "../store/app";
import { APP_COLORS, pageStyles } from "../styles";
import { Outlet, useMatch, useNavigate } from "react-router-dom";




export default function CSVInventory({ }) {
    let isParent = useMatch('/dashboard/csv_inventory');

    let navigate = useNavigate()
    const dispatch = useDispatch()
    let csvCollections = useSelector(selectCSVInventoryCollections)
   
    useEffect(() => {
        dispatch(getInventoryCSVCollections())
    }, [])

    return (
        <>  {isParent && 
            <div style={{ ...pageStyles, color: APP_COLORS.SECONDARY_TEXT }}>
                <div style={{ fontSize: 20, fontWeight: 'bolder', }}>
                    CSV Collections
                </div>
                <div style={{ margin: '20px 0px', }}>
                    {csvCollections?.length ? csvCollections.map((collection) => (
                        <div style={{
                            padding: 10,
                            borderBottom: '2px solid #333',
                            height: 50,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }} >
                            <div
                                style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}
                                onClick={() => { console.log(collection) }}>
                                <div style={{ fontSize: '18px', fontWeight: 'bolder' }}> {collection.name} </div>
                                <div style={{ display: 'flex', gap: 20 }}>
                                    <div
                                        onClick={() => { navigate('/dashboard/csv_inventory/' + collection.id, { state: { collection } }) }}
                                        style={{ cursor: 'pointer' }} >
                                        View
                                    </div>                                    
                                </div>

                            </div>
                            {collection.assigned_to && <div style={{ padding: '4px 5px', fontSize: '14px' }}>
                                Assigned to {collection.assigned_to?.name}
                            </div>}
                        </div>
                    )) : <div>Fetching...</div>}
                </div>
            </div>
            }
            
            
            <Outlet/>
            
        </>
    )
}

