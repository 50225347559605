import { createSlice } from '@reduxjs/toolkit'
import * as api from '../services/firebase/api';
import { db } from '../services/firebase/config';

import {  deleteDoc, doc, onSnapshot, serverTimestamp, Timestamp } from "firebase/firestore";
import { selectUserData } from './user';
import algoliasearch from 'algoliasearch';

// import { httpsCallable } from 'firebase/functions';
// import { functions } from '../services/firebase/config';

const initialState = {
    dashboardData: null,
    inventory_managers: [],
    property_managers: [],
    virtual_realtors: [],
    csvCollections: [],
    assignCSVStatus: null,
    csvInventoryCollections: []
}



const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
    setDashboardData(state, action) {
        state.dashboardData = action.payload
    },
    setInventoryManagers(state, action) {
        state.inventory_managers = action.payload
    },
    setPropertyManagers(state, action) {
        state.property_managers = action.payload
    },
    setVirtualRealtors(state, action) {
        state.virtual_realtors = action.payload
    },
    setCSVCollections(state, action) {
        state.csvCollections = action.payload
    },
    deleteCSVCollection(state, action) {
        let collections = state.csvCollections
        let idToDelete = action.payload
        let otherCollections = collections.filter(c => c.id != idToDelete)
        state.csvCollections = otherCollections
    },
    updateCSVCollection(state, action) {
        let data = action.payload
        let collections = state.csvCollections
        collections.forEach(c => {
            if (c.id == data.id) {
                c.assigned_to = data.assigned_to
                c.assigned_date = data.assigned_date
            }
        })
    },
    setAssignCSVStatus(state, action) {
        state.assignCSVStatus = action.payload
    },
    setCSVInventoryCollection(state, action) {
        state.csvInventoryCollections = action.payload
    },
    }
})

export default appSlice.reducer;

export const {
    setDashboardData,
    setInventoryManagers,
    setPropertyManagers,
    setVirtualRealtors,
    setCSVCollections,
    deleteCSVCollection,
    updateCSVCollection,
    setAssignCSVStatus,
    setCSVInventoryCollection
} = appSlice.actions;

export const getDashboardData = () => async (dispatch, getState) => {
    let unsub = onSnapshot(doc(db, 'stats', 'dashboard'), (docSnap) => {
        dispatch(setDashboardData(docSnap.data()))
    });   
}

export const getInventoryManagers = () => async (dispatch, getState) => {
    api.getDocById({ table: 'stats', id: 'inventory_management' }).then((res) => {
        dispatch(setInventoryManagers(res.inventory_managers))
    })
}

export const getPropertyManagers = () => async (dispatch, getState) => {
    api.getDocById({ table: 'stats', id: 'inventory_management' }).then((res) => {
        dispatch(setPropertyManagers(res.property_managers))
    })
}

export const getVirtualRealtors = () => async (dispatch, getState) => {
    api.getAllDocs({
        collectionName: 'users',
        wheres: [["role", "==", 'virtual_realtor']],
        order: ("firstname")
    })
        .then(
            response => {
                console.log(response)
                dispatch(setVirtualRealtors(response))
            })
}

export const getAllCSVCollections = () => async (dispatch, getState) => {
    api.getAllDocs({ collectionName: 'csv_queries_collections', }).then((res) => {
        console.log(res)
        dispatch(setCSVCollections(res))
    })
}

export const deleteCSVCollectionInfo = (collection) => async (dispatch, getState) => {
    await deleteDoc(doc(db, `csv_queries_collections`, collection.id));
    dispatch(deleteCSVCollection(collection.id))
}

export const assingCSVCollection = (inventoryManager, collectionId) => async (dispatch, getState) => {
    dispatch(setAssignCSVStatus({ processing: true }))
    let data = {
        assigned_to: inventoryManager,
        assigned_date: serverTimestamp(),
        id: collectionId,
    }
    dispatch(updateCSVCollection(data))
    let result = await api.setDocById({ collection: 'csv_queries_collections', data })
    dispatch(setAssignCSVStatus({ completed: true }))
}


export const getInventoryCSVCollections = () => async (dispatch, getState) => {
    let userData = selectUserData(getState())
    if(userData){
        api.getAllDocs({ collectionName: 'csv_queries_collections', wheres:[['assigned_to.id', '==', `${userData.id}` ]] }).then((res) => {
            console.log(res)
            dispatch(setCSVInventoryCollection(res))
        })
    }

}

// export const addQueriesToAlgolia = ( ) => async(dispatch, getState) =>{
//     console.log('reaching here')
//     let obj = {
//         records : []
//     }
//     api.getAllDocs({ collectionName: 'queries', order: ("created"), }).then(
//         response => {
//          console.log(response)
//          response.forEach( query => {
//             query.objectID = query.id
            
//             query.created = query.created.toMillis()
            
//             if(query.inventoried_date){
//                 query.inventoried_date = query.inventoried_date?.toMillis()
//             }
//             if(query.hot_dealed_date){
//                 query.hot_dealed_date =  query.hot_dealed_date?.toMillis()
//             }

//             obj.records.push(query)
//          })
//          let json = JSON.stringify(obj);
//          console.log(json)
//          console.log(obj.records)
//          //write this to a file
         
         

//         //  queriesIndex.saveObjects()
//     })
//     // const addToAlgolia = httpsCallable(functions, 'createAlgoliaIndex');
//     // addToAlgolia().then((res)=>{
//     //     console.log(res)
//     // }).catch((err) => {
//     //     console.log(err)
//     // })
// }

export const selectDashboardData = (state) => state.app.dashboardData
export const selectIM = (state) => state.app.inventory_managers
export const selectPM = (state) => state.app.property_managers
export const selectVR = (state) => state.app.virtual_realtors

export const selectCSVCollections = (state) => state.app.csvCollections


export const selectAssignCSVStatus = (state) => state.app.assignCSVStatus
export const selectCSVInventoryCollections = (state) => state.app.csvInventoryCollections