
import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_COLORS } from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import { approveContract, selectIsApprovedContract, selectIsApprovingContract } from '../../store/query';

export default function ApproveContract({id, onClose}) {

  let dispatch = useDispatch()
  let [approveContractRemarks, setApproveContractRemarks] = useState('')
  let isContractApproved = useSelector(state => selectIsApprovedContract(state, id))
  let isApprovingContract = useSelector(selectIsApprovingContract);

  const handleSubmit = () => {
    dispatch(approveContract({ id: id, approveContractRemarks: approveContractRemarks }))
  }

  useEffect(() => {
    if (isContractApproved) {
      onClose()
    }
  }, [isContractApproved])
  return (
    <div style={{}}>
      <TextField
        name={'contract_approved_remarks'}
        text={approveContractRemarks}
        type={'text'}
        placeholder={'Add Remarks'}
        onTextChange={(target) => {  setApproveContractRemarks(target.value) }}
        onEnter={() => { }}
        autoFocus={true}
        style={{ color: APP_COLORS.SECONDARY_TEXT }}
        multiLine={true}
      // onCancel = {} 
      // onFocus = {}
      // onClear = {} 
      />
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}>
        {isApprovingContract ?
          <Loader />
          :
          <Button
            label={'Send to Approved Contracts'}
            onClick={handleSubmit}
            style={{ textAlign: "center", padding: 10, fontSize: "16px" }}
          />}
      </div>

    </div>
  )
}
