import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"
import { getFunctions } from "firebase/functions"
import { getDatabase } from "firebase/database";
 
export const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
export const auth  = getAuth(app);
export const db = getFirestore(app);
export const realtimeDB = getDatabase(app)
export const storage = getStorage(app);
export const functions = getFunctions(app);








