
import { useState } from 'react';
import { APP_COLORS } from '../../styles';
import Icon from '../common/Icon';


export default function PropertyView({ data, onEdit, onDelete, idx, onImagePreview }) {
  let [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <div> <b>{idx + 1}. </b>
            {!isExpanded &&
              <span style={{ paddingLeft: 10, fontSize: '11px', color: APP_COLORS.SECONDARY_TEXT }}>
                <b>Address: </b> <span style={{ fontStyle: 'italic', paddingRight: 10 }}> {data.address}.</span>
                <b>State: </b> <span style={{ fontStyle: 'italic', paddingRight: 10 }}>{data.state.label}.</span>
                <b>Demand: </b> <span style={{ fontStyle: 'italic', paddingRight: 10 }}>{data.price}</span>
              </span>
            }
          </div>
          <div style={{ float: 'right', display: 'flex', gap: 10, }}>
            <Icon name={isExpanded ? 'caret-up-fill' : 'caret-down-fill'} family='BootstrapIcons' styles={styles.icon} onClick={() => setIsExpanded(!isExpanded)} />
            <Icon name='pencil-fill' family='BootstrapIcons' styles={styles.icon} onClick={() => onEdit(idx)} />
            <Icon name='trash-fill' family='BootstrapIcons' styles={styles.icon} onClick={() => onDelete(idx)} />
          </div>
        </div>
        {isExpanded &&
          <div style={{ display: 'flex', flexDirection: 'row', padding: 10, justifyContent:'space-evenly', }}>
            
            <div style={{ width:'50%', display: 'flex', flexDirection: 'column', gap:5 }}>
               <div style={styles.pane}>
                    <div style={styles.subHeading}> Address </div>
                    <div style={styles.content}>{data.address}</div>
                  </div>

                  <div style={styles.pane}>
                    <div style={styles.subHeading}> State </div>
                    <div style={styles.content}>{data.state.label}</div>
                  </div>  
                         
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Condition </div>
                    <div style={styles.content}>{data.conditon}</div>
                  </div>
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Area of Property </div>
                    <div style={styles.content}>{data.area}</div>
                  </div>
                  
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Occupation </div>
                    <div style={styles.content}>{data.occupation}</div>
                  </div>
                  
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Moving TimeOff </div>
                    <div style={styles.content}>{data.moving_toff}</div>
                  </div>
                  
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Offered Price </div>
                    <div style={styles.content}>{data.price}</div>
                  </div>
                  
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Client Demand  </div>
                    <div style={styles.content}>{data.asking_price}</div>
                  </div>
                  
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Zillow Price </div>
                    <div style={styles.content}>{data.zestimate}</div>
                  </div>

                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Remarks </div>
                    <div style={styles.content}>{data.remarks}</div>
                  </div>
            </div>

            <div style={{ width: '30%', display: 'flex', gap: 20, flexWrap: 'wrap', justifyContent: 'space-between' }}>
              
                <div>
                {data.links?.map( (link,idx) => (
                  <div key={idx} style={{
                    display:'flex', 
                    width:'200px',
                    flexDirection:'row', 
                    fontSize:'14px',}}>
                     <div style={{width:'50%', fontWeight:'bold'}} > {'Link ' + (idx+1) } </div>
                     <div style={styles.content}>
                       <a href={link} target="_blank" > {link} </a> 
                       </div>
                </div>
                ))}
                </div>
              
              {data?.previewImages?.length ? data?.previewImages.map((image, idx) => (
                <div key={idx} style={{ height: "100px", width: '100px', position: 'relative' }} >
                  <div style={{
                    background: 'white',
                    left: '90%', top: 2,
                    borderRadius: '50%',
                    position: 'absolute',
                    filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6))',
                    zIndex: 99,
                  }}>
                  </div>
                  <img src={image.src} onClick={(e) => { onImagePreview(idx) }} style={{ width: '100%', height: '100%' }} />

                </div>
              )
              ) : null}
            </div>
          </div>
        }

      </div>
    </>
  )
}

const styles = {
  container: {
    margin: 10,
    padding: 10,
    border: '1px solid gray',
    borderRadius: '10px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    color: APP_COLORS.LINK_ORANGE
  },
  pane: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 2,
    fontSize: '14px',
  },
  subHeading: {
    fontWeight: 'bolder',
  },
  content: {
    marginLeft: 5,
    textAlign: 'right',
    whiteSpace: "no-wrap",
    overflow: ' hidden',
    textOverflow: 'ellipsis'
  },
}