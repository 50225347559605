import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import {selectAllListings, getListings, selectIsFetchingListings} from '../store/query';
import { ROLE } from '../constants';
import {  pageStyles } from '../styles';
import {  Outlet } from 'react-router-dom';

export default function Listings ({}){
  const dispatch = useDispatch();
  let user = useSelector(selectUserData);
  let role = user?.role

  let listings = useSelector(selectAllListings);
  let isFetching = useSelector(selectIsFetchingListings);

  useEffect(() => {
      if (role && (role == ROLE.Admin || role == ROLE.DispositionManager || role == ROLE.PropertyManager )) {
      dispatch(getListings())
    }

    if( role && (role ==  ROLE.InventoryManager)){
      dispatch(getListings('', user.id))
    }

  }, [])

  return (<div style={{ ...pageStyles, ...styles.container }}>
    <Section
      title={'Listings'}
      content={
        <>
        {listings && <QueryListView 
        role={role} 
        data={listings}  
        isFetching = {isFetching}
        />}
        <Outlet/>
        </>
      }
    />

</div>)
}

const styles = { 
  container:{
   
},
}

