import React, {useState, useEffect,} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {BsPersonFillCheck, BsPersonFillSlash} from "react-icons/bs";
import { APP_COLORS } from '../../styles';
import Icon from '../common/Icon';


export default function AllUsers ({data, onEditClicked, enableOrDisableUser}){
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const columns = [
    {field:'firstname', headerName:"First Name", },
    {field:'lastname', headerName:'Last Name', },
    {field:'role', headerName:'Role', width:200 },
    {field:'email', headerName:'Email', width:300},
    {field:'office', headerName:'Office', width:100},
    {field:'disabled', headerName:'Disabled', width:100},
    {
      field:'actions', 
      headerName:'Edit',
      sortable:false, 
      width:80,
      renderCell: (params) => { return <Icon 
        onClick = {() => onEditClicked(params.row) } 
        family = {'BootstrapIcons'} 
        name = {'pencil-square'}
        styles={{fontSize:'22px'}}
        />
      }
    },
  ]
    return(
      <div style={{height:'600px', width:"100%", }}>
        <ThemeProvider theme={darkTheme}>
        <DataGrid 
          rows={data}
          columns = {columns}
          style={{color:APP_COLORS.PRIMARY_TEXT}}
        />
        </ThemeProvider>
      </div>
    )
}

