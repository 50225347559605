import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getAllCSVCollections, selectCSVCollections, selectIM, assingCSVCollection, selectAssignCSVStatus, setAssignCSVStatus } from "../../store/app";
import { deleteCSVCollection, selectDeleteJobStatus, setDeleteJobStatus } from "../../store/csv";
import { APP_COLORS, pageStyles } from "../../styles";
import { useNavigate } from "react-router-dom";
import UploadQueries from "./UploadQueries";
import { confirmAlert } from "../common/ConfirmationPrompt";
import Dialogue from "../common/Dialogue";

export default function CSVQueries({ }) {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    let csvCollections = useSelector(selectCSVCollections)
    let deleteJobStatus = useSelector(selectDeleteJobStatus)
    
    let inventoryManagers = useSelector(selectIM)
    let assignCSVStatus = useSelector(selectAssignCSVStatus) 
    let [showAssignDialog, setShowAssignDialog] = useState(false)

    useEffect(() => {
        dispatch(getAllCSVCollections())
    }, [])

    useEffect(() => {
        if (deleteJobStatus?.completed) {
            alert('Delete Successful')
            dispatch(setDeleteJobStatus(null))
        } else if (deleteJobStatus?.failed) {
            alert('Delete Failed. Try Again')
            dispatch(setDeleteJobStatus(null))
        }
    }, [deleteJobStatus])

    useEffect(()=>{
        if(assignCSVStatus){
            if(assignCSVStatus.processing){

            }else if(assignCSVStatus.completed){
                alert('CSV Assigned, successfully!')
                setShowAssignDialog(null)
                dispatch(setAssignCSVStatus(null))
            }
        }
    },[assignCSVStatus])

    return (
        <>
            <div style={{ ...pageStyles, color: APP_COLORS.SECONDARY_TEXT }}>
                <div style={{ fontSize: 20, fontWeight: 'bolder', }}>
                    CSV Collections
                </div>
                <div style={{ margin: '20px 0px', }}>
                    {csvCollections?.length ? csvCollections.map((collection) => (
                        <div style={{
                            padding: 10,
                            borderBottom: '2px solid #333',
                            height: 50,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }} >
                            <div
                                style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}
                                onClick={() => { console.log(collection) }}>
                                <div style={{ fontSize: '18px', fontWeight: 'bolder' }}> {collection.name} </div>
                                <div style={{ display: 'flex', gap: 20 }}>
                                    <div
                                        onClick={() => { navigate('/dashboard/csv/' + collection.id, { state: { collection } }) }}
                                        style={{ cursor: 'pointer' }} >
                                        View
                                    </div>
                                    <div
                                        onClick={() => { setShowAssignDialog({ show: true, cId: collection.id }) }}
                                        style={{ cursor: 'pointer' }}>
                                        {collection.assigned_to ? 'Re-' : ''}Assign
                                    </div>

                                    <div
                                        onClick={() => {
                                            confirmAlert({
                                                title: 'Confirm Delete',
                                                message: `This action cannot be undone, will delete Collection along with its metadata`,
                                                onConfirm: () => {
                                                    dispatch(deleteCSVCollection(collection))
                                                },
                                                onReject: () => { },
                                            })
                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        Delete
                                    </div>

                                </div>

                            </div>
                            {collection.assigned_to && <div style={{ padding: '4px 5px', fontSize: '14px' }}>
                                Assigned to {collection.assigned_to?.name}
                            </div>}
                        </div>
                    )) : null}
                </div>
            </div>
            <UploadQueries />
            {!!showAssignDialog && showAssignDialog.show &&
                <Dialogue
                    headerText={'Assign To IM'}
                    content={<div style={{ height: 600, overflowY: 'scroll' }}>
                        {inventoryManagers?.length && inventoryManagers.map(im => (
                            <div style={{
                                padding: '8px 5px',
                                border: '1px solid #333',
                                display: 'flex',
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                    confirmAlert({
                                        title: 'Confirm Assign',
                                        message: `Are you sure you want to assign this CSV File to ${im.name}?`,
                                        onConfirm: () => {
                                            dispatch(assingCSVCollection(im, showAssignDialog.cId))
                                        },
                                        onReject: () => { },
                                    })
                                }}
                            >
                                <div>  {im.name} </div>
                            </div>
                        ))}
                    </div>}
                    show={showAssignDialog.show}
                    onClose={() => setShowAssignDialog(null)}
                />
            }
        </>
    )
}

