import { createSlice } from '@reduxjs/toolkit'
import { selectUserData } from './user';
import * as firestoreComments from '../services/firebase/commentsApi';
import {transformCommentsData} from "./utils"

const initialState = {
  comments: {},
  isCommentsPanelOpen: false,
}

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {

    onCommentsFetched(state, action) {
      let { comments, queryId } = action.payload
      if (!state.comments[queryId]) {
        state.comments[queryId] = {}
      }
      state.comments[queryId] = comments
    },
    onCommentRemoved(state, action) {
    //   let { callId, subId, commentId } = action.payload
    //   let comments = state.comments[callId] && state.comments[callId][subId]
    //   if (comments) {
    //     state.comments[callId][subId] = comments.filter(c => c.id != commentId)
    //   }
    },
    resetComments() {
      return initialState;
    }
  },
  
})

export default commentsSlice.reducer

export const {
  onCommentsFetched,
  onCommentRemoved,
  toggleShowCommentPanel,
  resetComments
} = commentsSlice.actions

export const fetchComments = ( queryId ) => async (dispatch, getState) => {
    console.log('reaching')
  firestoreComments.getComments({ subId:queryId }, (comments, queryId) => {
    if (comments && comments.removed) {
      dispatch(onCommentRemoved({ queryId }))
    } else {
        let { data } = transformCommentsData(comments)  
      dispatch(onCommentsFetched({ queryId, comments : data }))
    }
  })
}


export const addComment = (data) => async (dispatch, getState) => {
  let state = getState()
  
  const userData = selectUserData(state)
  if (!userData) {
    console.error("USER NOT LOGGED IN SOMEHOW")
    return;
  }

  let cData = {
    ...data,
    sender: {
        name: userData.firstname + ' ' +userData.lastname,
        id: userData.id,
        role : userData.role,
        email : userData.email,
        alias : userData.alias || '',
    },
  }

  firestoreComments.addComment(cData).then(commentId => {
    console.log(commentId)
  })
  
}

// export const updateComment = ({
//   queryId,
//   updatingCommentId,
//   newData
// }) => async (dispatch, getState) => {
//   let state = getState()
  
//   let comments = selectComments(state, queryId)
//   let existingComment = comments.find(c => c.id == updatingCommentId);

//   let cData = {
//     existingComment,
//     ...newData
//   }
//   firestoreComments.updateComment(cData, updatingCommentId, true)
// }


// export const likeUnlikeComment = (comment, emoji) => async (dispatch, getState) => {
//   const userData = selectUserData(getState())
//   const isLiked = comment.reactions && comment.reactions.find(r => r.user == userData.email && r.emoji == emoji.native)
//   // const shouldLike = !isLiked

//   let reactions = [...comment.reactions];
//   if (!isLiked) {
//     let reaction = {
//       user: userData.email,
//       emoji: emoji.native,
//       emoji_id: emoji.id,
//       // created: firestoreTimestamp, //firebase.firestore.Timestamp.now(),
//     }
//     let sameEmojiExists = comment.reactions.find(r => r.emoji == emoji.native)
//     if (sameEmojiExists) {
//       let index = comment.reactions.indexOf(sameEmojiExists)
//       reactions.splice(index, 0, reaction);
//     } else {
//       reactions.push(reaction)
//     }
//   } else {
//     reactions = reactions.filter(r => (r.user != userData.email || r.emoji != emoji.native))
//   }

//   firestoreComments.updateComment({ reactions }, comment.id, false)
// }

// export const deleteComment = ({ id, call_id, file_id }) => async (dispatch, getState) => {
//   firestoreComments.deleteComment(id)
// }

export const selectComments = (state, queryId) => ( state.comments.comments && state.comments.comments[queryId] )


