
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { Provider } from 'react-redux';
import * as routes from "./routes";
import SimpleReactLightbox from 'simple-react-lightbox';

import store from './store';
import './app.css';

function App() {

  return (

    <Provider store={store}>
      <SimpleReactLightbox>
        <BrowserRouter basename="/">
          <routes.AppRoutes />
        </BrowserRouter>
      </SimpleReactLightbox>
    </Provider>
  );
}

export default App;


