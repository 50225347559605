import { DateTime } from 'luxon';


export const validate = (name, value, isEdit) => {
  //A function to validate each input values
  let errors = {
    alias: null,
    firstname: null,
    lastname: null,
    email: null,
    password: null,
    fullname: null,
    address: null,
    state: null,
  };
  switch (name) {
    case 'alias':
      if (value.length <= 3) {
        errors.alias = 'Alias should have atleast 3 letters';
      } else {
        errors.alias = null
      }
      break;
    case 'firstname':
      if (value.length <= 2) {
        // we will set the error state
        errors.firstname = 'Firstname should have atleast 2 letters';
      } else {
        // set the error state empty or remove the error for username input
        //omit function removes/omits the value from given object and returns a new object
        errors.firstname = null
      }
      break;
    case 'lastname':
      if (value.length <= 2) {
        // we will set the error state
        errors.lastname = 'Lastname should have atleast 2 letters'
      } else {
        // set the error state empty or remove the error for username input
        //omit function removes/omits the value from given object and returns a new object
        errors.lastname = null
      }

    case 'email':
      if (
        !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
      ) {
        //    errors.email = 'Enter a valid email address'
      } else {
        errors.email = null;
      }
      break;

    case 'password':
      if (!new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value) && !isEdit) {
        errors.password = 'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
      } else {
        errors.password = null;
      }
      break;

    case 'phone_no':
      if (!new RegExp(/^\d{10}$/).test(value)) {
        errors.phone_no = 'Number must be 10 digit and numeric'
      }
      break;
    //   case 'fullname':
    //       if(value.length == 0){
    //         errors.fullname = 'Add a name, cant be empty!'
    //       }
    //       break;
    case 'address':
      if (value.length == 0) {
        errors.address = 'Add an address, can not be empty!'
      }
      break;
    case 'state':
      if (value.length == 0) {
        errors.state = 'Specify State!'

      }
    //   case 'price' : 
    //         if(value.length == 0){
    //             errors.price = 'Add a price.'
    //         }
    //     break;
    default:
      break;
  }
  return errors;
}

export const checkForErrors = (errors) => {

  for (let key in errors) {
    if (errors[key]) {
      return true
    }
  }
  return false;
}

export const getUsTimestamp = (jsDate) => {
  return DateTime.fromJSDate(jsDate, { zone: 'America/New_York' })
}

export const getLocaleUSDate = (jsDate) => {
  return jsDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/New_York',
    timeZoneName: 'short'
  })
}

export const filterDateResults = (view, start, end, data) => {

  let startDate = DateTime.fromJSDate(start, { zone: 'America/New_York' }).set({ hours: 0, minute: 1 })
  let endDate = DateTime.fromJSDate(end, { zone: 'America/New_York' }).set({ hours: 23, minute: 59 })

  let dateRanged;

  if (view == 'queries' || view == 'myQueries' || view == 'allQueries') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.timestamp >= startDate && item.timestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.timestamp >= startDate && item.timestamp <= endDate
      } else {
        return item
      }
    });


  } else if (view == 'approved_queries') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.approvedTimestamp >= startDate && item.approvedTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.approvedTimestamp >= startDate && item.approvedTimestamp <= endDate
      } else {
        return item
      }
    });


  } else if (view == 'approved_contracts') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.contractApprovedTimestamp >= startDate && item.contractApprovedTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.contractApprovedTimestamp >= startDate && item.contractApprovedTimestamp <= endDate
      } else {
        return item
      }
    });

  } else if (view == 'sent_contracts') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.contractSentTimestamp >= startDate && item.contractSentTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.contractSentTimestamp >= startDate && item.contractSentTimestamp <= endDate
      } else {
        return item
      }
    });

  } else if (view == 'signed_contracts') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.contractSignedTimestamp >= startDate && item.contractSignedTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.contractSignedTimestamp >= startDate && item.contractSignedTimestamp <= endDate
      } else {
        return item
      }
    });

  } else if (view == 'inventory') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.inventoriedTimestamp >= startDate && item.inventoriedTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.inventoriedTimestamp >= startDate && item.inventoriedTimestamp <= endDate
      } else {
        return item
      }
    });

  } else if (view == 'inventory_contracts') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.inventoryContractSubmittedTimestamp >= startDate && item.inventoryContractSubmittedTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.inventoryContractSubmittedTimestamp >= startDate && item.inventoryContractSubmittedTimestamp <= endDate
      } else {
        return item
      }
    });

  } else if (view == 'hot_deals') {
    dateRanged = data.filter((item) => {
      if (startDate.toMillis() && !endDate.toMillis()) {
        let upper = startDate.set({ hour: 23, minute: 59 })
        return item.inventoryHotdealedTimestamp >= startDate && item.inventoryHotdealedTimestamp <= upper
      }
      if (startDate.toMillis() && endDate.toMillis()) {
        return item.inventoryHotdealedTimestamp >= startDate && item.inventoryHotdealedTimestamp <= endDate
      } else {
        return item
      }
    });
  }

  return dateRanged
}

export const US_STATES = [
  {
    value: "Alabama",
    label: "Alabama",
    abbr: "AL"
  },
  {
    value: "Alaska",
    label: "Alaska",
    abbr: "AK"
  },
  {
    value: "American Samoa",
    label: "American Samoa",
    abbr: "AS"
  },
  {
    value: "Arizona",
    label: "Arizona",
    abbr: "AZ"
  },
  {
    value: "Arkansas",
    label: "Arkansas",
    abbr: "AR"
  },
  {
    value: "California",
    label: "California",
    abbr: "CA"
  },
  {
    value: "Colorado",
    label: "Colorado",
    abbr: "CO"
  },
  {
    value: "Connecticut",
    label: "Connecticut",
    abbr: "CT"
  },
  {
    value: "Delaware",
    label: "Delaware",
    abbr: "DE"
  },
  {
    value: "District Of Columbia",
    label: "District Of Columbia",
    abbr: "DC"
  },
  {
    value: "Federated States Of Micronesia",
    label: "Federated States Of Micronesia",
    abbr: "FM"
  },
  {
    value: "Florida",
    label: "Florida",
    abbr: "FL"
  },
  {
    value: "Georgia",
    label: "Georgia",
    abbr: "GA"
  },
  {
    value: "Guam",
    label: "Guam",
    abbr: "GU"
  },
  {
    value: "Hawaii",
    label: "Hawaii",
    abbr: "HI"
  },
  {
    value: "Idaho",
    label: "Idaho",
    abbr: "ID"
  },
  {
    value: "Illinois",
    label: "Illinois",
    abbr: "IL"
  },
  {
    value: "Indiana",
    label: "Indiana",
    abbr: "IN"
  },
  {
    value: "Iowa",
    label: "Iowa",
    abbr: "IA"
  },
  {
    value: "Kansas",
    label: "Kansas",
    abbr: "KS"
  },
  {
    value: "Kentucky",
    label: "Kentucky",
    abbr: "KY"
  },
  {
    value: "Louisiana",
    label: "Louisiana",
    abbr: "LA"
  },
  {
    value: "Maine",
    label: "Maine",
    abbr: "ME"
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
    abbr: "MH"
  },
  {
    value: "Maryland",
    label: "Maryland",
    abbr: "MD"
  },
  {
    value: "Massachusetts",
    label: "Massachusetts",
    abbr: "MA"
  },
  {
    value: "Michigan",
    label: "Michigan",
    abbr: "MI"
  },
  {
    value: "Minnesota",
    label: "Minnesota",
    abbr: "MN"
  },
  {
    value: "Mississippi",
    label: "Mississippi",
    abbr: "MS"
  },
  {
    value: "Missouri",
    label: "Missouri",
    abbr: "MO"
  },
  {
    value: "Montana",
    label: "Montana",
    abbr: "MT"
  },
  {
    value: "Nebraska",
    label: "Nebraska",
    abbr: "NE"
  },
  {
    value: "Nevada",
    label: "Nevada",
    abbr: "NV"
  },
  {
    value: "New Hampshire",
    label: "New Hampshire",
    abbr: "NH"
  },
  {
    value: "New Jersey",
    label: "New Jersey",
    abbr: "NJ"
  },
  {
    value: "New Mexico",
    label: "New Mexico",
    abbr: "NM"
  },
  {
    value: "New York",
    label: "New York",
    abbr: "NY"
  },
  {
    value: "North Carolina",
    label: "North Carolina",
    abbr: "NC"
  },
  {
    value: "North Dakota",
    label: "North Dakota",
    abbr: "ND"
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    abbr: "MP"
  },
  {
    value: "Ohio",
    label: "Ohio",
    abbr: "OH"
  },
  {
    value: "Oklahoma",
    label: "Oklahoma",
    abbr: "OK"
  },
  {
    value: "Oregon",
    label: "Oregon",
    abbr: "OR"
  },
  {
    value: "Palau",
    label: "Palau",
    abbr: "PW"
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania",
    abbr: "PA"
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
    abbr: "PR"
  },
  {
    value: "Rhode Island",
    label: "Rhode Island",
    abbr: "RI"
  },
  {
    value: "South Carolina",
    label: "South Carolina",
    abbr: "SC"
  },
  {
    value: "South Dakota",
    label: "South Dakota",
    abbr: "SD"
  },
  {
    value: "Tennessee",
    label: "Tennessee",
    abbr: "TN"
  },
  {
    value: "Texas",
    label: "Texas",
    abbr: "TX"
  },
  {
    value: "Utah",
    label: "Utah",
    abbr: "UT"
  },
  {
    value: "Vermont",
    label: "Vermont",
    abbr: "VT"
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands",
    abbr: "VI"
  },
  {
    value: "Virginia",
    label: "Virginia",
    abbr: "VA"
  },
  {
    value: "Washington",
    label: "Washington",
    abbr: "WA"
  },
  {
    value: "West Virginia",
    label: "West Virginia",
    abbr: "WV"
  },
  {
    value: "Wisconsin",
    label: "Wisconsin",
    abbr: "WI"
  },
  {
    value: "Wyoming",
    label: "Wyoming",
    abbr: "WY"
  }
]