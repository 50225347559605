
import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_COLORS } from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import { signContract, selectIsContractSigned, selectIsSigningContract } from '../../store/query';

export default function SignContractForm({id, onClose, view}) {

  let dispatch = useDispatch()
  let [signContractRemarks, setSignContractRemarks] = useState('')
  let isContractSigned = useSelector(state => selectIsContractSigned(state, id))
  let isSigningContract = useSelector(selectIsSigningContract);

  const handleSubmit = () => {
    dispatch(signContract({ id: id, signContractRemarks: signContractRemarks, view:view }))
  }

  useEffect(() => {
    if (isContractSigned) {
      onClose()
    }
  }, [isContractSigned])
  return (
    <div style={{}}>
      <TextField
        name={'contract_sign_remarks'}
        text={signContractRemarks}
        type={'text'}
        placeholder={'Add Remarks'}
        onTextChange={(target) => {  setSignContractRemarks(target.value) }}
        onEnter={() => { }}
        autoFocus={true}
        style={{ color: APP_COLORS.SECONDARY_TEXT }}
        multiLine={true}
      // onCancel = {} 
      // onFocus = {}
      // onClear = {} 
      />
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}>
        {isSigningContract ?
          <Loader />
          :
          <Button
            label={'Sign Contract'}
            onClick={handleSubmit}
            style={{ textAlign: "center", padding: 10, fontSize: "16px" }}
          />}
      </div>

    </div>
  )
}
