export const APP_COLORS = {
  PAGE_BACKGROUND: '#2e2f32',
  SECONDARY_BACKGROUND: '#1a1c20',
  META_BACKGROUND: '#212529',
  BUTTON_BACKGROUND: '#ea6a47',
  PRIMARY_TEXT: "#a7a7a8",
  SECONDARY_TEXT: "#c2c2c2",
  BORDER_COLOR: 'rgba(255, 255, 255, 0.1)',
  LINK_ORANGE: '#ea6a47',
  BLUE: '#216aa4',
  GREEN: '#1AA260',
  RED: '#e44f50'
}


//sidebar, navbar color : #1a1c20
//page background color : 2e2f32
//title color : white
// text color : 626567



export const pageStyles = {
  width: '85vw',
  padding: '20px 20px',
}

export const adminComponentsStyles = {

}