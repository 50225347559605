import { createSlice } from '@reduxjs/toolkit'
import * as api from '../services/firebase/api';
import { resetAllOnLogout } from './query';
import { functions } from '../services/firebase/config';
import { httpsCallable } from "firebase/functions";
import { arrayUnion, setDoc, doc } from 'firebase/firestore';
import { db } from '../services/firebase/config';
import { ROLE } from '../constants';


const initialState = {
  user: null,
  isFetchingUserData: false,
  isLoggedIn: false,
  loggingIn: false,
  loginError: null,
  addingUser: false,
  userAdded: false,
  allUsers: null,
  editUserData: {},
  updatingUser: false,
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loggingIn(state, action) {
      state.loggingIn = true
    },
    userAuthenticated(state, action) {
      state.loggingIn = false
      state.isLoggedIn = true
      state.loginError = null
    },
    setUserData(state, action) {
      state.user = action.payload
    },
    setLoginError(state, action) {
      state.loggingIn = false;
      state.loginError = action.payload
    },
    userLoggedOut(state, action) {
      state.user = null;
      state.isLoggedIn = false;
      state.loginError = false;
    },
    setAddingUser(state, action) {
      state.addingUser = action.payload
    },
    setUserAdded(state, action) {
      let { status, newUser } = action.payload
      state.addingUser = false
      state.userAdded = status
      let allUsers = state.allUsers;
      state.allUsers = [...allUsers, newUser];
    },
    setAllUsers(state, action) {
      state.allUsers = action.payload;
    },
    setUpdatingUser(state, action) {
      state.updatingUser = action.payload
    },
    setUpdatedUser(state, action) {
      let { status, updatedUser } = action.payload
      state.updatingUser = false
      state.userAdded = status
      let allUsers = state.allUsers
      allUsers.forEach( user => {
        if (user.id == updatedUser.id) {
            user.firstname = updatedUser.firstname;
            user.lastname = updatedUser.lastname;
            user.role = updatedUser.role;
            user.office = updatedUser.office;
            user.disabled = updatedUser.disabled
            user.email = updatedUser.email
        }
      })
      state.allUsers = allUsers
    }
  }
})
export default userSlice.reducer

export const {
  loggingIn,
  userAuthenticated,
  setUserData,
  setLoginError,
  userLoggedOut,
  setUserAdded,
  setAddingUser,
  setAllUsers,
  updateAllUsers,
  setUpdatingUser,
  setUpdatedUser
} = userSlice.actions

export const getAllUsers = () => async (dispatch, getState) => {
  let cachedUsers = getState().users.allUsers || null;
  if (!cachedUsers) {
    api.getAllDocs({
      collectionName: 'users',
      order: ("role"),
      ascending: true,
      wheres: [["role", "not-in", ["dev", "admin"]]]
    }).then(
      response => {
        dispatch(setAllUsers(response))
      }
    )
  } else {
    dispatch(setAllUsers(cachedUsers))
  }
}

export const addUserToFirebase = (userCredentials) => async (dispatch, getState) => {
  dispatch(setAddingUser())
  api.registerWithEmailAndPassword(userCredentials).then(
    (response) => {
      console.log('response', response)
      dispatch(setUserAdded({ status: true, newUser: response }))
    }
  ).catch(
    err => console.log(err)
  )
}

export const updateUser = (userData) => async (dispatch, getState) => {
  dispatch(setUpdatingUser(true))
  const updateUser = httpsCallable(functions, 'updateUser');
  let updateData = {
    uid: userData.id,
    ...userData
  }
  updateUser(updateData)
    .then((result) => {
      console.log(result.data)
      dispatch(setUpdatedUser({ status: true, updatedUser: { id:userData.id, ...result.data} }))
    }).catch((err) => {
      console.log(err)
      dispatch(setUpdatingUser(false))
    })
}

export const createUser = (userData) => async (dispatch, getState) => {
  dispatch(setAddingUser(true))
  console.log(userData)
  const createUser = httpsCallable(functions, 'createUser');
  createUser(userData)
    .then((result) => {
      console.log(result)
      let metaDataObj = {
        uid: result.data.id,
        role : userData.role,
        firstname : userData.firstname,
        lastname : userData.lastname,  
      }
      updateMetaData(metaDataObj)
      dispatch( setUserAdded({ status: true, newUser: result.data }))
    }).catch((err) => {
      console.log(err)
      dispatch(setAddingUser(false))
    })
}

const updateMetaData = async (user) => {
  let role = user.role
  let firstname = user.firstname
  let lastname = user.lastname

  if (role == ROLE.InventoryManager) {
    const inventoryDataRef = doc(db, "stats", "inventory_management");
    await setDoc(inventoryDataRef, {
      inventory_managers: arrayUnion({ id: user.uid, name: firstname + ' ' + lastname })
    }, { merge: true });
  }
  if (role == ROLE.PropertyManager) {
    const pmsRef = doc(db, "stats", "inventory_management");
    await setDoc(pmsRef, {
      property_managers: arrayUnion({ id: user.uid, name: firstname + ' ' + lastname })
    }, { merge: true });
  }
  if (role == ROLE.DispositionManager) {
    const dmsRef = doc(db, "stats", "inventory_management");
    await setDoc(dmsRef, {
      dispo_managers: arrayUnion({ id: user.uid, name: firstname + ' ' + lastname })
    }, { merge: true });
  }
}

export const login = (userCredentials) => async (dispatch, getState) => {
  dispatch(loggingIn(true))
  api.authenticateUser(userCredentials).then(
    (response) => {
      if (response.user) {
        console.log(response.user)
        let uid = response.user.uid
        localStorage.setItem('userId', uid);
        dispatch(userAuthenticated())
        dispatch(setLoggedInUserData(uid))
      }
      if (response.error) {
        console.log(response.error)
        dispatch(setLoginError(response.error))
      }
    }
  ).catch(err => console.log(err)
  )
}

export const setLoggedInUserData = (uid) => async (dispatch, getState) => {
  api.getDocById({ table: "users", id: uid }).then((userData) => {
    dispatch(setUserData(userData))
  })
}

export const logout = () => async (dispatch, getState) => {
  dispatch(userLoggedOut());
  dispatch(resetAllOnLogout())
  localStorage.removeItem('userId');
  api.logout();
}

export const fetchUserData = (userData) => async (dispatch, getState) => {
  // let state = getState()
  // let exists = state.users.allUsers.find(user => user.id == userData.id)
  // console.log(exists)
  // if(!exists){
  let fetchUser = httpsCallable(functions, 'fetchUser')
  fetchUser(userData)
    .then((result) => {
      console.log(result)
    }).catch((err) => {
      console.log(err)
    })
  // }
}

export const selectIsLoggedIn = (state) => state.users.isLoggedIn
export const selectIsLoggedOut = (state) => !state.users.isLoggedIn
export const selectIsLogginIn = (state) => state.users.loggingIn

export const selectUserData = (state) => state.users?.user

export const selectLoginError = (state) => state.users.loginError

export const selectIsUserAdded = (state) => state.users.userAdded
export const selectIsAddingUser = (state) => state.users.addingUser
export const selectIsUpdatingUser = (state) => state.users.updatingUser
export const selectAllUsers = (state) => state.users.allUsers;