import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {APP_COLORS} from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import Select from '../common/Select';
import {moveToListing, selectIsListed, selectIsListing, } from '../../store/query';


export default function MoveToListingForm ({id, onClose , text}){
  const dispatch = useDispatch();

  let isMovedToListing = useSelector(state => selectIsListed(state,id))
  let isListing = useSelector(selectIsListing) 

  let [remarks, setRemarks ] = useState(text);
  // let [inventoryStatus, setInventoryStatus] = useState('');

  const onMove = () => {
    dispatch(moveToListing({id,remarks}))
  }

  useEffect(()=>{
    if(isMovedToListing){
      onClose()
    }
  },[isMovedToListing])

  return (
  <div style={{}}>
      <TextField 
        name={'hot_deal_remarks'}
        text = {remarks} 
        type={'text'}
        placeholder = {'Add Remarks'} 
        onTextChange = {(target)=>{console.log(target.name, target.value); setRemarks(target.value)}} 
        onEnter = {()=>{}} 
        autoFocus = {true} 
        style = {{color:APP_COLORS.SECONDARY_TEXT}} 
        multiLine={true}
        // onCancel = {} 
        // onFocus = {}
        // onClear = {} 
      />
      {/* <Select
        onChange={(e) => setInventoryStatus(e.target.value)}
        name={'Status'}
        value={inventoryStatus}
        style={{ background: 'inherit', color: APP_COLORS.SECONDARY_TEXT, height: '35px', }}
        options=
        {[
          { id: 'interested_client', text: 'Interested Client', value: 'interested_client', },
          { id: 'hot_deal', text: 'Hot Deal', value: 'hot_deal', },
        ]}
       /> */}

      <div style={{display:"flex", justifyContent:"center",paddingTop:30}}>
      {isListing ?
      <Loader/> :
       <Button
       label={'Move to Listing'}
       onClick={onMove}
       style={{textAlign:"center",padding:10, fontSize:"16px"}}
       />} 
      </div>
      
    </div>
  )
}