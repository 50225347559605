import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { APP_COLORS } from '../../styles';
import Button from './Button';
import { DateTime } from 'luxon';

export default function DateRangePicker({ onSelected }) {
    const convertDate = (date) => {
        if (date) {
            let ldate = DateTime.fromJSDate(date);
            ldate = ldate.setZone('America/New_York');
            let convertedDate = DateTime.local();
            convertedDate.setZone("America/New_York");
            convertedDate = convertedDate.set({ year: ldate.year, month: ldate.month, day: ldate.day, hour: 12, minute: 59, second: 0, millisecond: ldate.millisecond });
            return convertedDate.toJSDate()
        }
    }

    let date = new Date();
    let convertedDate = convertDate(date)

    const [startDate, setStartDate] = useState(convertedDate);
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;

        if (start == null) {
            setStartDate(convertedDate)
            setEndDate(null)
            onSelected(start, end)
        } else {
            setStartDate(start);
            setEndDate(end);
            onSelected(start, end)
        }
    };

    const CustomInput = React.forwardRef(({ value, onClick, placeholderText }, ref) => (
        <div
            className={'hoverInput'}
            style={{
                height: '50px',
                background: 'inherit',
                borderRadius: '10px',
                paddingLeft: 10,
                fontSize: '12px',
                color: APP_COLORS.PRIMARY_TEXT,
                display: 'flex',
                alignItems: "center"
            }}

            onClick={() => {
                if (ref.current)
                    ref.current.style.border = `1px solid ${APP_COLORS.LINK_ORANGE}`
                onClick()
            }}
            ref={ref}
        >

            {value && value}
            {!value && startDate}
        </div>
    ));
    return (
        <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            customInput={<CustomInput placeholderText={'Select Date...'} />}
        />
    );
}
