import { APP_COLORS } from "../styles";
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUserData } from "../store/user";
import Icon from './common/Icon';
import {AiOutlineLogout } from 'react-icons/ai'
import '../app.css';
import logo from '../assets/logo.png'
import Dropdown from "./common/Dropdown";

function Header() {
  let userData = useSelector(selectUserData)

  const dispatch = useDispatch();
  
  const iconStyles = {
    width:20, 
    height:20, 
    maxWidth: '100%',
    maxHeight: '100%'
  }
  let dropDownItems = [
    {
      title: 'Logout',
      key: "logout",
      icon: <AiOutlineLogout  style={iconStyles} />,
      onClick: () => dispatch(logout()) ,
    },
    // {
    //   title: `Get iPhone App`,
    //   key: "get_iPhone",
    //   // icon: <IoDownloadOutline  style={iconStyles}  />,
    //   onClick: ()=>dispatch(setShowInstallAppDialog(true)),
    // },
    // {
    //   title: `Log out ${userData?.name}`,
    //   // icon: <AiOutlinePoweroff  style={iconStyles}  />,
    //   key: "logout",
    //   onClick: ()=>signout(),
    // },
  ];


  return (
    <div style={styles.header}>
      <div style={{ maxWidth: '120px', display: 'flex', justifyContent: 'center', }}>
        <img src={logo} style={{ width: '100%', width: '100%' }} />
      </div>
      <div style={styles.rightNav}>
      <div
          style={styles.Icon}
          className={'hover'}
          onClick={() => {}}>
         <Icon tooltipTitle='' family='FontAwesome5' name='bell' styles={{ color: "white", fontSize:'22px' }} />
      </div>
        <Dropdown 
          dropdownStyle={{top:42,}}
          cellStyle={{color:APP_COLORS.SECONDARY_TEXT}}
          button={
            <div 
              className='hover'
              style={{
                  color:'white', 
                  cursor:'pointer',
                  background:APP_COLORS.PAGE_BACKGROUND,
                  fontWeight:'bold',
                  ...styles.Icon
                   }}>
                { userData?.firstname.charAt(0) + userData?.lastname.charAt(0) }
              </div>
            }  
            headerSection={ <>
              <div style={{
                  minWidth:200,
                  padding:20, 
                  display:'flex',
                  background:APP_COLORS.SECONDARY_BACKGROUND,
                  justifyContent:'space-between',
                  alignItems:'center',
                  color:APP_COLORS.SECONDARY_TEXT,
                  borderBottom:`1px solid ${APP_COLORS.PAGE_BACKGROUND}`
                  }}>
                  <div style={{fontSize:16, fontWeight:'bold', minWidth:160}}>
                      { userData?.firstname +' '+ userData?.lastname}
                  </div>  
                  <div style={{
                      textTransform:'uppercase',
                      letterSpacing:1.2,
                      color:'#aaa'
                      }}> {userData?.role.replace('_', ' ')   } </div>  
                  
              </div>
            </> }
          dropDownItems= {dropDownItems}
          /> 
        
      </div>


    </div>
  )
}


const styles = {
  header: {
    background: APP_COLORS.SECONDARY_BACKGROUND,
    color: APP_COLORS.SECONDARY_TEXT,
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 3px',
  },
  rightNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap:10,
    paddingRight:20,

  },
  Icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "40px",
    height: '40px',
    borderRadius: '50%',
  }
}
export default Header;