import React from "react";

const portal = document.getElementById("tooltip-root")
let tooltipRect = null;
let tooltipId = null;

const showTooltip = (text, rect, id) => {
  tooltipRect = rect
  tooltipId = id
  portal.innerText = text;
  portal.style.top = (rect.top + rect.height/2 - 25)  + "px"
  portal.style.left = ((rect.left + rect.width/2) - (portal.offsetWidth/2)) + "px"
  portal.style.opacity = 1;
}

export default function WithTooltip({ tooltipText, children, id }) {
  if(!!tooltipRect && tooltipId === id)
  {
    tooltipRect && showTooltip(tooltipText, tooltipRect, id)
  }

  return (
    <span
      onMouseEnter={(e) => tooltipText && showTooltip(tooltipText, e.target.getBoundingClientRect(), id)}
      onMouseLeave={() => {
        if(tooltipRect)
        {
          portal.style.opacity = 0
          tooltipRect = null;
        }
      }}>
      {children}
    </span>
  )
}
