import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { APP_COLORS } from "../styles";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '../components/dashboard/smallCard'
import '../app.css';
import { selectUserData } from '../store/user';
import { getDashboardData, getInventoryManagers, selectDashboardData, getVirtualRealtors, addQueriesToAlgolia } from '../store/app';
import { ROLE } from '../constants';
import CustomClock from '../components/common/CustomClock'
import DigitalClock from '../components/common/DigitalClock'
import Button from '../components/common/Button';

export default function DashboardIndex() {
  const dispatch = useDispatch()

  let dashboardData = useSelector(selectDashboardData);
  let role = useSelector(selectUserData)?.role;

  useEffect(() => {
    dispatch(getInventoryManagers())
    dispatch(getDashboardData())
    dispatch(getVirtualRealtors())
  }, [])

  return (
    <div >
      <div style={styles.pageStyles}>
        <div style={{
          padding: '15px',
          height: '30vh',
          display: 'flex',
          justifyContent: 'space-between',
          color: APP_COLORS.SECONDARY_TEXT,
          borderRadius: '5px',
          fontSize: '22px',
          fontWeight: 'bolder'
        }}>
          <div>
            Welcome to Kabir Corporation Admin
          </div>
          <div>

            <DigitalClock/>
          </div>

        </div>
        {role && (role == ROLE.Admin || role == ROLE.PropertyManager || role == ROLE.Management) &&
          <div style={styles.pane}>
            <Card
              info={dashboardData?.query_count}
              title={'Queries'}
              color={APP_COLORS.LINK_ORANGE}
              footerText={'all the queries to approve.'}
              icon={{ family: 'FontAwesome5', name: 'users-cog' }}
              iconStyle={{ fontSize: '40px' }}
            />
            <Card
              info={dashboardData?.approved_deals}
              title={'Approved Deals'}
              color={APP_COLORS.BLUE}
              footerText={'all the approved deals.'}
              icon={{ family: 'MaterialCommunityIcons', name: 'account-multiple-check' }}
              iconStyle={{ fontSize: '58px' }}
            />
            <Card
              info={dashboardData?.contracts_sent}
              title={'Contracts Sent'}
              color={APP_COLORS.RED}
              footerText={'total no of contract submissions.'}
              icon={{ family: 'Ionicons', name: 'receipt-sharp' }} />
            <Card
              info={dashboardData?.signed}
              title={'Signs'}
              color={APP_COLORS.GREEN}
              footerText={'total no of all signed contracts.'}
              icon={{ family: 'FontAwesome5', name: 'file-signature' }}
              iconStyle={{ fontSize: '40px' }} />

              {/* <Button label={'GET ALL QUERIES Here'} 
              onClick={()=>{
                dispatch(addQueriesToAlgolia())
              }} /> */}
          </div>
        }
      </div>
      <Outlet />

    </div>)
}

const styles = {
  pageStyles: {
    height: 'calc ( 100vh - 60px ) ',
    boxSizing:'border-box',
    width: '85vw',
    background:APP_COLORS.META_BACKGROUND,
    margin:'30px 30px',
    padding:10,
    borderRadius:5,
  },
  pane: {
    margin: 20,
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    alignItems: 'center',
  }
}
