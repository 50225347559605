import React, { useEffect } from 'react'
import Button from "./Button";
import { render, unmountComponentAtNode } from 'react-dom'
const ITEM_ID = 'react-confirm-alert';
let getDomElement = () => document.getElementById(ITEM_ID)

export function confirmAlert(properties) {
  let divTarget = getDomElement()
  if (!divTarget) {
    divTarget = document.createElement('div')
    divTarget.id = ITEM_ID
    document.body.appendChild(divTarget)
  }
  render(<ReactConfirmAlert {...properties} />, divTarget)
}

const ReactConfirmAlert = ({ title, message, onConfirm, onReject, rejectText, confirmationText, onClose }) => {
  const close = () => { 
    const target = getDomElement()
    if (target) {
      unmountComponentAtNode(target)
      target.parentNode.removeChild(target)
    }
    onClose && onClose()
  }
  
  const keyboardClose = event => event.keyCode === 27 && close()

  useEffect(() => {
    document.addEventListener('keydown', keyboardClose, false)
    return () => {
      document.removeEventListener('keydown', keyboardClose, false)
    }
  }, [])
  return (
    <div style={styles.continer}>
      <div style={styles.content}>
        <div style={styles.title}>{title}</div>
        <div style={styles.message}>{message}</div>
        <div style={styles.buttonsContainer}>
        <Button
          onClick={() => {
            onReject()
            close()
          }}
          label={rejectText || "No"}
          style={{margin:5, backgroundColor:'#222', border:'1px solid #888', color:'#fff'}}
        />
        <Button
          onClick={() => {
            onConfirm()
            close()
          }}
          label={confirmationText || "Yes"}
          style={{margin:5, backgroundColor:'#c33', border:'1px solid #888', color:'#fff'}}
        />
      </div>
      </div>
    </div>
  )
}

const styles = {
  continer: {
    position: 'fixed',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    top:0,
    left:0,
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    background: 'rgba(0,0,0,0.5)',
  },
  content: {
    // position: 'absolute',
    // top:'50%',
    // left:'50%',
    padding:'20px',
    background:'#222',
    border:'1px solid #333',
    borderRadius:10,
    // width: '100vw',
    // height: '100vh',
    // zIndex: 9999,
    // background: 'rgba(0,0,0,0.5)',
  },
  title:{
    textAlign:'center',
    fontWeight:'bold',
    color:'#eee',
    marginBottom:10
  },
  message:{
    color:'#aaa',
    marginBottom:10,
  },
  buttonsContainer:{
    display:'flex',
    width:'100%',
    justifyContent:'center',
  }
  
}
