
import { createSlice } from '@reduxjs/toolkit'
import * as api from '../services/firebase/api';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp, } from "firebase/firestore";
import { selectUserData } from './user';
import {httpsCallable} from "firebase/functions";
import { getSearchResults, transformCSVData, transformData, transformOldQueryData } from './utils';
import { DateTime } from 'luxon';
import { ROLE } from '../constants';


let storage = getStorage()

const initialState = {
  isAddingQuery: false,
  isQueryAdded: false,
  isUploadingImages: false,

  isEditingQuery: false,
  isEdited: false,

  allQueries: null,
  myQueries: null,
  queries: null,
  oldqueries: null,
  singQueries:null,

  queriesSearchString: null,
  allQueriesSearchString: null,
  myQueriesSearchString: null,
  oldQueriesSearchString: null,
  
  isFetchingAllQueries: false,
  isFetchingQueries: false,
  isFetchingMyQueries: false,
  isFetchingOldQueries: false,
  isFetchingQuery : false ,

  managementRemarksMap: {},
  isAddingManagementRemarks: false,

  approvedQueries: null,
  approvedQueriesMap: {},
  approvedQueriesSearchString: null,
  isApprovingQuery: null,
  isFetchingApprovedQueries: false,

  approvedContracts: null,
  approvedContractsMap: {},
  approvedContractsSearchString: null,
  isApprovingContract: false,
  isFetchingApprovedContracts: false,

  sentContracts: null,
  sentContractsMap: {},
  sentContractsSearchString: null,
  isSendingContract: false,
  isFetchingSentContracts: false,

  signedContracts: null,
  signedContractsMap: {},
  signedContractsSearchString: null,
  isSigningContract: false,
  isFetchingSignedContracts: false,

  inventory: null,
  inventoriedMap: {},
  inventorySearchString: null,
  isAddingToInventory: false,
  isFetchingInventory: false,
  isAddingBulkInventory: false,

  hotdeals: null,
  hotdealsmap: {},
  hotdealsSearchString: null,
  isMovingToHotDeals: false,
  isFetchingHotDeals: false,

  listings:null,
  listingsMap: {},
  isListing: false,
  isFetchingListings: false,

  inventoryContracts: null,
  inventoryContractsMap: {},
  inventoryContractsSearchString: null,
  isMovingToInventoryContracts: false,
  isFetchingInventoryContracts: false,
  
}

const querySlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    resetAllOnLogout(state, action) {
      state.allQueries = null;
      state.myQueries = null;
      state.approvedQueries = null;
      state.approvedContracts = null;
      state.sentContracts = null;
      state.signedContracts = null;
      state.inventory = null;
      state.hotdeals = null;
      state.inventoryContracts = null;
    },
    //Add Query 
    setUploading(state, action) {
      state.isUploadingImages = action.payload
    },
    setAddingQuery(state, action) {
      state.isAddingQuery = action.payload
    },
    setQueryAdded(state, action) {
      state.isQueryAdded = action.payload;
    },
    updateOnAddingQuery(state, action) {
      let { queryToAdd, directInventory } = action.payload;
      let allQuery = state.allQueries || [];
      let myQueries = state.myQueries || [];
      let queries = state.queries || [];
      let inventory = state.inventory || [];
      state.allQueries = [queryToAdd, ...allQuery];
      if(directInventory){
        state.inventory = [queryToAdd, ...inventory]
      }else{
        state.queries = [queryToAdd, ...queries];
        state.myQueries = [queryToAdd, ...myQueries];
      }
    },

    // Edit Query
    setIsEditingQuery(state, action) {
      state.isEditingQuery = action.payload
    },

    onEditQuery(state, action) {
      let { queryItem, view, index } = action.payload;
      let queries;
      if (view == 'queries') {
        queries = state.allQueries
        queries[index] = queryItem
        state.allQueries = queries

      } else if (view == 'approved_queries') {
        queries = state.approvedQueries
        queries[index] = queryItem
        state.approvedQueries = queries

      } else if (view == 'approved_contracts') {
        queries = state.approvedContracts
        queries[index] = queryItem
        state.approvedContracts = queries

      } else if (view == 'sent_contracts') {
        queries = state.sentContracts
        queries[index] = queryItem
        state.sentContracts = queries

      } else if (view == 'signed_contracts') {
        queries = state.signedContracts
        queries[index] = queryItem
        state.signedContracts = queries

      } else if (view == 'inventory') {
        queries = state.inventory
        queries[index] = queryItem
        state.inventory = queries

      } else if (view == 'hot_deals') {
        queries = state.hotdeals || []
        queries[index] = queryItem
        state.hotdeals = queries

      } else if (view == 'inventory_contracts') {
        queries = state.inventoryContracts
        queries[index] = queryItem
        state.inventoryContracts = queries
      }
      state.isEdited = true
    },
    resetIsEdited(state, action) {
      state.isEdited = action.payload
    },


    //Query Fetching
    setAllQueries(state, action) {
      state.allQueries = action.payload
    },
    setAllQueriesSearchString(state, action) {
      state.allQueriesSearchString = action.payload
    },
    setQueries(state, action) {
      state.queries = action.payload
    },
    setQueriesSearchString(state, action) {
      state.queriesSearchString = action.payload
    },
    setMyQueries(state, action) {
      state.myQueries = action.payload
    },
    setMyQueriesSearchString(state, action) {
      state.myQueriesSearchString = action.payload
    },
    setOldQueries(state, action) {
      state.oldqueries = action.payload
    },
    onQueryFetched(state, action){
      let singleQueries = state.singleQueries || [];
      state.singleQueries = [action.payload, ...singleQueries]
    },
    // setOldQueriesSearchString(state,action){
    //   state.oldQueriesSearchString = action.payload
    // },
    setIsFetchingMyQueries(state, action) {
      state.isFetchingMyQueries = action.payload
    },
    setIsFetchingAllQueries(state, action) {
      state.isFetchingAllQueries = action.payload
    },
    setIsFetchingQueries(state, action) {
      state.isFetchingQueries = action.payload
    },
    setFetchingQuery(state,action) {
      state.isFetchingQuery = action.payload
    },
    setIsFetchingOldQueries(state, action) {
      state.isFetchingOldQueries = action.payload
    },
    //Management Remarks
    setIsAddingRemarks(state, action) {
      state.isAddingManagementRemarks = action.payload
    },
    onManagementRemarksAdded(state, action) {
      let { id, queryItem, index, view } = action.payload
      let queries;
      if (view == 'allQueries') {
        queries = state.allQueries || []
        queries[index] = queryItem
        state.allQueries = queries
      } else if (view == 'approved_queries') {
        queries = state.approvedQueries || []
        queries[index] = queryItem
        state.approvedQueries = queries
      } else if (view == 'contracts_sent') {
        queries = state.sentContracts || []
        queries[index] = queryItem
        state.sentContracts = queries
      } else if (view == 'signed_contracts') {
        queries = state.signedContracts || []
        queries[index] = queryItem
        state.signedContracts = queries
      } else if (view == 'inventory') {
        queries = state.inventory || []
        queries[index] = queryItem
        state.inventory = queries
      } else if (view == 'hot_deals') {
        queries = state.hotdeals || []
        queries[index] = queryItem
        state.hotdeals = queries
      } else if (view == 'inventory_contracts') {
        queries = state.inventoryContracts || []
        queries[index] = queryItem
        state.inventoryContracts = queries
      }

      state.remarksAdded = true
      state.managementRemarksMap[id] = true
    },
    resetIsAddedRemarks(state, action) {
      state.remarksAdded = false
    },

    //Approved Query
    setApprovingQuery(state, action) {
      state.isApprovingQuery = action.payload
    },
    setApprovedQueriesSearchString(state, action) {
      state.approvedQueriesSearchString = action.payload
    },
    updateOnQueryApproved(state, action) {
      let { queryItem, index } = action.payload;
      let approvedQueries = state.approvedQueries || [];
      if (approvedQueries && approvedQueries.length) {
        state.approvedQueries = [queryItem, ...approvedQueries]
      }
      let allQueries = state.allQueries || []
      allQueries.splice(index, 1)
      state.allQueries = allQueries

      state.approvedQueriesMap[queryItem.id] = true
    },
    setIsFetchingApprovedQueries(state, action) {
      state.isFetchingApprovedQueries = action.payload
    },
    setApprovedQueries(state, action) {
      state.approvedQueries = action.payload
    },

    //APPROVE CONTRACTS
    setApprovedContracts(state, action) {
      state.approvedContracts = action.payload
    },
    setApprovedContractsSearchString(state, action) {
      state.approvedContractsSearchString = action.payload
    },
    setIsFetchingApprovedContracts(state, action) {
      state.isFetchingApprovedContracts = action.payload
    },
    setApprovingContract(state, action) {
      state.isApprovingContract = action.payload
    },
    onApprovingContract(state, action) {
      let { queryItem, index } = action.payload;
      let approvedQueries = state.approvedQueries || []
      approvedQueries.splice(index, 1)
      state.approvedQueries = approvedQueries

      let approvedContracts = state.approvedContracts || [];
      if (approvedContracts && approvedContracts.length) {
        state.approvedContracts = [queryItem, ...approvedContracts]
      }
      state.approvedContractsMap[queryItem.id] = true
    },

    //SENT CONTRACTS
    setSentContracts(state, action) {
      state.sentContracts = action.payload
    },
    setSentContractsSearchString(state, action) {
      state.sentContractsSearchString = action.payload
    },
    setIsFetchingSentContracts(state, action) {
      state.isFetchingSentContracts = action.payload
    },
    setIsSendingContract(state, action) {
      state.isSendingContract = action.payload
    },
    onSendingContract(state, action) {
      let { queryItem, index } = action.payload;
      let approvedContracts = state.approvedContracts || []
      approvedContracts.splice(index, 1)
      state.approvedContracts = approvedContracts

      let sentContracts = state.sentContracts || [];
      if (sentContracts && sentContracts.length) {
        state.sentContracts = [queryItem, ...sentContracts]
      }
      state.sentContractsMap[queryItem.id] = true
    },

    //SIGNED CONTRACTS
    setIsSigningContract(state, action) {
      state.isSigningContract = action.payload
    },
    setSignedContractsSearchString(state, action) {
      state.signedContractsSearchString = action.payload
    },
    onSigningContract(state, action) {
      let { queryItem, index, view } = action.payload;
      if(view == 'contracts_sent'){
        let sentContracts = state.sentContracts || []
        sentContracts.splice(index, 1)
        state.sentContracts = sentContracts
      }else{
        let inventoryContracts = state.inventoryContracts || []
        inventoryContracts.splice(index, 1)
        state.inventoryContracts = inventoryContracts
      }

      let signedContracts = state.signedContracts || []
      if (signedContracts && signedContracts.length) {
        state.signedContracts = [queryItem, ...signedContracts]
      }
      state.signedContractsMap[queryItem.id] = true
    },
    setIsFetchingSignedContracts(state, action) {
      state.isFetchingSignedContracts = action.payload
    },
    setSignedContracts(state, action) {
      state.signedContracts = action.payload
    },


    //Inventory 
    setInventory(state, action) {
      state.inventory = action.payload
    },
    setInventorySearchString(state, action) {
      state.inventorySearchString = action.payload
    },
    setIsFetchingInventory(state, action) {
      state.isFetchingInventory = action.payload
    },
    setAddingToInventory(state, action) {
      state.isAddingToInventory = action.payload
    },
    onAddingToInventory(state, action) {
      let { queryItem, index, view } = action.payload;
      let inventory = state.inventory || [];
      if (inventory && inventory.length) {
        state.inventory = [queryItem, ...inventory]
      }
      if (view == 'queries') {
        let queries = state.queries || []
        queries.splice(index, 1)
        state.queries = queries
      } else if (view == 'approved') {
        let approvedQueries = state.approvedQueries || []
        approvedQueries.splice(index, 1)
        state.approvedQueries = approvedQueries
      } else if (view == 'contracts') {
        let contracts = state.sentContracts || []
        contracts.splice(index, 1)
        state.sentContracts = contracts
      } else if (view == 'signed_contracts') {
        let signedContracts = state.signedContracts || []
        signedContracts.splice(index, 1)
        state.signedContracts = signedContracts
      }
      state.inventoriedMap[queryItem.id] = true
    },
    onBulkInventoryAdded(state, action) {
      let items = action.payload
      let inventory = state.inventory || [];
      items.forEach(item => {
        let queries = state.queries || []
        let index = queries.findIndex(q => q.id == item);
        let fullItem = queries[index]
        queries.splice(index, 1)
        state.queries = queries

        if (inventory && inventory.length) {
          state.inventory = [fullItem, ...inventory]
        }

        state.inventoriedMap[item] = true
      })

    },
    isBulkInventorying(state, action) {
      state.isAddingBulkInventory = action.payload
    },

    //HotDeals 
    setHotDeals(state, action) {
      state.hotdeals = action.payload
    },
    setHotDealsSearchString(state, action) {
      state.hotdealsSearchString = action.payload
    },
    setIsFetchingHotDeals(state, action) {
      state.isFetchingHotDeals = action.payload
    },
    setMovingToHotDeals(state, action) {
      state.isMovingToHotDeals = action.payload
    },
    onMovingToHotDeals(state, action) {
      let { inventoryItem, index, } = action.payload;
      let hotDeals = state.hotdeals || [];

      if (hotDeals && hotDeals.length) {
        state.hotdeals = [inventoryItem, ...hotDeals]
      }

      let inventories = state.inventory || []
      inventories.splice(index, 1)
      state.inventory = inventories
      state.hotdealsmap[inventoryItem.id] = true
    },

    //Listings

    setListings(state,action){
      state.listings = action.payload
    },
    setIsFetchingListings(state, action) {
      state.isFetchingListings = action.payload
    },
    setMovingToListing(state, action) {
    state.isListing = action.payload
    },
    onMovedToListing(state, action){
      let { listingItem, index, } = action.payload;
      let listings = state.listings || [];
      if (listings && listings.length) {
        state.listings = [listingItem, ...listings]
      }
      let inventory = state.inventory || []
      inventory.splice(index, 1)
      state.inventory = inventory
      state.listingsMap[listingItem.id] = true
    },


    //Inventory Contracts

    setInventoryContracts(state, action) {
      state.inventoryContracts = action.payload
    },
    setInventoryContractsSearchString(state, action) {
      state.inventoryContractsSearchString = action.payload
    },
    setIsFetchingInventoryContracts(state, action) {
      state.isFetchingHotDeals = action.payload
    },
    setMovingToInventoryContracts(state, action) {
      state.isMovingToHotDeals = action.payload
    },
    onMovingToInventoryContracts(state, action) {
      let { inventoryItem, index, } = action.payload;
      let inventoryContracts = state.inventoryContracts || [];
      if (inventoryContracts && inventoryContracts.length) {
        state.inventoryContracts = [inventoryItem, ...inventoryContracts]
      }
      let hotDeals = state.hotdeals || []
      hotDeals.splice(index, 1)
      state.hotdeals = hotDeals
      state.inventoryContractsMap[inventoryItem.id] = true
    },

  }
})
export default querySlice.reducer;


export const {
  resetAllOnLogout,

  setUploading,
  setAddingQuery,
  updateOnAddingQuery,
  setQueryAdded,

  setIsEditingQuery,
  onEditQuery,
  resetIsEdited,

  setAllQueries,
  setMyQueries,
  setQueries,
  setQueriesSearchString,
  setIsFetchingQueries,
  setIsFetchingAllQueries,
  setIsFetchingMyQueries,
  setAllQueriesSearchString,
  setMyQueriesSearchString,
  setFetchingQuery,
  onQueryByIdFetched,
  onQueryFetched,


  setIsAddingRemarks,
  onManagementRemarksAdded,
  resetIsAddedRemarks,


  setApprovedQueries,
  updateOnQueryApproved,
  setApprovingQuery,
  setIsFetchingApprovedQueries,
  setApprovedQueriesSearchString,

  setApprovedContracts,
  setApprovingContract,
  setIsFetchingApprovedContracts,
  onApprovingContract,
  setApprovedContractsSearchString,

  setSentContracts,
  setIsFetchingSentContracts,
  setIsSendingContract,
  onSendingContract,
  setSentContractsSearchString,

  setIsSigningContract,
  onSigningContract,
  setSignedContracts,
  setIsFetchingSignedContracts,
  setSignedContractsSearchString,

  //inventory actions
  setInventory,
  setAddingToInventory,
  setIsFetchingInventory,
  onAddingToInventory,
  setInventorySearchString,
  onBulkInventoryAdded,
  isBulkInventorying,

  setHotDeals,
  setMovingToHotDeals,
  setIsFetchingHotDeals,
  onMovingToHotDeals,
  setHotDealsSearchString,

  setListings,
  setIsFetchingListings,
  setMovingToListing,
  onMovedToListing,

  setInventoryContracts,
  setMovingToInventoryContracts,
  setIsFetchingInventoryContracts,
  onMovingToInventoryContracts,
  setInventoryContractsSearchString,

  //Old Queries
  setOldQueries,
  setIsFetchingOldQueries,

  //CSV UPLOADING QUERIES
  setUploadingCSVStatus,
  resetUploadingCSV,
 
} = querySlice.actions;

// QUERY 
//************************************ */
//************************************ */
export const addQuery = (data, inventory ) => async (dispatch, getState) => {
  console.log(data)
  let user = selectUserData(getState());
  let newQueryId = await api.getDocIdBeforeAdd({ collectionName: 'queries' });
  console.log(newQueryId.id);
  // const promises = [];
  // dispatch(setUploading(true));

  // properties.forEach((property, i) => {
  //   let images = property.imageFiles;
  //   property.images = [] 
  //   images?.length && images.forEach((file) => {
  //     let fileName = file.name.split(' ').join('') + Date.now().toString();
  //     let fileRef = `propertyImages/${newQueryId.id}/${fileName}`;
  //     const storageRef = ref(storage, fileRef);
  //     promises.push(uploadBytes(storageRef, file).then(
  //       async (snapshot) => {
  //         try {
  //           const url = await getDownloadURL(snapshot.ref);
  //           console.log(url);
  //           property.images.push(url)
  //           return { url }
  //         } catch (e) {
  //           return console.log(e);
  //         }
  //       }
  //     ))
  //   })
  //   delete property.imageFiles
  //   delete property.previewImages
  // })

  // Promise.all(promises)
  //   .then((newPromises) => {
  dispatch(setAddingQuery(true));
  // console.log('All files uploaded', newPromises, promises)

  dispatch(setUploading(false))
  let inventoryInfo={
    inventoried_by: user,
    inventoried_date: serverTimestamp(),
    inventoried_from: 'Direct',
    inventory_manager: {
      id: user.id,
      name: user.firstname + ' ' + user.lastname
    },
  }
  let queryToAdd = {
    id: newQueryId.id,
    created: serverTimestamp(),
    updated: null,
    added_by: user,
    office: user.office ? user.office : '',
    new_query: inventory ? false : true,
    approved: false,
    contract_approved: false,
    contract_sent: false,
    contract_signed: false,
    inventory: inventory ? true : false,
    hot_deal: false,
    listing:false,
    inventory_contract_submitted: false,
    ...data,
    ...inventory && inventoryInfo,
  }
  console.log('DATA TO ADD. CHECK PROPERTIES', queryToAdd)

  api.setDocById({ collection: 'queries', data: queryToAdd }).then(
    () => {
      dispatch(setAddingQuery(false))
      dispatch(setQueryAdded(true))
      let date = new Date();
      if(inventory){
        queryToAdd.inventoried_date = { local: true, time: date };
      }else{
        queryToAdd.created = { local: true, time: date };
      }
      let { data } = transformData([queryToAdd]);
      dispatch(updateOnAddingQuery({ queryToAdd: data[0], directInventory: inventory ? true : false }))
      api.updateCountById({ collection: 'stats', id: 'dashboard', field: 'query_count' })
    })
  // })
  // .catch(err => console.log(err.code));

  // let user=firebase.auth().currentUser;
  // let ref = firebase.storage().ref('data/'+user.uid+'/posts/');
  // let files=Array.from(review_photo.files);
  // let promises = files.map(function(file) {
  //     return ref.child(file.name).put(file).then(function(snapshot){
  //         return snapshot.ref.getDownloadURL()
  //     })
  // });
  // return Promise.all(promises);
}

export const editQuery = (data, view) => async (dispatch, getState) => {
  console.log(data)
  let queries; let index; let queryItem;
  if (view == 'queries') {
    queries = getState().queries.allQueries
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'approved_queries') {
    queries = getState().queries.approvedQueries
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'approved_contracts') {
    queries = getState().queries.approvedContracts
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'sent_contracts') {
    queries = getState().queries.sentContracts
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'signed_contracts') {
    queries = getState().queries.signedContracts
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'inventory') {
    queries = getState().queries.inventory
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'hot_deals') {
    queries = getState().queries.hotdeals || []
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  } else if (view == 'inventory_contracts') {
    queries = getState().queries.inventoryContracts
    index = queries.findIndex(q => q.id == data.id)
    queryItem = queries[index]
  }

  let { properties } = data;
  const promises = [];
  dispatch(setUploading(true));
  properties.forEach((property, i) => {
    let images = property.imageFiles;
    if (images && images.length) {
      if (property.images.length) {

      } else {
        property.images = []
      }
      images.forEach((file) => {
        let fileName = file.name.split(' ').join('') + Date.now().toString();
        let fileRef = `propertyImages/${data.id}/${fileName}`;
        const storageRef = ref(storage, fileRef);
        promises.push(uploadBytes(storageRef, file).then(
          async (snapshot) => {
            try {
              const url = await getDownloadURL(snapshot.ref);
              console.log(url);
              property.images.push(url)
              return { url }
            } catch (e) {
              return console.log(e);
            }
          }
        ))
      })
      delete property.imageFiles
      delete property.previewImages
    }
  })

  Promise.all(promises)
    .then((newPromises) => {
      dispatch(setIsEditingQuery(true));
      console.log('All files uploaded', newPromises, promises)
      dispatch(setUploading(false))
      let dataToAdd = {
        updated: serverTimestamp(),
        ...data,
      }
      api.setDocById({ collection: 'queries', data: dataToAdd }).then(
        () => {
          dispatch(setIsEditingQuery(false))
          dispatch(resetIsEdited(true))
          let date = new Date();
          queryItem = {
            ...queryItem,
            ...data,
            updated: { local: true, time: date }
          }
          dispatch(onEditQuery({ queryItem: queryItem, view: view, index: index }))
          //Add edit logging here
        })
    }).catch((err) => {
      console.log(err)
    })
}

export const addImagesToProperty = (id, view, activeProperty, imageFiles) => async (dispatch, getState) => {
  console.log(id, view, activeProperty, imageFiles)

  let propertyIndex = activeProperty || 0
  let queryItem; let index;
  if (view == 'signed_contracts') {
    let signedContracts = getState().queries.signedContracts;
    index = signedContracts.findIndex(sc => sc.id == id)
    queryItem = { ...signedContracts[index] }
  }
  if (view == 'sent_contracts') {
    let sentContracts = getState().queries.sentContracts;
    index = sentContracts.findIndex(sc => sc.id == id)
    queryItem = { ...sentContracts[index] }
  }

  dispatch(setUploading(true));
  let property = queryItem.properties[propertyIndex];
  const promises = [];
  if (imageFiles && imageFiles.length) {
    property.images = []

    imageFiles.forEach((file) => {
      let fileName = file.name.split(' ').join('') + Date.now().toString();
      let fileRef = `propertyImages/${id}/${fileName}`;
      const storageRef = ref(storage, fileRef);
      promises.push(uploadBytes(storageRef, file).then(
        async (snapshot) => {
          try {
            const url = await getDownloadURL(snapshot.ref);
            console.log(url);
            property.images.push(url)
            return { url }
          } catch (e) {
            return console.log(e);
          }
        }
      ))
    })
  }

  Promise.all(promises)
    .then((newPromises) => {
      dispatch(setIsEditingQuery(true));
      console.log('All files uploaded', newPromises, promises)
      dispatch(setUploading(false))

      let dataToAdd = {
        updated: serverTimestamp(),
        properties: queryItem.properties,
        id: id
      }
      api.setDocById({ collection: 'queries', data: dataToAdd }).then(
        () => {
          dispatch(setIsEditingQuery(false))
          dispatch(resetIsEdited(true))
          let date = new Date();
          queryItem = {
            ...queryItem,
            updated: { local: true, time: date }
          }
          dispatch(onEditQuery({ queryItem: queryItem, view: view, index: index }))
          //Add edit logging here
        })
    }).catch((err) => {
      console.log(err)
    })
}


export const getQueriesByUserId = (userId, queryString, role) => async (dispatch, getState) => {
  dispatch(setIsFetchingMyQueries(true))
  let cachedQueries = getState().queries.myQueries || null;
  if (role == ROLE.VirtualRealtor) {
    console.log('reached')

    let hour = DateTime.local().hour
    if (hour > 17 || hour < 6) {
      // if date is higher than 25, then 
      let month = DateTime.local().month
      let day = DateTime.local().day
      let year = DateTime.local().year

      if (day < 25) {
        if (month == 1) {
          month = 12
          year --
        } else {
          month--
        }
      }
      let end = DateTime.local().set({ day: 25, month: month, year })
      console.log(end.toJSDate())
      if ((!cachedQueries || cachedQueries.length == 0) || queryString != undefined) {
        // wheres: [["created" , "<", end.toJSDate() ], ["created" , ">", start.toJSDate() ]]
        api.getAllDocs({ collectionName: 'queries', order: ("created"), wheres: [["added_by.id", "==", userId]], endsAtDate: end.toJSDate() }).then(
          response => {
            let { data } = transformData(response)
            console.log(data)
            if (queryString) {
              dispatch(setMyQueriesSearchString(queryString))
              data = getSearchResults(queryString, data)
            } else {
              dispatch(setMyQueriesSearchString(null))
            }

            dispatch(setMyQueries(data))
            dispatch(setIsFetchingMyQueries(false))
          }
        )
      }
      else {
        dispatch(setMyQueries(cachedQueries))
        dispatch(setIsFetchingMyQueries(false))
      }
    }
  } else {
    if (!cachedQueries || cachedQueries.length == 0 || queryString) {
      api.getAllDocs({ collectionName: 'queries', wheres: [["added_by.id", "==", userId]], order: ("created") }).then(
        response => {
          console.log(response)
          let { data } = transformData(response)
          if (queryString) {
            dispatch(setMyQueriesSearchString(queryString))
            data = getSearchResults(queryString, data)
          } else {
            dispatch(setMyQueriesSearchString(null))
          }
          dispatch(setMyQueries(data))
          dispatch(setIsFetchingMyQueries(false))
        }
      )
    } else {
      dispatch(setMyQueries(cachedQueries))
      dispatch(setIsFetchingMyQueries(false))
    }
  }
}
export const getQueries = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingQueries(true))
  let cachedQueries = getState().queries.queries || null;
  if ((!cachedQueries || cachedQueries.length == 0) || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("created"), wheres: [["new_query", "==", true],], limitAt: 600 }).then(
      response => {
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setQueriesSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setQueriesSearchString(null))
        }

        dispatch(setQueries(data))
        dispatch(setIsFetchingQueries(false))
      }
    )
  } else {
    dispatch(setQueries(cachedQueries))
    dispatch(setIsFetchingQueries(false))
  }
}
export const getAllQueries = (queryString, dateRanged) => async (dispatch, getState) => {
  dispatch(setIsFetchingAllQueries(true))
  let cachedQueries = getState().queries.allQueries || null;
  if ((!cachedQueries || cachedQueries.length == 0) || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("created"), limitAt: 600 }).then(
      response => {
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setAllQueriesSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setAllQueriesSearchString(null))
        }
        dispatch(setAllQueries(data))
        dispatch(setIsFetchingAllQueries(false))
      }
    )
  }
  else {
    dispatch(setAllQueries(cachedQueries))
    dispatch(setIsFetchingAllQueries(false))
  }
}
//DATE RANGE GET QUERIES 
export const getQueriesByDateRange = ({ start, end, view, userId, role }) => async (dispatch, getState) => {
  let startDate = DateTime.fromJSDate(start, { zone: 'America/New_York' }).set({ hours: 4, minute: 1 })
  let endDate = DateTime.fromJSDate(end, { zone: 'America/New_York' }).set({ hours: 23, minute: 59 })

  if (view == 'allQueries') {
    dispatch(setIsFetchingAllQueries(true))
    api.getAllDocs({
      collectionName: 'queries',
      wheres: [["created", ">=", startDate.toJSDate()], ["created", "<=", endDate.toJSDate()]],
      order: ("created"),
    })
      .then(
        response => {
          let { data } = transformData(response)
          console.log(data)
          dispatch(setAllQueries(data))
          dispatch(setIsFetchingAllQueries(false))
        }
      )
  } else if (view == 'myQueries') {
    dispatch(setIsFetchingMyQueries(true))
    api.getAllDocs({
      collectionName: 'queries',
      order: ("created"),
      wheres: [["created", ">=", startDate.toJSDate()], ["created", "<=", endDate.toJSDate()], ["added_by.id", "==", userId],]
    }).then(
        response => {
          let { data } = transformData(response)
          dispatch(setMyQueries(data))
          dispatch(setIsFetchingMyQueries(false))
        }
      )
  } else if (view == 'queries') {
    dispatch(setIsFetchingQueries(true))
    api.getAllDocs({
      collectionName: 'queries',
      wheres: [["created", ">=", startDate.toJSDate()], ["created", "<=", endDate.toJSDate()], ["new_query", "==", true]],
      order: ("created"),
    })
      .then(
        response => {
          let { data } = transformData(response)
          dispatch(setQueries(data))
          dispatch(setIsFetchingQueries(false))
        }
      )
  } else if (view == 'inventory') {
    dispatch(setIsFetchingInventory(true))
    let wheres = [["created", ">=", startDate.toJSDate()], ["created", "<=", endDate.toJSDate()], ["inventory", "==", true]]
    if (role == ROLE.InventoryManager) {
      wheres.push(["inventory_manager.id", "==", userId])
    }
    api.getAllDocs({
      collectionName: 'queries',
      wheres: wheres,
      order: ("created"),
    })
      .then(
        response => {
          let { data } = transformData(response)
          dispatch(setInventory(data))
          dispatch(setIsFetchingInventory(false))
        }
      )
  }
  //TO BE DONE
  // else if ( view == 'hot_deal') {
  //   dispatch(setIsFetchingHotDeals(true))
  //   let wheres = [["created", ">=", startDate.toJSDate()], ["created", "<=", endDate.toJSDate()], ["hot_deal", "==", true]]
  //   if (role == ROLE.InventoryManager) {
  //     wheres.push(["inventory_manager.id", "==", userId])
  //   }
  //   api.getAllDocs({
  //     collectionName: 'queries',
  //     wheres: wheres,
  //     order: ("created"),
  //   })
  //     .then(
  //       response => {
  //         let { data } = transformData(response)
  //         dispatch(setHotDeals(data))
  //         dispatch(setIsFetchingHotDeals(false))
  //       }
  //     )
  // }
}

//OLDQUERIES STRING
export const fetchOldQueries = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingOldQueries(true))
  let cachedQueries = getState().queries.oldqueries || null;
  if ((!cachedQueries || cachedQueries.length == 0) || queryString != undefined) {
    api.getAllDocs({ collectionName: 'old_queries' }).then(
      response => {
        console.log(response)
        let { data } = transformOldQueryData(response)
        if (queryString) {
          dispatch(setQueriesSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setQueriesSearchString(null))
        }
        dispatch(setOldQueries(data))
        dispatch(setIsFetchingOldQueries(false))
      }
    ).catch(e => console.log(e))
  } else {
    dispatch(setOldQueries(cachedQueries))
    dispatch(setIsFetchingOldQueries(false))
  }
}

export const selectOldQueries = (state) => state.queries.oldqueries
export const selectIsFetchingOldQueries = (state) => state.queries.isFetchingOldQueries
export const selectOldQueryString = (state) => state.queries.oldQueriesSearchString

//TEAM LEAD QUERIES FROM ALL QUERIES

export const getQueriesForTeamLeads = (queryString) => async (dispatch, getState) => {
  let user = selectUserData(getState());

  dispatch(setIsFetchingAllQueries(true))
  let cachedQueries = getState().queries.allQueries || null;

  let hour = DateTime.local().hour

  if (hour > 17 || hour < 6) {
    let end = DateTime.local().minus({ hours: 10, })
    console.log(end.toJSDate())

    if ((!cachedQueries || cachedQueries.length == 0) || queryString != undefined) {
      // wheres: [["created" , "<", end.toJSDate() ], ["created" , ">", start.toJSDate() ]]
      api.getAllDocs({ collectionName: 'queries', order: ("created"), endsAtDate: end.toJSDate() }).then(
        response => {
          let { data } = transformData(response)
          if (queryString) {
            dispatch(setAllQueriesSearchString(queryString))
            data = getSearchResults(queryString, data)
          } else {
            dispatch(setAllQueriesSearchString(null))
          }
          if (user.role == 'team_leads') {
            if (user.office == 'royal') {
              data = data.filter(d => d.office == user.office)
            } else if (user.office == 'nadra') {
              data = data.filter(d => !d.office || d.office == 'nadra')
            }

          }
          dispatch(setAllQueries(data))
          dispatch(setIsFetchingAllQueries(false))
        }
      )
    }
    else {
      dispatch(setAllQueries(cachedQueries))
      dispatch(setIsFetchingAllQueries(false))
    }
  } else {
    dispatch(setIsFetchingAllQueries(false))
  }
}


export const getQueryById  = (id) => async(dispatch, getState) => {
    dispatch(setFetchingQuery(true))
    let singleQueries = getState().queries.singleQueries || []
    let existing = singleQueries.find( item => item.id == id )
    console.log(existing)
    
    if(!existing){
      await api.getDocById({ table: 'queries', id: id })
      .then((res) => {
        console.log(res)
        let { data } = transformData([res])
        dispatch(setFetchingQuery(false))
        dispatch(onQueryFetched(data[0]))
      })
    }
    dispatch(setFetchingQuery(false))
}

export const selectIsUploadingImages = (state) => state.queries.isUploadingImages;
export const selectIsAddingQuery = (state) => state.queries.isAddingQuery;
export const selectIsQueryAdded = (state) => state.queries.isQueryAdded;

export const selectIsEditingQuery = (state) => state.queries.isEditingQuery
export const selectIsQueryEdited = (state) => state.queries.isEdited

export const selectAllQueries = (state) => state.queries.allQueries;
export const selectAllQueriesSearchString = (state) => state.queries.allQueriesSearchString;
export const selectQueryById = (state,id) => state.queries.singleQueries?.find(aq => aq.id == id) || null

export const selectQueries = (state) => state.queries.queries;
export const selectQueriesSearchString = (state) => state.queries.queriesSearchString;

export const selectMyQueriesSearchString = (state) => state.queries.myQueriesSearchString;

export const selectMyQueries = (state) => state.queries.myQueries;
export const selectIsFetchingQueries = (state) => state.queries.isFetchingQueries;
export const selectIsFetchingAllQueries = (state) => state.queries.isFetchingAllQueries;
export const selectIsFetchingMyQueries = (state) => state.queries.isFetchingMyQueries;
export const selectIsFetchingQuery = (state) => state.queries.isFetchingQuery;

//  APPROVE QUERY 
//************************************ */
//************************************ */

export const approveQuery = ({ id, approved_remarks }) => async (dispatch, getState) => {
  dispatch(setApprovingQuery(true))
  let user = selectUserData(getState());
  let allQueries = getState().queries.allQueries
  let index = allQueries.findIndex(q => q.id == id)
  let queryItem = allQueries[index];
  let dataToSet = {
    id,
    new_query: false,
    approved: true,
    approved_remarks: approved_remarks,
    approved_by: user,
    approve_date: serverTimestamp(),
  }
  let date = new Date();
  queryItem = {
    ...queryItem,
    ...dataToSet,
    approve_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setApprovingQuery(false))
      dispatch(updateOnQueryApproved({ index, queryItem }))

      api.updateCountById({ collection: 'stats', id: 'dashboard', field: 'approved_deals' })
    }
  ).catch((err) => {
    dispatch(setApprovingQuery(false))
  })
}

export const getAllApprovedQueries = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingApprovedQueries(true))
  let cachedApprovedQueries = getState().queries.approvedQueries || null;
  if (!cachedApprovedQueries || cachedApprovedQueries.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("approve_date"), wheres: [["approved", "==", true]] }).then(
      response => {
        console.log(response)
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setApprovedQueriesSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setApprovedQueriesSearchString(null))
        }
        dispatch(setApprovedQueries(data))
        dispatch(setIsFetchingApprovedQueries(false))
      }
    )
  } else {
    dispatch(setApprovedQueries(cachedApprovedQueries))
    dispatch(setIsFetchingApprovedQueries(false))
  }
}


export const selectApprovedQueries = (state) => state.queries.approvedQueries;
export const selectApprovedQueriesSearchString = (state) => state.queries.approvedQueriesSearchString;

export const selectIsApprovingQuery = (state) => state.queries.isApprovingQuery;
export const selectIsQueryApproved = (state, id) => state.queries.approvedQueriesMap[id] && state.queries.approvedQueriesMap[id] || null;
export const selectIsFetchingApprovedQueries = (state) => state.queries.isFetchingApprovedQueries;


///MANAGEMENT REMARKS 

export const addManagementRemarks = ({ id, remarks, view }) => async (dispatch, getState) => {
  dispatch(setIsAddingRemarks(true))
  let user = selectUserData(getState());
  let queries;
  let index;
  let queryItem;
  let dataToSet;

  if (view == 'allQueries') {

    queries = getState().queries.allQueries
    index = queries.findIndex(q => q.id == id)

    dataToSet = {
      id,
      management_remarks: remarks,
      management_remarks_by: user
    }

  } else if (view == 'approved_queries') {
    queries = getState().queries.approvedQueries
    index = queries.findIndex(q => q.id == id)

    dataToSet = {
      id,
      contracter_remarks: remarks,
      contract_remarks_by: user
    }
  } else if (view == 'approved_contracts') {


  } else if (view == 'contracts_sent') {
    queries = getState().queries.sentContracts
    index = queries.findIndex(q => q.id == id)

    dataToSet = {
      id,
      property_manager_remarks: remarks,
      property_manager_remarks_by: user,
    }

  } else if (view == 'signed_contracts') {
    queries = getState().queries.signedContracts
    index = queries.findIndex(q => q.id == id)

    dataToSet = {
      id,
      property_manager_remarks: remarks,
      property_manager_remarks_by: user
    }

  } else if (view == 'inventory') {
    queries = getState().queries.inventory
    index = queries.findIndex(q => q.id == id)

    dataToSet = {
      id,
      inventory_manager_remarks: remarks,
      inventory_manager_remarks_by: user
    }
  } else if (view == 'hot_deals') {
    queries = getState().queries.hotdeals
    index = queries.findIndex(q => q.id == id)
    if(user.role == ROLE.InventoryManager){
      dataToSet = {
        id,
        inventory_manager_remarks: remarks,
      inventory_manager_remarks_by: user
      }
    }else if( user.role == ROLE.DispositionManager ){
      dataToSet = {
        id,
        dispo_manager_remarks: remarks,
        dispo_manager_remarks_by: user
      }
    }else if(user.role == ROLE.PropertyManager){
      dataToSet = {
        id,
        property_manager_remarks_inventory : remarks,
        property_manager_remarks_inventory_by : user
      }
    }
  } else if (view == 'inventory_contracts') {
    queries = getState().queries.inventoryContracts
    index = queries.findIndex(q => q.id == id)
    if(user.role == ROLE.DispositionManager){
      dataToSet = {
        id,
        dispo_manager_remarks: remarks,
        dispo_manager_remarks_by: user
      }
    }else if(user.role == ROLE.PropertyManager || user.role == ROLE.Admin){
      dataToSet = {
        id,
        property_manager_remarks_inventory: remarks,
        property_manager_remarks_inventory_by: user
      }
    }
    
  }
  queryItem = queries[index];
  queryItem = {
    ...queryItem,
    ...dataToSet,
  }

  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setIsAddingRemarks(false))
      dispatch(onManagementRemarksAdded({ id, index, queryItem, view: view }))
    })
}

export const selectAddedManagementRemarks = (state) => state.queries.remarksAdded
export const selectIsAddingManagementRemarks = (state) => state.queries.isAddingManagementRemarks;



/*  APPROVED CONTRACTS 
*/
export const approveContract = ({ id, approveContractRemarks }) => async (dispatch, getState) => {
  dispatch(setApprovingContract(true))
  let user = selectUserData(getState());

  let approvedQueries = getState().queries.approvedQueries
  let index = approvedQueries.findIndex(q => q.id == id);
  let queryItem = approvedQueries[index];

  let dataToSet = {
    id,
    approved: false,
    contract_approved: true,
    contract_approved_by: user,
    contract_approved_date: serverTimestamp(),
    contract_approved_remarks: approveContractRemarks
  }

  let date = new Date();
  queryItem = {
    ...queryItem,
    ...dataToSet,
    contract_approved_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setApprovingContract(false))
      dispatch(onApprovingContract({ index, queryItem }))
    }
  ).catch((err) => {
    dispatch(setApprovingContract(false))
  })

}
export const getApprovedContracts = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingApprovedContracts(true))
  let cachedApprovedContracts = getState().queries.approvedContracts || null;
  if (!cachedApprovedContracts || cachedApprovedContracts.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("contract_approved_date"), wheres: [["contract_approved", "==", true]] }).then(
      response => {
        dispatch(setIsFetchingApprovedContracts(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setApprovedContractsSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setApprovedContractsSearchString(null))

        }
        dispatch(setApprovedContracts(data))
      }
    )
  } else {
    dispatch(setApprovedContracts(cachedApprovedContracts))
    dispatch(setIsFetchingApprovedContracts(false))
  }
}


export const selectApprovedContracts = (state) => state.queries.approvedContracts;
export const selectApprovedContractsSearchString = (state) => state.queries.approvedContractsSearchString;

export const selectIsApprovingContract = (state) => state.queries.isApprovingContract;
export const selectIsApprovedContract = (state, id) => state.queries.approvedContractMap && state.queires.approvedContractMap[id] || null;
export const selectIsFetchingApprovedContracts = (state) => state.queries.isFetchingApprovedContracts;


/**********************************
 * 
 * SENT CONTRACTS
 * 
 */
export const sendContract = ({ id, sendContractRemarks, }) => async (dispatch, getState) => {
  dispatch(setIsSendingContract(true))
  let user = selectUserData(getState());
  let approvedContracts = getState().queries.approvedContracts
  let index = approvedContracts.findIndex(q => q.id == id);
  let queryItem = approvedContracts[index];

  let dataToSet = {
    id,
    contract_approved: false,
    contract_sent: true,
    contract_sent_by: user,
    contract_sent_date: serverTimestamp(),
    contract_sent_remarks: sendContractRemarks
  }
  let date = new Date();
  queryItem = {
    ...queryItem,
    ...dataToSet,
    contract_sent_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setIsSendingContract(false))
      dispatch(onSendingContract({ index, queryItem }))
      api.updateCountById({ collection: 'stats', id: 'dashboard', field: 'contracts_sent' })
    }
  ).catch((err) => {
    dispatch(setIsSendingContract(false))
  })
}


export const getSentContracts = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingSentContracts(true))
  let cachedSentContracts = getState().queries.sentContracts || null;
  if (!cachedSentContracts || cachedSentContracts.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("contract_sent_date"), wheres: [["contract_sent", "==", true]] }).then(
      response => {
        dispatch(setIsFetchingSentContracts(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setSentContractsSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setSentContractsSearchString(null))
        }
        dispatch(setSentContracts(data))
      }
    )
  } else {
    dispatch(setSentContracts(cachedSentContracts))
    dispatch(setIsFetchingSentContracts(false))
  }
}


export const selectIsFetchingContracts = (state) => state.queries.isFetchingContracts;
export const selectSentContractsSearchString = (state) => state.queries.sentContractsSearchString;

export const selectSentContracts = (state) => state.queries.sentContracts;
export const selectIsContractSent = (state, id) => state.queries.sentContractsMap && state.queries.sentContractsMap[id] || null
export const selectIsSendingContract = (state) => state.queries.isSendingContract


/**********************************
 * 
 * SIGNED CONTRACTS
 * 
 */


export const signContract = ({ id, signContractRemarks, view }) => async (dispatch, getState) => {
  dispatch(setIsSigningContract(true))
  let user = selectUserData(getState());
  let index;
  let queryItem;
  
  if(view == 'sent_contracts'){
    let sentContracts = getState().queries.sentContracts
    index = sentContracts.findIndex(q => q.id == id);
    queryItem = sentContracts[index];
  }else{
    let inventoryContracts = getState().queries.inventoryContracts
    index = inventoryContracts.findIndex(q => q.id == id);
    queryItem = inventoryContracts[index];
  }

  let dataToSet = {
    id,
    contract_sent: false,
    inventory_contract_submitted:false,
    contract_signed: true,
    contract_signed_by: user,
    contract_signed_date: serverTimestamp(),
    contract_signed_remarks: signContractRemarks
  }
  let date = new Date();
  queryItem = {
    ...queryItem,
    ...dataToSet,
    contract_signed_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setIsSigningContract(false))
      dispatch(onSigningContract({ index, queryItem, view }))
      api.updateCountById({ collection: 'stats', id: 'dashboard', field: 'signed' })
    }
  ).catch((err) => {
    dispatch(setIsSigningContract(false))
  })
}

export const getSignedContracts = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingSignedContracts(true))
  let cachedSignedContracts = getState().queries.signedContracts || null;
  if (!cachedSignedContracts || cachedSignedContracts.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("contract_signed_date"), wheres: [["contract_signed", "==", true]] }).then(
      response => {
        dispatch(setIsFetchingSignedContracts(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setSignedContractsSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setSignedContractsSearchString(null))

        }
        dispatch(setSignedContracts(data))
      }
    )
  } else {
    dispatch(setSignedContracts(cachedSignedContracts))
    dispatch(setIsFetchingSignedContracts(false))
  }
}


export const selectIsFetchingSignedContracts = (state) => state.queries.isFetchingSignedContracts;
export const selectSignedContracts = (state) => state.queries.signedContracts;
export const selectSignedContractsSearchString = (state) => state.queries.signedContractsSearchString;

export const selectIsContractSigned = (state, id) => state.queries.signedContractsMap && state.queries.signedContractsMap[id] || null
export const selectIsSigningContract = (state) => state.queries.isSigningContract




//INVENTORY//
//************************************ */
//************************************ */
export const addToInventory = ({ id, data, view }) => async (dispatch, getState) => {
  dispatch(setAddingToInventory(true))
  let user = selectUserData(getState());
  let queryItem; let index;
  let inventoriedFrom;
  if (view == 'queries') {
    inventoriedFrom = 'Query'
    let allQueries = getState().queries.queries;
    index = allQueries.findIndex(q => q.id == id);
    queryItem = allQueries[index];
  } else if (view == 'approved_queries') {
    inventoriedFrom = 'Approved Query'
    let allApproved = getState().queries.approvedQueries
    index = allApproved.findIndex(q => q.id == id);
    queryItem = allApproved[index];
  } else if (view == 'approved_contracts') {
    inventoriedFrom = 'Approved Contract'
    let approvedContracts = getState().queries.approvedContracts
    index = approvedContracts.findIndex(q => q.id == id);
    queryItem = approvedContracts[index];
  }
  else if (view == 'sent_contracts') {
    inventoriedFrom = 'Sent Contract'
    let sentContracts = getState().queries.sentContracts
    index = sentContracts.findIndex(q => q.id == id);
    queryItem = sentContracts[index];
  } else if (view == 'signed_contracts') {
    inventoriedFrom = 'Signed Contract'
    let signedContracts = getState().queries.signedContracts
    index = signedContracts.findIndex(q => q.id == id);
    queryItem = signedContracts[index];
  }


  let inventoryData = await api.getDocById({ table: 'stats', id: 'inventory_management' })
  let lastInventory = inventoryData?.last_inventory;
  let updatedCount;
  let inventoryManagers = inventoryData?.inventory_managers;
  let inventoryManagerData;
  console.log(lastInventory, inventoryManagers)
  if (!lastInventory) {
    inventoryManagerData = inventoryManagers[0]
    updatedCount = 1
  } else {
    if (lastInventory == inventoryManagers.length) {
      inventoryManagerData = inventoryManagers[0]
      updatedCount = 1
    } else {
      inventoryManagerData = inventoryManagers[lastInventory]
      updatedCount = lastInventory + 1
    }
  }

  let { inventoryRemarks } = data
  let dataToSet = {
    id,
    inventory: true,
    inventory_remarks: inventoryRemarks,
    inventoried_by: user,
    inventoried_date: serverTimestamp(),
    inventoried_from: inventoriedFrom,
    inventory_manager: inventoryManagerData,
    // inventory_manager_name :  inventoryManagerData.name,
    new_query: false,
    approved: false,
    contract_sent: false,
    contract_signed: false,
  }

  let date = new Date();
  queryItem = {
    ...queryItem,
    ...dataToSet,
    inventoried_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setAddingToInventory(false))
      dispatch(onAddingToInventory({ index, queryItem, view }))
      api.setDocById({
        collection: 'stats', data: {
          id: 'inventory_management',
          last_inventory: updatedCount
        }
      })
    }
  ).catch((err) => {
    dispatch(setAddingToInventory(false))
  })
}

export const onBulkInventory = ({ actionType, items, allocations }) => async (dispatch, getState) => {

  console.log(actionType, items)
  let validAllocations = allocations.filter(al => al.count !== 0)
  console.log(validAllocations)
  let user = selectUserData(getState());

  let inventoryData = await api.getDocById({ table: 'stats', id: 'inventory_management' })
  let lastInventory = inventoryData?.last_inventory;
  let updatedCount;

  const promises = []
  if (actionType == 'auto') {
    dispatch(isBulkInventorying(true))
    items.forEach(item => {
      let queryItem; let index;
      let inventoriedFrom;
      inventoriedFrom = 'Query'
      let queries = getState().queries.queries;
      index = queries.findIndex(q => q.id == item);
      queryItem = queries[index];

      let inventoryManagers = inventoryData?.inventory_managers;
      let inventoryManagerData;

      if (!lastInventory) {
        inventoryManagerData = inventoryManagers[0]
        updatedCount = 1
        lastInventory = 1;
      } else {
        if (lastInventory == inventoryManagers.length) {
          inventoryManagerData = inventoryManagers[0]
          updatedCount = 1
          lastInventory = 1;
        } else {
          inventoryManagerData = inventoryManagers[lastInventory]
          updatedCount = lastInventory + 1
          lastInventory++;
        }
      }

      let dataToSet = {
        id: item,
        inventory: true,
        inventoried_by: user,
        inventoried_date: serverTimestamp(),
        inventoried_from: inventoriedFrom,
        inventory_manager: inventoryManagerData,
        // inventory_manager_name :  inventoryManagerData.name,
        new_query: false,
        approved: false,
        contract_sent: false,
        contract_signed: false,
      }
      let date = new Date();
      queryItem = {
        ...queryItem,
        ...dataToSet,
        inventoried_date: { local: true, time: date }
      }
      promises.push(api.setDocById({ collection: 'queries', data: dataToSet }))
    })

    Promise.all(promises)
      .then((newPromises) => {
        console.log(newPromises)
        dispatch(isBulkInventorying(false))
        dispatch(onBulkInventoryAdded(items))
        api.setDocById({
          collection: 'stats', data: {
            id: 'inventory_management',
            last_inventory: updatedCount
          }
        })
      }).catch((err) => {
        console.log(err)
      })


  } else {
    let itemsCopy = [...items]
    dispatch(isBulkInventorying(true))
    validAllocations.forEach(va => {
      va.items = itemsCopy.splice(0, va.count)
      va.items && va.items.length && va.items.forEach(item => {
        let queryItem; let index;
        let inventoriedFrom = 'Query'
        let queries = getState().queries.queries;
        index = queries.findIndex(q => q.id == item);
        queryItem = queries[index];

        let dataToSet = {
          id: item,
          inventory: true,
          inventoried_by: user,
          inventoried_date: serverTimestamp(),
          inventoried_from: inventoriedFrom,
          inventory_manager: { id: va.id, name: va.name },
          // inventory_manager_name :  inventoryManagerData.name,
          new_query: false,
          approved: false,
          contract_sent: false,
          contract_signed: false,
        }
        let date = new Date();
        queryItem = {
          ...queryItem,
          ...dataToSet,
          inventoried_date: { local: true, time: date }
        }
        promises.push(api.setDocById({ collection: 'queries', data: dataToSet }))
      })
    })

    Promise.all(promises)
      .then((newPromises) => {
        console.log(newPromises)
        dispatch(isBulkInventorying(false))
        dispatch(onBulkInventoryAdded(items))
      }).catch((err) => {
        console.log(err)
      })
  }

}

export const getAllInventory = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingInventory(true))
  let cachedInventory = getState().queries.inventory || null;
  if (!cachedInventory || cachedInventory.length == 0 || queryString != undefined) {
    api.getAllDocs({
      collectionName: 'queries',
      order: ("inventoried_date"),
      wheres: [["inventory", "==", true]],
      limitAt: 600
    }).then(
      response => {
        console.log(response)
        dispatch(setIsFetchingInventory(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setInventorySearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setInventorySearchString(null))
        }
        dispatch(setInventory(data))
      }
    )
  } else {
    dispatch(setInventory(cachedInventory))
    dispatch(setIsFetchingInventory(false))
  }
}

export const getInventoryById = (queryString, id) => async (dispatch, getState) => {

  dispatch(setIsFetchingInventory(true))
  let cachedInventory = getState().queries.inventory || null;
  if (!cachedInventory || cachedInventory.length == 0 || queryString != undefined) {
    api.getAllDocs({
      collectionName: 'queries',
      order: ("inventoried_date"),
      wheres: [["inventory", "==", true], ["inventory_manager.id", "==", id]],
      limitAt: 600
    }).then(
      response => {
        console.log(response)
        dispatch(setIsFetchingInventory(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setInventorySearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setInventorySearchString(null))

        }
        dispatch(setInventory(data))
      }
    )
  } else {
    dispatch(setInventory(cachedInventory))
    dispatch(setIsFetchingInventory(false))
  }
}



export const selectAllInventory = (state) => state.queries.inventory;
export const selectInventorySearchString = (state) => state.queries.inventorySearchString;

export const selectIsFetchingInventory = (state) => state.queries.isFetchingInventory;
export const selectIsInventoried = (state, id) => state.queries.inventoriedMap[id] && state.queries.inventoriedMap[id] || null;
export const selectIsAddingToInventory = (state) => state.queries.isAddingToInventory;
export const selectIsAddingBulkInventory = (state) => state.queries.isAddingBulkInventory;


//HOT DEALS//
//************************************ */
//************************************ */
export const moveToHotDeals = ({ id, remarks }) => async (dispatch, getState) => {
  dispatch(setMovingToHotDeals(true))
  let user = selectUserData(getState());
  let inventory = selectAllInventory(getState())
  let index = inventory.findIndex(i => i.id == id);

  let inventoryData = await api.getDocById({ table: 'stats', id: 'inventory_management' })
  let lastHotDeal = inventoryData?.last_hotdeal;
  let updatedCount;
  let propertyManagers = inventoryData?.property_managers;
  let propertyManagerData;
  console.log(lastHotDeal, propertyManagers)
  if (!lastHotDeal) {
    propertyManagerData = propertyManagers[0]
    updatedCount = 1
  } else {
    if (lastHotDeal == propertyManagers.length) {
      propertyManagerData = propertyManagers[0]
      updatedCount = 1
    } else {
      propertyManagerData = propertyManagers[lastHotDeal]
      updatedCount = lastHotDeal + 1
    }
  }

  let inventoryItem;
  let dataToSet = {
    id,
    hot_deal: true,
    hot_dealed_by: user,
    // hot_dealed_remarks: remarks,
    inventory_manager_remarks : remarks,
    hot_dealed_date: serverTimestamp(),
    property_manager: propertyManagerData,
    inventory: false,
  }

  let date = new Date();
  inventoryItem = {
    ...dataToSet,
    hot_dealed_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setMovingToHotDeals(false))
      dispatch(onMovingToHotDeals({ index, inventoryItem }))
      api.setDocById({
        collection: 'stats', data: {
          id: 'inventory_management',
          last_hotdeal: updatedCount
        }
      })
    }
  ).catch((err) => {
    dispatch(setMovingToHotDeals(false))
  })
}

export const getHotDeals = (queryString, id, pm_id, ) => async (dispatch, getState) => {
  dispatch(setIsFetchingHotDeals(true))
  let cachedHotDeals = getState().queries.hotdeals || null;
  if (!cachedHotDeals || cachedHotDeals.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("hot_dealed_date"), wheres: [["hot_deal", "==", true]] }).then(
      response => {
        dispatch(setIsFetchingHotDeals(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setHotDealsSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setHotDealsSearchString(null))
        }
        if (pm_id) {

          let date = DateTime.fromISO('2022-06-14T11:00:00.000')

          let oldData = data.filter(item => item.inventoryHotdealedTimestamp < date)
          let newData = data.filter(item => item.inventoryHotdealedTimestamp > date)

          // let filteredData = newData.filter(item => item.property_manager.id == pm_id)
          data = [...newData, ...oldData,]
        }
        if (id) {
          data = data.filter(item => item.hot_dealed_by.id == id)
        }
        dispatch(setHotDeals(data))
      }
    )
  } else {
    dispatch(setHotDeals(cachedHotDeals))
    dispatch(setIsFetchingHotDeals(false))
  }
}


export const selectHotDeals = (state) => state.queries.hotdeals;
export const selectHotDealsSearchString = (state) => state.queries.hotdealsSearchString;

export const selectIsFetchingHotDeals = (state) => state.queries.isFetchingHotDeals;
export const selectIsMovedToHotDeals = (state, id) => state.queries.hotdealsmap[id] && state.queries.hotdealsmap[id] || null;
export const selectIsMovingToHotDeals = (state) => state.queries.isMovingToHotDeals;


//LISTINGS //
//*********************************** */
//*********************************** */
//*********************************** */

export const moveToListing = ({ id, remarks }) => async (dispatch, getState) => {
  dispatch(setMovingToListing(true))
  let user = selectUserData(getState());
  let inventory = selectAllInventory(getState())
  let index = inventory.findIndex(i => i.id == id);

  let listingItem;
  let dataToSet = {
    id,
    inventory: false,
    listing:true,
    listing_by: user,
    inventory_manager_remarks : remarks,
    listing_date: serverTimestamp(),
  }

  let date = new Date();
  listingItem = {
    ...dataToSet,
    listing_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setMovingToListing(false))
      dispatch(onMovedToListing({ index, listingItem }))
    }
  ).catch((err) => {
    dispatch(setMovingToListing(false))
  })
}

export const getListings = (queryString, id,  ) => async (dispatch, getState) => {
  dispatch(setIsFetchingListings(true))
  let cachedListings = getState().queries.listings || null;
  if (!cachedListings || cachedListings.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("listing_date"), wheres: [["listing", "==", true]] }).then(
      response => {
        dispatch(setIsFetchingListings(false))
        let { data } = transformData(response)
        if (id) {
          data = data.filter(item => item.listing_by.id == id)
        }
        dispatch(setListings(data))
      }
    )
  } else {
    dispatch(setListings(cachedListings))
    dispatch(setIsFetchingListings(false))
  }
}


export const selectAllListings = (state) => state.queries.listings;
export const selectIsFetchingListings = (state) => state.queries.isFetchingListings;
export const selectIsListed = (state, id) => state.queries.listingsMap[id] && state.queries.listingsMap[id] || null;
export const selectIsListing = (state) => state.queries.isListing;




//INVENTORY HOT DEAL CONTRACTS//
//************************************ */
//************************************ */

export const moveToInventoryContracts = ({ id, remarks }) => async (dispatch, getState) => {
  dispatch(setMovingToInventoryContracts(true))

  let user = selectUserData(getState());
  let hotdeals = selectHotDeals(getState())
  let index = hotdeals.findIndex(i => i.id == id);

  let inventoryItem;
  let dataToSet = {
    id,
    hot_deal: false,
    inventory_contract_submitted: true,
    // inventory_contract_submitted_remarks: remarks,
    property_manager_remarks_inventory : remarks,
    inventory_contract_submitted_date: serverTimestamp(),
    inventory_contract_submitted_by: user,
  }

  let date = new Date();
  inventoryItem = {
    ...dataToSet,
    inventory_contract_submitted_date: { local: true, time: date }
  }
  api.setDocById({ collection: 'queries', data: dataToSet }).then(
    () => {
      dispatch(setMovingToInventoryContracts(false))
      dispatch(onMovingToInventoryContracts({ index, inventoryItem }))
    }
  ).catch((err) => {
    dispatch(setMovingToInventoryContracts(false))
  })
}

export const getInventoryContracts = (queryString) => async (dispatch, getState) => {
  dispatch(setIsFetchingInventoryContracts(true))
  let cached = getState().queries.inventory_contract_submitted || null;
  if (!cached || cached.length == 0 || queryString != undefined) {
    api.getAllDocs({ collectionName: 'queries', order: ("inventory_contract_submitted_date"), wheres: [["inventory_contract_submitted", "==", true]] }).then(
      response => {
        dispatch(setIsFetchingInventoryContracts(false))
        let { data } = transformData(response)
        if (queryString) {
          dispatch(setInventoryContractsSearchString(queryString))
          data = getSearchResults(queryString, data)
        } else {
          dispatch(setInventoryContractsSearchString(null))
        }
        dispatch(setInventoryContracts(data))
      }
    )
  } else {
    dispatch(setInventoryContracts(cached))
    dispatch(setIsFetchingInventoryContracts(false))
  }
}


export const selectInventoryContracts = (state) => state.queries.inventoryContracts;
export const selectInventoryContractsSearchString = (state) => state.queries.inventoryContractsSearchString;

export const selectIsFetchingInventoryContracts = (state) => state.queries.isFetchingInventoryContracts;
export const selectIsAddedToInventoryContracts = (state, id) => state.queries.inventoryContractsMap[id] && state.queries.inventoryContractsMap[id] || null;
export const selectIsAddingToInventoryContracts = (state) => state.queries.isMovingToInventoryContracts;