import { DateTime } from 'luxon';
import {useState, useEffect} from 'react';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';


function CustomClock (){
    const DateTimeFormat_TIME_SIMPLE_12_HOUR = Object.assign(Object.assign({}, DateTime.TIME_WITH_SECONDS), { hour12: true });
    const setCustomTime = () => {
        return DateTime.fromJSDate(new Date(), {zone:'UTC-4'} ).toISOTime()      
    }
    const [time, setTime] = useState(setCustomTime);
   
    useEffect(() => {
      let TimeId = setInterval(() => setTime(setCustomTime), 1000);
      return () => {
       clearInterval(TimeId);
      };
      });
 
       return (
       <div style={{}}> 
        <Clock 
          className={'custom-clock'}
          value={time} 
          hourMarksWidth={5} 
          hourMarksLength={14} 
          hourHandWidth={4}
          hourHandOppositeLength={15}
          hourHandLength={70}
          minuteHandLength={80}
          minuteHandWidth={3}
          minuteHandOppositeLength={15}
          secondHandLength={80}
          secondHandOppositeLength={20}
          />
        </div>);
}
    
export default CustomClock;