import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_COLORS } from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import Select from '../common/Select';
import { addManagementRemarks, selectAddedManagementRemarks, selectIsAddingManagementRemarks, resetIsAddedRemarks } from '../../store/query';


export default function AddRemarks({ id, onClose, view, text }) {
  console.log(text)
  const dispatch = useDispatch();
  let isAdded = useSelector(selectAddedManagementRemarks)
  let isAdding = useSelector(selectIsAddingManagementRemarks)

  let [remarks, setRemarks] = useState(text);

  const onAddRemarks = () => {
    dispatch(addManagementRemarks({ id, remarks, view }))
  }

  useEffect(() => {
    return dispatch(resetIsAddedRemarks())
  }, [])

  useEffect(() => {
    if (isAdded) {
      dispatch(resetIsAddedRemarks())
      onClose()

    }
  }, [isAdded])

  return (
    <div style={{}}>
      <TextField
        name={'Add Remarks'}
        text={remarks}
        type={'text'}
        placeholder={'Add Remarks'}
        onTextChange={(target) => { console.log(target.name, target.value); setRemarks(target.value) }}
        // onEnter = {()=>{}} 
        autoFocus={false}
        style={{ color: APP_COLORS.SECONDARY_TEXT }}
        multiLine={true}
      // onCancel = {} 
      // onFocus = {}
      // onClear = {} 
      />

      <div style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}>
        {isAdding ?
          <Loader /> :
          <Button
            label={'Add'}
            onClick={onAddRemarks}
            style={{ textAlign: "center", padding: 10, fontSize: "16px" }}
          />}
      </div>

    </div>
  )
}