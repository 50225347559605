import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { APP_COLORS, pageStyles } from "../styles";
import { getQueryById, selectIsFetchingQuery, selectQueryById } from "../store/query";
import { selectUserData } from "../store/user";
import Icon from "../components/common/Icon";
import Loader from '../components/common/Loading';
import { ROLE } from "../constants";
import Button from "../components/common/Button";
import Dialogue from '../components/common/Dialogue';
import MoveToHotDealsForm from '../components/modals/MoveToHotDealsForm';
import MoveToInventoryContracts from '../components/modals/AddToInventoryContracts';
import CommentsPanel from "../components/queries/CommentsPanel";

export default function FullView({ }) {
    const { id } = useParams();
    const { state } = useLocation()
    console.log(state)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const item = useSelector((state) => selectQueryById(state, id)) || state?.item

    const isFetching = useSelector(selectIsFetchingQuery)
    const user = useSelector(selectUserData)
    const role = user?.role

    let [activeProperty, setActiveProperty] = useState(0)
    let [showDialogue, setShowDialogue] = useState(null);

    useEffect(() => {
        if (!item)
            dispatch(getQueryById(id))
    }, [id])

    const onMoveToHotDealsClicked = (id, remarks) => {
        setShowDialogue({ show: true, type: 'hot_deals', itemId: id, text: remarks })
    }
    const onMoveToInventoryContracts = (id) => {
        setShowDialogue({ show: true, type: 'inventory_contracts', itemId: id });
      }

    return (
        <div style={pageStyles}>
            {isFetching && <div style={{ display: 'flex', alignItems: 'center', }}><Loader /></div>}
            {item &&
                <div>
                    <div style={{ ...styles.flexSpaceBetween, color: APP_COLORS.PRIMARY_TEXT }}>
                        <div style={{
                            ...styles.flexColumn,
                            width: '75%',
                            paddingRight: '20px',
                            boxSizing: 'border-box',
                            gap: 20,
                        }}>
                            <section>
                                <div style={{ ...styles.flexSpaceBetween, alignItems: 'center' }}>
                                    <div style={{
                                        ...styles.flexAlignCenter,
                                        ...styles.title,
                                        color: APP_COLORS.SECONDARY_TEXT,
                                        marginBottom: 5,
                                        gap: 10,
                                    }}>
                                        <Icon family={'BootstrapIcons'} name='person-circle' styles={{ fontSize: '20px' }} />
                                        {item.fullname && item.fullname}
                                    </div>

                                    <div style={{
                                        ...styles.flexAlignCenter,
                                        color: APP_COLORS.SECONDARY_TEXT,
                                        fontSize: '18px',
                                        gap: 6,
                                    }}>
                                        <Icon family={'BootstrapIcons'} name='telephone-fill' styles={{ fontSize: '16px' }} />
                                        {item.phone_no}
                                    </div>
                                </div>

                                {item.email &&
                                    <div style={{
                                        ...styles.flexAlignCenter,
                                        gap: 6
                                    }}>
                                        <Icon family={'BootstrapIcons'} name='envelope' styles={{ fontSize: '14px' }} />
                                        {item.email}
                                    </div>
                                }

                                {item.mailing_address &&
                                    <div style={{
                                        ...styles.flexAlignCenter,
                                        gap: 4
                                    }}>
                                        <Icon family={'BootstrapIcons'} name='mailbox' styles={{ fontSize: '14px' }} />
                                        {item.mailing_address}
                                    </div>
                                }
                            </section>

                            <div style={{ ...styles.flexAlignCenter, gap: 20 }}>
                                {item.properties.length > 1 && item.properties.map(((p, i) => (
                                    <div
                                        onClick={() => {
                                            setActiveProperty(i)
                                        }}
                                        style={{
                                            margin: '10px 0px',
                                            padding: '5px 10px',
                                            background: i == activeProperty ? APP_COLORS.LINK_ORANGE : APP_COLORS.META_BACKGROUND,
                                            borderRadius: 10,
                                            cursor: 'pointer',
                                            color: i == activeProperty && '#fff'
                                        }}> Property {i + 1} </div>)
                                ))
                                }
                            </div>
                            {item.properties.filter((p, i) => i == activeProperty).map(p => (
                                <>
                                    <section>
                                        <div style={{
                                            ...styles.title,
                                            ...styles.flexSpaceBetween,
                                            color: APP_COLORS.SECONDARY_TEXT,
                                            marginBottom: 5,
                                        }}>
                                            <div>
                                                {item.properties[activeProperty].address}
                                            </div>
                                            <div>{item.properties[activeProperty].state.label}  </div>
                                        </div>
                                        <div style={styles.flexSpaceBetween}>
                                            <div>
                                                <div style={{ display: 'flex', gap: 10, marginBottom: 5 }}>
                                                    <div> <b> {item.properties[activeProperty].area} </b> </div>
                                                    <div style={{ borderLeft: '1px solid gray' }} />
                                                    <div>Condition: {item.properties[activeProperty].condition} </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: 10, }}>
                                                    <div> Occupied : {item.properties[activeProperty].occupation} </div>
                                                    <div style={{ borderLeft: '1px solid gray' }} />
                                                    <div> Moving Time-off: {item.properties[activeProperty].moving_toff}</div>
                                                </div>
                                            </div>

                                            <div style={{ textAlign: 'right', }}>
                                                {item.properties[activeProperty].asking && <div style={{ marginBottom: 5 }}>
                                                    Demand: <b>{item.properties[activeProperty].asking}</b>
                                                </div>}
                                                {item.properties[activeProperty].zestimate &&
                                                    <div style={{ marginBottom: 5 }}>
                                                        Zest:
                                                        <b> <span style={{
                                                            fontSize: '20px',
                                                            color: APP_COLORS.SECONDARY_TEXT
                                                        }}>
                                                            {item.properties[activeProperty].zestimate}</span>
                                                        </b>
                                                    </div>
                                                }
                                                {item.properties[activeProperty].price &&
                                                    <div style={{ marginBottom: 5 }}>
                                                        Our Offer:  <b>{item.properties[activeProperty].price}</b>
                                                    </div>}
                                            </div>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            {item.properties[activeProperty].links?.length ? item.properties[activeProperty].links.map(link => (
                                                <div>
                                                    <a href={link} style={{ color: APP_COLORS.PRIMARY_TEXT, opacity: 0.8, width: '100%' }} target='_blank'>{link} </a>
                                                </div>
                                            )) : null}
                                        </div>
                                    </section>

                                    <section style={{ marginTop: 10, display: 'flex', gap: 20, fontSize: '14px', }}>

                                        {item.properties[activeProperty].remarks &&
                                            <div
                                                style={{
                                                    maxWidth: 300,
                                                    lineHeight: 1.2
                                                }}>
                                                <b>Agent Remarks</b> <br />
                                                {item.properties[activeProperty].remarks}
                                            </div>
                                        }

                                        {item.management_remarks &&
                                            <div
                                                style={{
                                                    maxWidth: 300,
                                                    lineHeight: 1.2
                                                }}>
                                                <b>Management Remarks</b> <br />
                                                {item.management_remarks}
                                            </div>
                                        }

                                        {item.inventory_manager_remarks &&
                                            ((role == ROLE.Admin) || (role == ROLE.PropertyManager) || (role == ROLE.InventoryManager)) &&
                                            <div style={{ maxWidth: 300, lineHeight: 1.2 }}>
                                                <b>Inventory Manager Remarks</b> <br />
                                                {item.inventory_manager_remarks}
                                            </div>
                                        }
                                        {item.property_manager_remarks_inventory &&
                                            ((role == ROLE.Admin) || (role == ROLE.PropertyManager)) &&
                                            <div style={{ maxWidth: 300, lineHeight: 1.2 }}>
                                                <b>Property Manager Remarks</b> <br />
                                                {item.property_manager_remarks_inventory}
                                            </div>
                                        }

                                        {item.property_manager_remarks_inventory &&
                                            ((role == ROLE.Admin) || (role == ROLE.PropertyManager)) &&
                                            <div style={{ maxWidth: 300, lineHeight: 1.2 }}>
                                                <b> Property Manager Remarks </b> <br />
                                                {item.property_manager_remarks_inventory}
                                            </div>
                                        }
                                       
                                    </section>
                                    <section>
                                    {item.hot_deal &&   
                                            <CommentsPanel queryId = {item.id} />
                                        }
                                    </section>
                                </>
                            ))}

                        </div>

                        <div style={{
                            ...styles.flexColumn,
                            width: '25%',
                            padding: 15,
                            boxSizing: 'border-box',
                            borderRadius: 10,
                            background: APP_COLORS.SECONDARY_BACKGROUND,
                            gap: 10,
                        }}>
                            <div style={{display:'flex', gap:10,}}>
                            {(item.inventory) && (role == ROLE.InventoryManager || role == ROLE.Admin) &&
                            <>
                                <Button
                                    label={'Edit'}
                                    onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view: state?.view } }) }}
                                    icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                                    style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                                />
                                <Button
                                    label={'Move to Hot-Deals'}
                                    onClick={ () => onMoveToHotDealsClicked(item.id, item.inventory_manager_remarks)}
                                    icon={{ name: 'hotjar', family: 'FontAwesome5Brands'}}
                                    style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.RED, }}
                                />
                                <Button
                                    label={'Move to Listings'}
                                    // onClick={ () => onMoveToHotDealsClicked(item.id, item.inventory_manager_remarks)}
                                    icon={{ name: 'receipt', family: 'BootstrapIcons' }}
                                    style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.BUTTON_BACKGROUND, }}
                                />  
                            </>
                        }

                        {(item.hot_deal) && (role == ROLE.Admin || role == ROLE.PropertyManager || role == ROLE.DispositionManager) &&
                            <>
                                <Button
                                    label={'Edit'}
                                    onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view: state?.view } }) }}
                                    icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                                    style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                                />
                                <Button
                                    label={'Move to InventoryContracts'}
                                    onClick={() => onMoveToInventoryContracts(item.id)}
                                    icon={{ name: 'mail-bulk', family: 'FontAwesome5' }}
                                    style={{ fontSize: "10px", padding: 10, width: "120px", color: 'white', background: APP_COLORS.GREEN, }}
                                />
                            </>
                        }
                            </div>
                            <div>
                                <div style={styles.metaTitle}>
                                    Status
                                </div>
                                <div>
                                    {item.new_query && <div>{'New Query'} </div>}
                                    {item.inventory && <div> Inventory 
                                    <Icon name='folder2-open' family='BootstrapIcons' styles={{display:'inline', marginLeft:10,}} />
                                    </div>}
                                    {item.hot_deal && <div> Hot Deal
                                    <Icon name='hotjar' family='FontAwesome5Brands' styles={{display:'inline', marginLeft:10,}} />
                                    </div>}
                                    {item.inventory_contract_submitted &&<div>Inventory Contracted</div>}
                                </div>
                            </div>
                            <div>
                                <div style={styles.metaTitle}>
                                    Created
                                </div>
                                <div>
                                    {item.dateStr}
                                </div>
                            </div>
                            <div>
                                <div style={styles.metaTitle}>
                                    Added By
                                </div>
                                <div>
                                    {item.added_by.firstname + " " + item.added_by.lastname}
                                </div>
                                <div>
                                    {item.alias}
                                </div>
                                <div>
                                    {item.added_by.office}
                                </div>
                            </div>

                            {item.inventory &&
                                <div>
                                    <div style={styles.metaTitle}>
                                        Inventoried
                                    </div>
                                    <div>
                                        <b>to</b> <i>{item.inventory_manager.name}</i>
                                    </div>
                                    <div>
                                        <b>on</b> <i>{item.inventoriedDateStr}</i>
                                    </div>
                                </div>
                            }

                            {item.hot_deal &&
                                <div>
                                    <div style={styles.metaTitle}>
                                        Hot Dealed
                                    </div>
                                    <div>
                                        <b>by</b> <i>{item.hot_dealed_by.firstname + ' ' + item.hot_dealed_by.lastname}</i>
                                    </div>
                                    <div>
                                        <b>on</b> <i>{item.inventoryHotdealedDateStr}</i>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        {showDialogue && showDialogue.type == 'hot_deals' &&
        <Dialogue
          headerText={'Move to Hot-Deals'}
          content={<MoveToHotDealsForm id={showDialogue.itemId} view={showDialogue.view} text={showDialogue.text} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}

        {showDialogue && showDialogue.type == 'inventory_contracts' &&
        <Dialogue
          headerText={'Move to Inventory Contracts'}
          content={<MoveToInventoryContracts id={showDialogue.itemId} view={showDialogue.view} onClose={() => setShowDialogue(null)} />}
          show={showDialogue.show}
          onClose={() => setShowDialogue(null)}
        />}
        </div>
    )
}


const styles = {
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    flexAlignCenter: {
        display: 'flex',
        alignItems: "center"
    },
    title: {
        fontSize: '26px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    metaTitle: {
        fontSize:'14px',
        color: APP_COLORS.LINK_ORANGE,
    }
}