
import { useEffect, useState } from "react"
import { APP_COLORS } from "../../styles"
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button"
import FileUploader from "../common/fileUploader";
import { addFileToFirebase,  selectUploadingCSVStatus, resetUploadingCSV} from "../../store/csv";
import { getAllCSVCollections } from "../../store/app";

const csv = require('csvtojson')

export default function UploadQueries ({}) {

    const dispatch = useDispatch()
    const [file, setFile] = useState()
    
    let uploadingCSVStatus = useSelector(selectUploadingCSVStatus)

    useEffect(()=>{
        return () => {dispatch(resetUploadingCSV())}
    },[])

    useEffect(()=>{
        if(uploadingCSVStatus !== 'processing'){
            if(uploadingCSVStatus == 'completed'){
                alert('file uploaded successfully, please find in CSV collections.')
                dispatch(getAllCSVCollections())
            }else if(uploadingCSVStatus == 'failed'){
                alert('file upload failed, please try again.')
            }
            setFile(null)
        }
    },[uploadingCSVStatus])

    const onFilesDropped = (files) => {
        setFile(files[0])
        console.log(files[0])
    }

    return (
    <div style={styles.container}>
        <div style={{margin:10,  padding:20, display:'flex',gap:20, }}>
            {file ? 
            <div style={{ width:'100%', display:'flex', justifyContent:'space-between' , alignItems : 'center' }}>
                <div>{ file.name } </div>
                <div 
                    style={{ padding:5, cursor:'pointer' }}
                    onClick={()=>{
                        setFile(null)
                    }} > x </div>
            </div> : 
            <FileUploader
                onFilesDropped={onFilesDropped}
                text={'Click or Drag n Drop CSV Here!'}
                accept = {".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
            />
            }

            {file && 
            <div style={{margin:'10px 0px', display:'flex', justifyContent:'flex-end'}}>
                { uploadingCSVStatus == 'processing' ? 
                    <div> Processing CSV ... </div> 
                    :
                <Button
                    label={'Add To Database'} 
                    style={{padding:10, width:100,fontSize:'12px'}}
                    onClick={()=>{
                        let json = {}
                        const reader = new FileReader();
                        if(file.type.includes('csv') ){
                            console.log('csv')
                            
                            reader.onload = () => {
                              const fileAsBinaryString = reader.result;
                              csv({
                                noheader: true,
                                output: "json"
                              })
                                .fromString(fileAsBinaryString)
                                .then((csvRows) => {
                                  const toJson = []
                                  csvRows.forEach((aCsvRow, i) => {
                                    if (i !== 0) {
                                      const builtObject = {}
                      
                                      Object.keys(aCsvRow).forEach((aKey) => {
                                        const valueToAddInBuiltObject = aCsvRow[aKey];
                                        const keyToAddInBuiltObject = csvRows[0][aKey];
                                        builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                                      })
                                      toJson.push(builtObject)
                                    }
                      
                                  }) 
                                  dispatch(addFileToFirebase(toJson, file.name ))
                                })
                            }
                            reader.onabort = () => console.log('file reading was aborted');
                            reader.onerror = () => console.log('file reading has failed');
                            reader.readAsBinaryString(file);

                        }
                    }} />
                }
            </div>}
        </div>
    </div>)
}

const styles = {
    container:{
        color: APP_COLORS.PRIMARY_TEXT,
        height:'60vh', 
        width:'100%',
        padding:20,
    }
}