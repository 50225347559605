import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import TextField from '../common/textField';
import Select from '../common/Select';
import Button from '../common/Button';
import Loader from '../common/Loading';
import { APP_COLORS } from '../../styles';
import { validate, checkForErrors } from '../../utils';
import { fetchUserData, addUserToFirebase, selectIsAddingUser, selectIsUserAdded, createUser, updateUser, selectIsUpdatingUser } from '../../store/user';
import Checkbox from '../common/CheckBox';

export default function AddUserFrom({ editUserData }) {
  let dispatch = useDispatch()
  let userAdded = useSelector(selectIsUserAdded)
  let isAddingUser = useSelector(selectIsAddingUser)
  let isUpdatingUser = useSelector(selectIsUpdatingUser)
  let [isEdit, setIsEdit] = useState( !!editUserData ? true : false )

  let [userData, setUserData] = useState({
    id: editUserData?.id || '',
    firstname: editUserData?.firstname || "",
    lastname: editUserData?.lastname || "",
    email: editUserData?.email || '',
    password: '',
    role: editUserData?.role || 'virtual_realtor',
    office: editUserData?.office || 'nadra',
    disabled: editUserData?.disabled || false,
  });


  let [errors, setErrors] = useState({})
  let errorRef = useRef(errors);

  useEffect(() => {
    if (editUserData) {
      dispatch(fetchUserData(editUserData))
    }
  }, [])

  const handleChange = (target) => {
    const { name, value } = target;
    let returnedErrors = validate(name, value)
    // console.log(returnedErrors[name])
    setErrors(errors => ({
      ...errors,
      [name]: returnedErrors[name]
    }));
    setUserData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const handleSubmit = () => {
    for (const key in userData) {
      let returnedErrors = validate(key, userData[key], true)
      errorRef.current[key] = returnedErrors[key]
      setErrors(errors => ({
        ...errors,
        [key]: returnedErrors[key]
      }));
    }
    let hasErrors = checkForErrors(errorRef.current)
    if (!hasErrors) {
      console.log(editUserData)
      if (editUserData) {
        dispatch(updateUser(userData))
      } else {
        dispatch(createUser(userData))
      }
      // dispatch(addUserToFirebase(userData))
    }
  }

  // useEffect(()=>{
  //   if(userAdded){
  //     toast.success('User Added Successfully')
  //   }
  // },[userAdded])

  return (
    <div style={{
      width: '80vw',
      color: APP_COLORS.PRIMARY_TEXT,
      boxSizing: 'border-box',
      padding: 20,
    }}>
      <div style={{ padding: 10, fontSize: '24px', fontWeight: 'bolder' }}>
        {editUserData ? 'Edit User' : 'Add User'}
      </div>

      <section style={{ display: 'flex', gap: 20, height: '100%', justifyContent: 'space-between' }}>
        <section style={{ flex: 1, padding: 10, display: 'flex', flexDirection: "column", alignContent: 'center', justifyContent: 'center' }}>

          <div>
            <div style={{ padding: '10px 0px' }}> Firstname </div>
            <TextField
              name={'firstname'}
              text={userData.firstname}
              type={'text'}
              placeholder={'add firstname'}
              onTextChange={handleChange}
              onEnter={() => { }}
              autoFocus={true}
              style={{ color: APP_COLORS.SECONDARY_TEXT }}
            // onCancel = {} 
            // onFocus = {}
            // onClear = {} 
            />
            <span style={styles.errors}>{errors.firstname}</span>
          </div>

          <div>
            <div style={{ padding: '10px 0px' }}> Lastname </div>
            <TextField
              name={'lastname'}
              text={userData.lastname}
              type={'text'}
              placeholder={'add lastname'}
              onTextChange={handleChange}
              onEnter={() => { }}
              autoFocus={true}
              style={{ color: APP_COLORS.SECONDARY_TEXT }}
            // onCancel = {} 
            // onFocus = {}
            // onClear = {} 
            />
            <span style={styles.errors}>{errors.lastname}</span>
          </div>

          <div>
            <div style={{ padding: '10px 0px' }}> Email </div>
            <TextField
              name={'email'}
              text={userData.email}
              disabled={ isEdit }
              type={'text'}
              placeholder={'add email'}
              onTextChange={handleChange}
              onEnter={() => { }}
              autoFocus={true}
              style={{ color: APP_COLORS.SECONDARY_TEXT }}
            // onCancel = {} 
            // onFocus = {}
            // onClear = {} 
            />
            <span style={styles.errors}>{errors.email}</span>
          </div>
        </section>

        <section style={{ flex: 1, padding: 10, display: 'flex', flexDirection: "column", alignContent: 'center', }}>
          <div style={{ marginBottom: '10px', }}>
            <div style={{ padding: '10px 0px' }}>  Role </div>
            <Select
              onChange={(e) => handleChange(e.target)}
              name={'role'}
              value={userData.role}
              style={{ background: 'inherit', color: APP_COLORS.SECONDARY_TEXT, height: '36px' }}
              options=
              {[
                { id: 'admin', text: 'Admin', value: 'admin', },
                { id: 'contractor', text: 'Contractor', value: 'contractor', },
                { id: 'property_manager', text: 'Property Manager', value: 'property_manager', },
                { id: 'dispo_manager', text: 'Disposition Manager', value: 'dispo_manager', },
                { id: 'inventory_manager', text: 'Inventory Manager', value: 'inventory_manager', },
                { id: 'management', text: 'Management', value: 'management' },
                { id: 'team_leads', text: 'Team Lead', value: 'team_leads' },
                { id: 'virtual_realtor', text: 'Virtual Realtor', value: 'virtual_realtor', }
              ]}
            />
          </div>

          <div style={{ marginBottom: '10px', }}>
            <div style={{ padding: '10px 0px' }}>  Office </div>
            <Select
              onChange={(e) => handleChange(e.target)}
              name={'office'}
              value={userData.office}
              style={{ background: 'inherit', color: APP_COLORS.SECONDARY_TEXT, height: '36px' }}
              options=
              {[
                { id: 'nadra', text: 'KABIR NADRA', value: 'nadra', },
                { id: 'royal', text: 'KABIR ROYAL', value: 'royal', },
              ]}
            />
            <span style={styles.errors}>{errors.office}</span>
          </div>

          <div>
            <div style={{ padding: '10px 0px' }}> Password </div>
            <TextField
              name={'password'}
              text={userData.password}
              type={'password'}
              placeholder={editUserData ? 'enter new password' : 'add a password'}
              onTextChange={handleChange}
              onEnter={() => { }}
              autoFocus={true}
            // onCancel = {} 
            // style = {} 
            // onFocus = {}
            // onClear = {} 
            />
            <span style={styles.errors}>{errors.password}</span>
          </div>
        </section>
      </section>

      {editUserData &&
        <div style={{ padding: 10, }}>
          <div style={{ marginBottom: 10 }}>
            Advanced
          </div>
          <div>
            <Checkbox
              text={"Disable Account"}
              checked={userData.disabled}
              onChange={({ target }) => {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                setUserData(prev => ({
                  ...prev,
                  ['disabled']: value
                }))
              }}
            />
          </div>
        </div>
      }

      <div style={{ padding: 10, display: 'flex', flexDirection: "column", alignContent: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          {(isAddingUser || isUpdatingUser) ?
            <Loader />
            :
            <Button
              label={editUserData ? 'Update User' : 'Add User'}
              onClick={handleSubmit}
              style={{ textAlign: "center", fontSize: "16px", width: '100px', }}
            />
          }
        </div>
        <ToastContainer
          position="top-right"
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  )
}


const styles = {
  errors: {
    fontSize: '12px',
    color: APP_COLORS.LINK_ORANGE,
  }

}