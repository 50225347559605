import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { pageStyles} from "../styles";

import 'react-toastify/dist/ReactToastify.css';
import Section from '../components/common/Section';
import AllUsers from '../components/manageUsers/AllUsers';
import AddUserForm from '../components/manageUsers/AddUserForm';

import {selectAllUsers, getAllUsers} from '../store/user';
import Dialog from '../components/common/Dialog';
import { confirmAlert } from "../components/common/ConfirmationPrompt";

export default function ManageUsers (){
  let dispatch = useDispatch()
  let allUsers = useSelector(selectAllUsers)

  let [showDialogue, setShowDialogue] = useState(false);
  let [ editUserData, setEditUserData] = useState();


  useEffect(()=>{
    dispatch(getAllUsers())
  },[])

  return(
    <div style={pageStyles}>
      <Section
        title={"Manage Users"}
        rightButtons={[{
          onClick:()=>{setShowDialogue(true)},
          label:'Add a user'
        }]}
        content={        
        <div>
          {allUsers &&  
          <AllUsers
            data={allUsers}
            onEditClicked = { (data) => {
              setEditUserData(data)
              setShowDialogue(true)
            }}
            enableOrDisableUser = { (data) => {
              confirmAlert({
                title: 'Confirm' + data.disabled == true ? 'Enable this user' : 'Disable this user' ,
                message: "This action will disable/enable the user.",
                onConfirm: () => { },
                onReject: () => { },
              })
            }}
          />
          }
        </div>
      }
      />
      
      {showDialogue && 
      <Dialog  
        onClose={()=>{setShowDialogue(false); setEditUserData(null)}}
        showClose>
        <AddUserForm editUserData={editUserData}  />
      </Dialog>
        // <Dialogue
        // headerText = {'Add User'}
        // content={<AddUserForm />}
        // show={showDialogue}
        // onClose={()=>setShowDialogue(false)}
        // />
      }

    </div>
  )
}

const styles={
  container:{
    padding:20,
    width:'100%',
  },
  subTitle:{
    fontSize:"18px",
    fontWeight:700,
    marginBottom:'20px'
  },
  section:{
    margin:20,
    padding:15,
  },
 
}
