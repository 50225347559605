import { useEffect, useRef, useState } from "react"
import AddCommentBox from "./AddCommentBox";
import {fetchComments, selectComments, } from "../../store/comments";
import { useDispatch, useSelector } from "react-redux";
import CommentItem from "./CommentItem";
import { APP_COLORS } from "../../styles";


function useScrollTop() {
    const [scrollTop, setScrollTop] = useState(0);
    const onScroll = (event) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
  }

  
export default function CommentsPanel ({queryId}) {
    let dispatch = useDispatch()
    let comments = useSelector( (state) => selectComments(state, queryId))
    
    
    const commentsScrollRef = useRef()
    const [scrolledToComment, setScrolledToComment] = useState(false)
    const scrolledToCommentTracker = useRef()
    scrolledToCommentTracker.current = scrolledToComment
    const [scrollTop, scrollProps] = useScrollTop();
    
    useEffect(()=>{
        dispatch(fetchComments(queryId))
    },[])

    useEffect(()=>{
        commentsScrollRef.current.scrollTop = commentsScrollRef.current.scrollHeight;
    },[comments])

    return(<div style={styles.containerStyle}  >
        <div style={{padding:'10px 0px', fontSize:16}}>
            COMMENTS  {comments?.length && `(${comments.length})`}
        </div>
        <div ref={commentsScrollRef} {...scrollProps} 
        style={{
            boxShadow: scrollTop > 0 ? "inset 0 8px 5px -7px rgb(0 0 0 / 0.4)" : "none",
            transition: "box-shadow 0.3s",
            padding:'0px 0px', 
            overFlowX:'hidden', 
            overflowY:'auto'}}>
        {comments?.length && comments.map(  (item,index) => {return (<CommentItem comment = {item} index={index} />)})}
        </div>
        
        <AddCommentBox 
        queryId = {queryId}  
        onClose={() => {
            console.log('SCROLLING')
          setTimeout(() => {
            if (commentsScrollRef.current) {
              commentsScrollRef.current.scrollTop = commentsScrollRef.current.scrollHeight;
            }
          }, 100);
        }} 
        />
    </div>)
}

const styles = {
    containerStyle : {
        float:'right', 
        padding:20, 
        background: APP_COLORS.PAGE_BACKGROUND, 
        width:'100%', 
        boxSizing:'border-box', 
        maxHeight:400,
        minHeight:140,
        display: 'flex',
        flexDirection: 'column',
      }
}