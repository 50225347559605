import { APP_COLORS } from "../../styles";
import Icon from "../common/Icon";

export default function SmallCard({ title, icon, info, color, footerText, iconStyle }) {

    return (
        <div style={{
            height: '140px',
            width: '260px',
            background: APP_COLORS.SECONDARY_BACKGROUND,
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: 0,
        }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <div style={{ color: APP_COLORS.PRIMARY_TEXT, padding: 8, fontSize: '16px', color, fontWeight: 'bold' }}>
                        {title}
                    </div>

                    <div style={{ paddingLeft: 20, paddingTop: 10, fontSize: '42px', fontWeight: 'bolder', color: color, }}>
                        {info}
                    </div>
                </div>
                <div style={{ width: '40%', marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    {icon && <Icon family={icon.family} name={icon.name} styles={{ color: color, fontSize: '42px', cursor: 'default', ...iconStyle, }} />}

                </div>
            </div>
            <div style={{  color: color, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', padding: 20, fontSize: '12px' }}>
                {footerText}
            </div>
        </div>)
}

