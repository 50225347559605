
import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_COLORS } from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import { sendContract, selectIsContractSent, selectIsSendingContract } from '../../store/query';

export default function SendContractForm({id, onClose}) {

  let dispatch = useDispatch()
  let [sendContractRemarks, setSendContractRemarks] = useState('')
  let isContractSent = useSelector(state => selectIsContractSent(state, id))
  let isSendingContract = useSelector(selectIsSendingContract);

  const handleSubmit = () => {
    dispatch(sendContract({ id: id, sendContractRemarks: sendContractRemarks }))
  }

  useEffect(() => {
    if (isContractSent) {
      onClose()
    }
  }, [isContractSent])
  return (
    <div style={{}}>
      <TextField
        name={'contract_sent_remarks'}
        text={sendContractRemarks}
        type={'text'}
        placeholder={'Add Remarks for Contract'}
        onTextChange={(target) => {  setSendContractRemarks(target.value) }}
        onEnter={() => { }}
        autoFocus={true}
        style={{ color: APP_COLORS.SECONDARY_TEXT }}
        multiLine={true}
      // onCancel = {} 
      // onFocus = {}
      // onClear = {} 
      />
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}>
        {isSendingContract ?
          <Loader />
          :
          <Button
            label={'Send to Contracts'}
            onClick={handleSubmit}
            style={{ textAlign: "center", padding: 10, fontSize: "16px" }}
          />}
      </div>

    </div>
  )
}
