import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import { getSentContracts , selectSentContracts, selectIsFetchingContracts, selectSentContractsSearchString} from '../store/query';
import { ROLE } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';
import {  Outlet } from 'react-router-dom';


export default function SentContracts ({}){


  const dispatch = useDispatch();
  let role = useSelector(selectUserData).role;
  let searchString = useSelector(selectSentContractsSearchString)
  let sentContracts = useSelector(selectSentContracts);
  let isFetchingContracts = useSelector(selectIsFetchingContracts);

  
  useEffect(() => {
    if (role && (role == ROLE.Admin || role == ROLE.Management || ROLE.Contractor)) {
      dispatch(getSentContracts())
    }
  }, [])

  const onSearchEnter= (queryString) => {
    console.log('Sent COntracts', queryString)
    dispatch(getSentContracts(queryString))
  }



  return (<div style={{ ...pageStyles, ...styles.container }}>
    <Section
      title={'Contracts Submitted'}
      content={
        <>
        {sentContracts && <QueryListView 
          role={role} 
          data={sentContracts}  
          isFetching = {isFetchingContracts} 
          onSearchEnter = {onSearchEnter}
          searchString = {searchString}
          />}
        <Outlet/>
        </>
      }
    />

</div>)
}


const styles = {
container: {

},
}
