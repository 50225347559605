import React, { useRef, useState, useEffect } from "react";
import { APP_COLORS } from "../../styles";
import Icon from './Icon';

const TextField = ({
  name,
  type,
  text,
  placeholder,
  onEnter,
  onCancel,
  style,
  onTextChange,
  autoFocus,
  onFocus,
  onClear,
  autocomplete,
  multiLine,
  search,
  disabled
}) => {
  const inputContainerRef = useRef();
  const inputRef = useRef();
  const inputElement = useRef();

  const [editingText, setEditingText] = useState(text || '')
  const [showPassword, setShowPassword] = useState(false)


  const onKeyUp = (keyEvent) => {
    if (inputElement.current && inputElement.current === document.activeElement) {
      if (!keyEvent.shiftKey) {
        if (keyEvent.code === "Enter") {
          keyEvent.preventDefault();
          keyEvent.stopPropagation();
          // setEditingText("")
          onEnter && onEnter(inputRef.current)
          // inputRef.current = ""
        }
        if (keyEvent.code === "Escape") {
          inputRef.current = ""
          keyEvent.stopPropagation();
          keyEvent.preventDefault();
          setEditingText("")
          onCancel();
        }
      }
      if ((!inputRef.current || inputRef.current == "")) {
        if (keyEvent.code === "Backspace" || keyEvent.code === "delete") {
          onClear && onClear()
        }
        if (keyEvent.code === "Escape") {
          keyEvent.stopPropagation();
          keyEvent.preventDefault();
          onCancel && onCancel();
        }
      }
    }
  }

  const onResetPressed = () => {
    inputRef.current = ""
    setEditingText("")
    onCancel();
  }

  const onSearchIconClicked = () => {
    onEnter && onEnter(inputRef.current)
  }

  return (
    <div className={'hoverInput'} style={{ ...styles.inputContainer, ...style }} ref={inputContainerRef} >
      {!multiLine ?
        <input
          disabled={disabled}
          ref={inputElement}
          className={'hoverInput'}
          name={name}
          type={type || 'text'}
          value={editingText}
          autoFocus={autoFocus}
          onFocus={() => {
            if (inputContainerRef.current) { 
              inputContainerRef.current.style.border = `1px solid ${APP_COLORS.LINK_ORANGE}` 
              type !== 'number' && inputElement.current?.setSelectionRange(text.length, text.length)
            }
          }
          }
          placeholder={placeholder}
          onBlur={() => { if (inputContainerRef.current) inputContainerRef.current.style.border = `` }}
          onChange={(e) => {
            inputRef.current = e.target.value;
            setEditingText(e.target.value)
            onTextChange && onTextChange(e.target)
          }}
          onKeyPressCapture={onKeyUp}
          autoComplete={autocomplete}
          style={{ ...styles.inputStyles(multiLine), ...style }}
        />
        :
        <textarea
          disabled={disabled}
          ref={inputElement}
          className={'hoverInput'}
          name={name}
          type={type || 'text'}
          value={editingText}
          autoFocus={autoFocus}
          onFocus={() => {
            if (inputContainerRef.current) inputContainerRef.current.style.border = `1px solid ${APP_COLORS.LINK_ORANGE}`
          }
          }
          placeholder={placeholder}
          onBlur={() => { if (inputContainerRef.current) inputContainerRef.current.style.border = `` }}
          onChange={(e) => {
            inputRef.current = e.target.value;
            setEditingText(e.target.value)
            onTextChange && onTextChange(e.target)
          }}
          onKeyPressCapture={onKeyUp}
          autoComplete={autocomplete}
          style={{ ...styles.inputStyles(multiLine), ...style }}
        />
      }
      {type == 'password' && inputRef?.current?.length > 0 &&
        <div
          className='hover'
          style={{ marginRight: '10px', padding: '5px', borderRadius: '50%', background: APP_COLORS.LINK_BLUE }}>
          <Icon
            name={showPassword ? 'eye-fill' : 'eye-slash-fill'}
            family='BootstrapIcons'
            styles={{ fontSize: '16px', color: APP_COLORS.META_BACKGROUND }}
            onClick={() => {
              setShowPassword(!showPassword)
              console.log(showPassword)
              inputElement.current.type = showPassword ? 'password' : 'text'
            }}
          />
        </div>
      }
      {
        search && <div
          className='hover'
          style={{ marginRight: '2px', padding: '5px', borderRadius: '50%', background: '#216aa4' }}>
          <Icon
            name={'search'}
            family='BootstrapIcons'
            styles={{ fontSize: '12px', color: 'white' }}
            onClick={onSearchIconClicked}
          />
        </div>}
      {
        search && <div
          className='hover'
          style={{ marginRight: '10px', padding: '5px', borderRadius: '50%', background: '#216aa4' }}>
          <Icon
            name={'arrow-repeat'}
            family='BootstrapIcons'
            styles={{ fontSize: '12px', color: 'white' }}
            onClick={onResetPressed}
          />
        </div>}
    </div>
  )
}

const styles = {
  inputContainer: {
    backgroundColor: 'inherit',
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
  },
  inputStyles: (multiLine) => ({
    width: '100%',
    border: 'none',
    backgroundColor: 'inherit',
    color: 'inherit',
    zIndex: '1',
    boxSizing: 'border-box',
    outline: 'none',
    resize: 'none',
    padding: 15,
    height: multiLine && '100px',
  }),
}

export default TextField;

