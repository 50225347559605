
import { collection, doc, query, where, orderBy, updateDoc, deleteDoc, addDoc, onSnapshot , serverTimestamp} from "firebase/firestore";
import { db } from './config';

const commentCollection = () => collection( db , 'comments')

let callCommentsSubs = {}

export const getComments = async ({ subId }, callback) => {
    if (!callCommentsSubs[subId]) {
      callCommentsSubs[subId] = {}
    }
    if (!!callCommentsSubs[subId] && callCommentsSubs[subId].length) {
        console.log('REACHING x')
        return;
    }
   
    const q = query(commentCollection(),
      where("query_id", "==", subId),
      orderBy('created'),
    );
    
    callCommentsSubs[subId] = onSnapshot(q, snapshot => {
        
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          let data = change.doc
          callback({
            id: data.id,
            removed: true,
          }, subId)
        }
      });
      let comments = snapshot.docs.map(data => ({
        id: data.id,
        ...data.data()
      }))
      comments && comments.length && callback(comments, subId)
    })
}


export const addComment = async (commentData) => {
    let toAddData = {
        created: serverTimestamp(),
        ...commentData,
        updated: serverTimestamp(),
      }
      let docref = await addDoc(commentCollection(), toAddData)
      return docref.id
}

export const updateComment = async ({} ) => {

}

export const deleteComment = async ({} ) => {

}
