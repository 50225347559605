import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import { getSignedContracts , selectSignedContracts, selectIsFetchingSignedContracts , selectSignedContractsSearchString} from '../store/query';
import { ROLE } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';
import {  Outlet } from 'react-router-dom';


export default function SignedContracts ({}){

  const dispatch = useDispatch();
  let role = useSelector(selectUserData).role;
  let searchString = useSelector(selectSignedContractsSearchString)
  let signedContracts = useSelector(selectSignedContracts);
  let isFetchingSignedContracts = useSelector(selectIsFetchingSignedContracts);

  useEffect(() => {
    if (role && (role == ROLE.Admin || role == ROLE.Management || ROLE.Contractor)) {
      dispatch(getSignedContracts())
    }
  }, [])

  const onSearchEnter = (queryString) => {
    console.log('Signed Contracts', queryString)
    dispatch(getSignedContracts(queryString))

  }

  return (<div style={{ ...pageStyles, ...styles.container }}>
    <Section
      title={'Signed Contracts'}
      content={
        <>
        {signedContracts && 
        <QueryListView 
        role={role} 
        data={signedContracts}  
        isFetching = {isFetchingSignedContracts}
        onSearchEnter={onSearchEnter}
        searchString = {searchString}
        />}
        <Outlet/>
        </>
      }
    />

</div>)
}


const styles = {
container: {

},
}
