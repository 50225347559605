import React,{useState, useEffect, } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {APP_COLORS} from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import {approveQuery, selectIsQueryApproved, selectIsApprovingQuery} from'../../store/query';

export default function ApproveQueryForm ({id, onClose}){
  let dispatch = useDispatch()
  let [approveRemarks, setApproveRemarks ] = useState('')
  let isQueryApproved = useSelector(state => selectIsQueryApproved(state, id))
  
  let isQueryApproving = useSelector(selectIsApprovingQuery) 

  const handleSubmit = () => {
    dispatch(approveQuery({id:id, approved_remarks : approveRemarks}))
  }

  useEffect(()=>{
    if(isQueryApproved){
      onClose()
    }
  },[isQueryApproved])

  return(
    <div style={{}}>
      <TextField 
        name={'approveRemarks'}
        text = {approveRemarks} 
        type={'text'}
        placeholder = {'Add Approve Remarks'} 
        onTextChange = {(target)=>{console.log(target.name, target.value); setApproveRemarks(target.value)}} 
        onEnter = {()=>{}} 
        autoFocus = {true} 
        style = {{color:APP_COLORS.SECONDARY_TEXT}} 
        multiLine={true}
        // onCancel = {} 
        // onFocus = {}
        // onClear = {} 
      />
      <div style={{display:"flex", justifyContent:"center",paddingTop:30}}>
      {isQueryApproving ? 
      <Loader/>  
       :
       <Button
       label={'Approve'}
       onClick={handleSubmit}
       style={{textAlign:"center",padding:10, fontSize:"16px"}}
       />} 
      </div>
      
    </div>
  )
}