import { useEffect, useState } from "react";
import TextField from "./textField";
import { APP_COLORS } from "../../styles";

export default function BasicSearch({data}) {
    
    const [filteredData, setFilteredData] = useState()
    const [searchText , setSearchText] = useState('');
    const onTextChange = (target) => {
        console.log(target.value)
        setSearchText(target.value)
    };


    useEffect(() => {
        if(!searchText){
            setFilteredData(null)
        }else{
            //make the whole object list -> list of stringified objects
            const stringifiedObjectList = 
            data.map( query => JSON.stringify(query).toLowerCase())
            // filter on the string array. Just like searching a string
            const filteredStringifiedObjectList = 
            stringifiedObjectList.filter(query => query.includes(searchText))

            // revert back the list into object
            const results = 
            filteredStringifiedObjectList.map(item => JSON.parse(item));
            setFilteredData(results)
        }
         
      },[searchText])

    return ( 
        <div style={{overFlowY:'scroll'}}>
        <TextField
        name={'basic_search'}
        text={''}
        type={'text'}
        placeholder={'Search ...'}
        onTextChange={onTextChange}
        onEnter={() => { }}
        autoFocus={true}
        style={{ color: APP_COLORS.PRIMARY_TEXT }}
        autocomplete={"off"}
        // onCancel = {} 
        // onFocus = {}
        // onClear = {} 
        />

        {filteredData && <div style={{
            color: APP_COLORS.PRIMARY_TEXT,
            background:APP_COLORS.META_BACKGROUND, 
            overflow: 'auto',
            maxHeight:'400px', 
            border:'1px solid white'
            }}>
            <div style={{padding:10}}>{filteredData.length+' results'} </div>
            {filteredData.map((item,index) => (
                <div 
                    style={{
                        padding:10, 
                        fontSize:'12px',
                        display:'flex',
                        border:'1px solid #555', gap:20 }}> 
                    <div> { index + 1 } </div>
                    <div style={{width:300}}> 
                        
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}>    address </div> 
                            <div> {item.address} </div> 
                        </div>
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}>occupation</div> <div>{item.occupation}</div> 
                        </div>
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}>condition</div> <div>{item.condition} </div> </div>
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}> area</div> <div> {item.area} </div> </div>
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}>moving tOff</div> <div> {item.moving_toff} </div> </div>
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}>asking</div> <div> {item.asking_price} </div> </div>
                        <div style={{display:'flex', gap:5 }}> 
                            <div style={{width: 70}}>offer</div> <div> {item.price} </div>
                        </div>
                    </div>
                    
                    <div style={{width:250}}>
                        <div>{item.fullname}</div>
                        <div>{item.phone_no} </div>
                        <div>{item.email} </div>
                        <div>{item.mailing_address} </div>
                    </div>

                    <div style={{width:300}}>
                        <div style={{display:'flex', gap:5 }}>  <div style={{width: 70}}> Alias </div> <div> {item.alias} </div> </div>
                        <div style={{display:'flex', gap:5 }}>  <div style={{width: 70}}> AgentName </div> <div> {item.alias} </div> </div>
                        <div style={{paddingTop:20}}>{item.agent_remarks} </div>
                    </div>

                    
                </div>
            ))}
            </div>}
        </div>
        )
}