import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon';

const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export default function  DigitalClock ({ h24 = false }) {

    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const [day, setDay] = useState(0);
    const [pm, setPm] = useState(false);

    useEffect(() => {

        const update = () => {
            
            // let date = new Date()
            let date = DateTime.fromJSDate(new Date(), {zone:'UTC-4'} )
            let hour = date.hour;
            if (!h24) {
                hour = (hour % 12) || 12;
            }
            setHour(hour);
            setMinute(date.minute);
            setSecond(date.second);
            setDay(date.day);
            setPm(date.hour >= 12);
        }

        update();

        const interval = setInterval(() => {
            update();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='clock' >
            <div className='calendar'>
                {
                    days.map((value, index) => (<Word key={value} value={value} hidden={index != day} />))
                }
            </div>
            <div className='row'>
                <div className='hour'>
                    <Number value={hour} />
                    <Word value={':'} />
                    <Number value={minute} />
                    <Word value={':'} />
                    <Number value={second} />
                </div>
                <div className='ampm'>
                    <Word value={'AM'} hidden={pm} />
                    <Word value={'PM'} hidden={!pm} />
                </div>
            </div>
        </div>
    )

}


const Number = ({ value = 0 }) => {
    const result = String(value).padStart(2, "0");
    return (
        <div className='digital'>
            <p>88</p>
            <p>{result}</p>
        </div>
    )
}


const Word = ({ value, hidden = false }) => {
    const getStyle = () => {
        return {
            visibility: hidden ? 'hidden' : 'visible'
        }
    }
    return (
        <div className='digital'>
            <p>{value}</p>
            <p style={getStyle()}>{value}</p>
        </div>
    )
}