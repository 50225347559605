import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { APP_COLORS } from "../styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loading from '../components/common/Loading';
import Card from '../components/dashboard/smallCard'
import '../app.css';

import { logout } from '../store/user';

export default function Dashboardpage() {

  let location = useLocation()
  let params = useParams();
  console.log(location,params)

  return (
    <div >
      <Header />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        background: APP_COLORS.PAGE_BACKGROUND,
      }}>
        <Sidebar />
        <Outlet />
      </div>
    </div>)
}

const styles = {

}
