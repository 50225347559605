import React, { useState, useEffect, useRef } from 'react';
import TextField from './textField';
import algoliasearch from 'algoliasearch';

import { useDispatch, useSelector } from 'react-redux';
import { APP_COLORS } from '../../styles';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Configure,
} from 'react-instantsearch-dom';

import Button from './Button'
import SearchResultPanel from './SearchResultPanel';

import { ROLE } from '../../constants';
import { selectUserData } from '../../store/user';

const SearchContainer = ({ onEnter, searchString, view, role, }) => {
  
  let user = useSelector(selectUserData)
  const dispatch = useDispatch();
  let searchContainerRef = useRef();
  let [pageSize, setPageSize] = useState(20)


  useEffect(()=>{
    if (role == ROLE.InventoryManager) {
      setPageSize(1000)
    }
  },[view])


  // const algoliaClient = algoliasearch(
  //   '4EW76W3NGR',
  //   '5afcd901be15aef0f97c581d007c0365'
  // );

  const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
  );

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
      }
      return algoliaClient.search(requests).then((res)=>{
        
        let hits = res.results[0]?.hits
        
        if(hits.length ){ 
            if (view == 'inventory') {
              if (role == ROLE.InventoryManager) {
                res.results[0].hits = hits.filter( hit => hit.inventory_manager?.id == user.id)
              }
            } else if (view == 'hot_deals') {
              if (role == ROLE.InventoryManager) {
                res.results[0].hits = hits.filter( hit => hit.hot_dealed_by?.id == user.id)
              }
            } else if (view == 'myQueries') {
              res.results[0].hits = hits.filter( hit => hit.added_by?.id == user.id)
         }
        }
        
        return res
      });
    },
  };

  // const searchClient = algoliasearch('4EW76W3NGR', '5afcd901be15aef0f97c581d007c0365');
  // let {results} = useInstantSearch();
  let [searchedValue, setSearchedValue] = useState(searchString)

  let filters;
  if (view == 'inventory') {
    filters = "inventory:true"
  } else if (view == 'queries') {
    filters = "new_query:true"
  } else if (view == 'hot_deals') {
    filters = "hot_deal:true"
  } else if (view == 'approved_queries') {
    filters = "approved:true"
  } else if (view == 'approved_contracts') {
    filters = "contract_approved:true"
  } else if (view == 'sent_contracts') {
    filters = "contract_sent:true"
  } else if (view == 'signed_contracts') {
    filters = "contract_signed:true"
  } else if (view == 'inventory_contracts') {
    filters = "inventory_contract_submitted:true"
  }
  

  const Hit = ({ hit }) => {
    return  (
      <>
        <SearchResultPanel
          key={hit.id}
          item={hit && hit}
          // onOpenImageGallery={(idx, images) => { showLightBox(idx, images) }}
          role={ROLE.Admin}
          view={view}
        // onItemClick={((itemId) => {
        //   setIsSelected({ itemId: itemId, scrollPos: window.scrollY })
        //   setCheckedItem((previous)=>({
        //     ...previous,
        //     [i] : !previous[i] 
        //   }))
        // })}
        // isSelected={queryItem.id == selectedItem?.itemId}
        />
      </>
    )
  }

  return (
    <div style={{
        marginLeft: 10,
        display: 'flex',
      }}
      ref={searchContainerRef}>
      <div>
        <InstantSearch searchClient={searchClient} indexName="queries" >
          <Configure filters={filters}  hitsPerPage={pageSize}  />
          <Pagination />
          <SearchBox 
          className='search_box'/>
          <div style={{
            position: 'absolute',
            top: 320,
            left: 260,
            zIndex: 999,
            width: '70vw',
          }}>
           <Hits
              hitComponent={Hit} className='search_list' />
          </div>
        </InstantSearch>
      </div>
    </div>
  )
}


export default SearchContainer;


