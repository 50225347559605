import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import {getHotDeals, selectHotDeals, selectIsFetchingHotDeals, selectHotDealsSearchString, getQueriesByDateRange} from '../store/query';
import { ROLE } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';
import {  Outlet } from 'react-router-dom';

export default function HotDeals ({}){
  const dispatch = useDispatch();
  let user = useSelector(selectUserData);
  let role = user?.role

  let searchString = useSelector(selectHotDealsSearchString)
  let hotDeals = useSelector(selectHotDeals);
  let isFetching = useSelector(selectIsFetchingHotDeals);

  useEffect(() => {
    if (role && (role == ROLE.Admin || role == ROLE.DispositionManager  )) {
      dispatch(getHotDeals())
    }

    if( role == ROLE.PropertyManager ) {
      dispatch(getHotDeals('',null, user.id))
    }

    if( role && (role ==  ROLE.InventoryManager)){
      dispatch(getHotDeals('', user.id))
    }

  }, [])

  const onSearchEnter = (queryString) => {
    console.log('Hot Deals', queryString)

    if (role && (role == ROLE.Admin  )) {
      dispatch(getHotDeals(queryString))
    }

    if( role == ROLE.PropertyManager ) {
      dispatch(getHotDeals(queryString,null, user.id))
    }

    if( role && (role ==  ROLE.InventoryManager)){
      dispatch(getHotDeals(queryString, user.id))
    }
  }

  //to be done
  // const onDateFilter = (start, end, view ) => {
  //   if(start && end){
  //     dispatch(getQueriesByDateRange({start,end,view, userId:userData?.id, role}))
  //   }else if(start==null && end==null){
  //     console.log(start, end)
  //     if(view == 'myQueries'){
  //       dispatch(getQueriesByUserId(userData?.id, '', role))
  //     }else if(view == 'allQueries'){
  //       dispatch(getAllQueries())
  //     }else if(view == 'queries'){
  //       dispatch(getQueries())
  //     }
  //   }
  // }


  return (<div style={{ ...pageStyles, ...styles.container }}>
    <Section
      title={'Hot Deals'}
      content={
        <>
        {hotDeals && <QueryListView 
        role={role} 
        data={hotDeals}  
        isFetching = {isFetching}
        onSearchEnter = {onSearchEnter}
        searchString = {searchString}
        //onDateFilter={onDateFilter}
        />}
        <Outlet/>
        </>
      }
    />

</div>)
}

const styles = { 
  container:{
   
},
}

