import React from 'react';
import {NavLink} from 'react-router-dom';
import {ROLE} from '../constants';
import { selectIsLoggedOut, selectUserData } from "../store/user";
import {useDispatch, useSelector} from 'react-redux';
import { APP_COLORS } from '../styles';
import Icon from './common/Icon';

function Sidebar () {
  const isLoggedOut = useSelector(selectIsLoggedOut)
  const user = useSelector(selectUserData)
  return (
    !isLoggedOut ? (
    <div style={styles.sidebarContainer}>
      {/* <div style={{margin:'20px 0px', padding:'10px 20px', fontSize:'12px', borderBottom:`1px solid ${APP_COLORS.BORDER_COLOR}`}}>
        
        Menu
      </div> */}
      
      <ul style={styles.list}> 
      <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className="hover" to="/dashboard">
          <li style={styles.listItem} className="hover" >
              Dashboard
           </li>
        </NavLink>

          {user && user.role == ROLE.Admin &&   
            <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/users">
             <li style={styles.listItem} className="hover" >
              Users
           </li>
          </NavLink>
          }

          {
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)}  to="/dashboard/queries">
          <li style={styles.listItem} className="hover">
            Queries
            </li>
          </NavLink>}

          {/* {user && (user.role == ROLE.Admin ) &&   
            <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/approved_queries">
             <li style={styles.listItem} className="hover" >
              Approved Queries
           </li>
          </NavLink>
          }
          {user && (user.role == ROLE.Admin || user.role == ROLE.Contractor  ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/approved_contracts">
            <li style={styles.listItem} className="hover" >
            Pending Contracts
          </li>
          </NavLink>
          }
          {user && (user.role == ROLE.Admin || user.role == ROLE.Contractor  ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/sent_contracts">
            <li style={styles.listItem} className="hover" >
            Contracts Submissions
          </li>
          </NavLink>
          } */}

          {user && (user.role == ROLE.Admin || user.role == ROLE.PropertyManager || user.role == ROLE.InventoryManager || user.role == ROLE.DispositionManager) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/inventory">
          <li style={styles.listItem} className="hover" >
            Inventory
          </li>
          </NavLink>
          }
         
          {user && (user.role == ROLE.Admin || user.role == ROLE.PropertyManager || user.role == ROLE.InventoryManager || user.role == ROLE.DispositionManager ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/hot_deals">
          <li style={styles.listItem} className="hover" >
            Hot Deals 
          </li>
          </NavLink>
          }
          {user && (user.role == ROLE.Admin || user.role == ROLE.PropertyManager || user.role == ROLE.InventoryManager || user.role == ROLE.DispositionManager ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/listings">
          <li style={styles.listItem} className="hover" >
            Listings 
          </li>
          </NavLink>
          }
          {user && (user.role == ROLE.Admin || user.role == ROLE.PropertyManager || user.role == ROLE.DispositionManager ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/inventory_contracts">
          <li style={styles.listItem} className="hover" >
            Inventory Contracts 
          </li>
          </NavLink>
          }
            {user && (user.role == ROLE.InventoryManager ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/csv_inventory">
          <li style={styles.listItem} className="hover" >
            CSV Inventory
          </li>
          </NavLink>
          }
           {user && (user.role == ROLE.Admin || user.role == ROLE.PropertyManager || user.role == ROLE.InventoryManager || user.role == ROLE.DispositionManager  ) &&   
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/csv_hotdeals">
          <li style={styles.listItem} className="hover" >
            CSV HotDeals
          </li>
          </NavLink>
          }

            {user && (user.role == ROLE.Admin || user.role == ROLE.Contractor || user.role == ROLE.PropertyManager ) &&    
          <NavLink style={({isActive})=> isActive ? styles.listItemLink(isActive) : styles.listItemLink(isActive)} className='hover'  to="/dashboard/signed_contracts">
            <li style={styles.listItem} className="hover" >
            Signed Contracts
          </li>
          </NavLink>
          }
          
      </ul>
    </div>
  ): null
  )}
export default Sidebar;

const styles={
  sidebarContainer:{
    minHeight:"calc(100vh - 50px)",
    width:'15vw',
    background:APP_COLORS.SECONDARY_BACKGROUND,
    color:APP_COLORS.SECONDARY_TEXT,
  },
  sidebarTitle:{
    height:"40px",
    fontSize:'20px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    padding:'0px 10px'
  },
  list:{
    borderTop:'1px solid #555',
    padding:'0px',
    paddingLeft:0,
    margin:"0px",
  },
  listItem: {
    listStyle:'none',
    padding:'10px 20px',
    
  },
  listItemLink: (isActive) => ({
    textDecoration:'none', 
    color: isActive ? APP_COLORS.LINK_ORANGE: APP_COLORS.SECONDARY_TEXT,
    fontWeight: isActive && 'bolder',
    fontSize: isActive && '18px'
  })
}
