import React from "react";

const Checkbox = ({ text, checked, onChange, borderColor }) => {
  return (
    <label className="container" style={{...styles.container,}}> 
        <input style={styles.input} type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark" style={styles.checkmark(borderColor)}></span>
        <div>{text} </div>
    </label>
  )
}
export default Checkbox;

const styles = {
    container:{
        display: 'block',
        alignItems:'center',
        position: 'relative',
        paddingLeft: '28px',
        cursor: 'pointer',
        fontSize: '14px',
        userSelect: 'none',
    },
    input:{
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
    },
    checkmark: (borderColor)=>({
        position: 'absolute',
        top: 0,
        left: 0,
        height: '14px',
        width: '14px',
        borderRadius:3,
        border: borderColor ? `1px solid ${borderColor}` : '1px solid #888',
        
    })

}
