import React, { useState, useRef } from 'react';
import { ROLE } from '../../constants';
import { useNavigate, Link } from 'react-router-dom';
import { APP_COLORS } from '../../styles';
import Icon from '../common/Icon';
import Button from '../common/Button';
import Loader from '../common/Loading';
import {
    Highlight,
} from 'react-instantsearch-dom';
import {getLocaleUSDate} from '../../utils';
import { HOME_PAGE_ROUTE } from '../../routes';

export default function SearchResultPanel(
  { item,
    onOpenImageGallery,
    role,
    view,
    isSelected,
    onItemClick,
  }) {

  const navigate = useNavigate();
  let [activeProperty, setActiveProperty] = useState(0);
  let [loading, setLoading] = useState(true)

  let imageContainerRef = useRef()

  function imagesLoaded(parentNode) {
    const imgElements = [...parentNode.current.querySelectorAll("img")];
    for (let i = 0; i < imgElements.length; i += 1) {
      const img = imgElements[i];
      if (!img.complete) {
        return false;
      }
    }
    return true;
  }

  const handleImageChange = () => {
    setLoading({ loading: !imagesLoaded(imageContainerRef) })
  };

  const renderSpinner = () => {
    if (!loading) {
      // Render nothing if not loading
      return null;
    }
    return (
      <Loader />
    );
  }

  return (
    <div style={styles.item(isSelected)}
      onClick={(e) => {
        e.stopPropagation();
        onItemClick(item.id)
        console.log(item.id);
      }}>

      {(view == 'approved_queries') && role != ROLE.VirtualRealtor &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BLUE, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>  Approve Remarks:  {item.approved_remarks && item.approved_remarks} </div>
          <div> Approved By {item.approved_by?.role} @
            {item.approvedDateStr}
          </div>
        </div>
      }
      {(view == 'approved_contracts') && role != ROLE.VirtualRealtor &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BLUE, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>  Remarks:  {item.contract_approved_remarks && item.contract_approved_remarks} </div>
          <div> Contract Approved By {item.contract_approved_by?.firstname + ' ' + item.contract_approved_by?.lastname}  @
            {item.contractApprovedDateStr}
          </div>
        </div>
      }

      {(view == 'sent_contracts') && role != ROLE.VirtualRealtor &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BLUE, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>
            Contract Remarks:  {item.contract_sent_remarks && item.contract_sent_remarks}
          </div>
          <div> Contract Sent by {item.contract_sent_by?.firstname + ' ' + item.contract_sent_by?.lastname} @
            {item.contractSentDateStr}
          </div>
        </div>
      }
      {view == 'signed_contracts' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.GREEN, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>
            Sign Remarks:  {item.contract_signed_remarks && item.contract_signed_remarks}
          </div>
          <div> Contract Signed by {item.contract_signed_by?.firstname + ' ' + item.contract_signed_by?.lastname} @
            {item.contractSignedDateStr}
          </div>
        </div>
      }

      {view == 'inventory' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BORDER_COLOR, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>
            <div> <b> Moved from</b>   {item.inventoried_from} </div>
            <div>  <b> Inventory Remarks: </b>   {item.inventory_remarks && item.inventory_remarks} </div>
          </div>
          <div>
            <div>  <b>Inventory Manager </b>   {item.inventory_manager && item.inventory_manager.name} </div>
          </div>
          <div> <b> Inventoried by</b>  {item.inventoried_by?.firstname + ' ' + item.inventoried_by?.lastname} @
            {item.inventoriedDateStr}
          </div>
        </div>
      }

      {view == 'hot_deals' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.RED, color: "white", fontStyle: "italic", }}>
          <div>  <b>Property Manager </b>   {item.property_manager ? item.property_manager.name : 'NA'} </div>

          <div> <b> Moved By</b>  {item.hot_dealed_by?.firstname + ' ' + item.hot_dealed_by?.lastname} @
            {item.inventoryHotdealedDateStr}
          </div>


        </div>
      }
      {view == 'inventory_contracts' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.GREEN, color: 'white', fontStyle: "italic", }}>
          <div>
            <div>  <b>Remarks: </b>   {item.inventory_contract_submitted_remarks && item.inventory_contract_submitted_remarks} </div>
          </div>

          <div> <b> Moved By</b>  {item.inventory_contract_submitted_by?.firstname + ' ' + item.inventory_contract_submitted_by?.lastname} @
            {item.inventoryContractSubmittedDateStr}
          </div>
        </div>
      }

      <div style={styles.header}>
        <header style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: 10 }}>
          <div style={{ fontSize: '12px', fontWeight: 'bolder' }}>
            Properties
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: 5 }}>
            {item.properties?.length > 1 && item.properties?.map((item, idx) => (
              <div
                key={idx}
                onClick={() => { setActiveProperty(idx) }}
                style={{
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  padding: 5,
                  height: '10px',
                  width: '10px',
                  background: activeProperty == idx ? APP_COLORS.LINK_ORANGE : 'none',
                  border: `1px solid ${APP_COLORS.LINK_ORANGE}`,
                  borderRadius: '50%',
                  textAlign: 'center',
                  color: activeProperty == idx ? 'white' : '',
                  cursor: 'pointer',
                  fontSize: '9px'
                }}>
                {idx + 1}
              </div>
            ))
            }
          </div>
        </header>
        <div style={{display:'flex', gap:10}}>
          <Link
          to={ HOME_PAGE_ROUTE+'/query/' + item.id}
          >
          <Button 
          label={'View'} 
          icon={{family:'BootstrapIcons',name:'eye-fill'}}
          style={{fontSize:'9px', padding: '2px 5px'}}
          // onClick={()=>{
          //   navigate('/dashboard/query/' + item.id)
          // }}
          />
          </Link>
        <div>
          {getLocaleUSDate(new Date(item.created))}
        </div>
        </div>
      </div>

      <div style={styles.body}>
        <section style={{ paddingTop: '5px', width: '60%' }}>
          {item.properties?.length && item.properties.filter((p, i ) => { return i == activeProperty }).map((p,i) => (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", gap: 10 }}>
              <div style={{ width: '80%' }}>

                <div style={styles.pane}> 
                  <div style={styles.subHeading}> Address </div>
                    <div style={styles.content}>
                    <Highlight  attribute={`properties[${i}].address`} hit={item} tagName="mark" />
                        {/* {p.address} */}
                    </div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> State </div>
                  <div style={styles.content}>
                  <Highlight  attribute={`properties[${i}].state.label`} hit={item} tagName="mark" />
                  {/* <div style={styles.content}>{p.state.label}</div> */}
                  </div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Condition </div>
                  <div style={styles.content}>
                  <Highlight  attribute={`properties[${i}].condition`} hit={item} tagName="mark" />
                  {/* <div style={styles.content}>{p.condition}</div> */}
                  </div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Area of Property </div>
                  <div style={styles.content}>{p.area}</div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Occupation </div>
                  <div style={styles.content}>{p.occupation}</div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Moving TimeOff </div>
                  <div style={styles.content}>{p.moving_toff}</div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Offered Price </div>
                  <div style={styles.content}>{p.price}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Client Demand  </div>
                  <div style={styles.content}>{p.asking_price}</div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Zillow Price </div>
                  <div style={styles.content}>{p.zestimate}</div>
                </div>

                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Links </div>
                    <div style={{ width: '100%' }}>
                      {p.links?.length && p.links.map(link => (
                        <div style={styles.content}>
                          <a href={link} style={{ color: APP_COLORS.BUTTON_BACKGROUND, opacity: 0.8, width: '100%' }} target='_blank'>{link} </a>
                        </div>
                      ))}
                    </div>
                  </div>
              </div>
              <div ref={(element) => imageContainerRef = element}
                style={{
                  width: '10%',
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: 10,
                  height: '180px',
                  overflow: 'scroll'
                }}>
                {p.images?.map((img, idx) => (
                  <div>
                    <img key={img}
                      // onLoad={() => handleImageChange()} 
                      style={{ width: '50px', height: '50px', marginRight: '10px' }}
                      src={img} onClick={() => { onOpenImageGallery(idx, p.images) }} />
                  </div>
                ))}
              </div>
            </div>
          )) 
          }
        </section>

        <section style={{ paddingTop: 10, width: '40%', }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }}>
              <div>
                <div style={styles.heading}>CLIENT INFO</div>
                <div style={{ paddingLeft: 10 }}>
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Phone </div>
                    <Highlight  attribute="phone_no" hit={item} tagName="mark" />
                    {/* <div style={styles.content}>{item.phone_no ? item.phone_no : 'NA'} </div> */}
                  </div>
                  <div style={styles.pane}>
                    <div style={styles.subHeading}>Name</div>
                    <Highlight  attribute="fullname" hit={item} tagName="mark" />
                    {/* <div style={styles.content}>{item.fullname ? item.fullname : 'NA'}</div> */}
                  </div>
                  {(role !== ROLE.TeamLeads) &&
                    <div style={styles.pane}>
                      <div style={styles.subHeading}>Email</div>
                      <Highlight  attribute="email" hit={item} tagName="mark" />
                      {/* <div style={styles.content}>{item.email ? item.email : 'NA'} </div> */}
                    </div>
                  }
                  {(role !== ROLE.TeamLeads) &&
                    <div style={styles.pane}>
                      <div style={styles.subHeading}>Mailing Address</div>
                      <Highlight  attribute="mailing_address" hit={item} tagName="mark" />
                      {/* <div style={styles.content}>{item.mailing_address ? item.mailing_address : 'NA'} </div> */}
                    </div>
                  }
                </div>
              </div>
            <div>
              <div style={styles.heading}>AGENT INFO</div>
              <div style={{ paddingLeft: 10 }}>

                
                  <div style={styles.pane}>
                    <div style={styles.subHeading}>Agent Alias</div>
                    {/* <Highlight  attribute="alias" hit={item} tagName="mark" /> */}
                    <div style={styles.content}>{item.alias} </div>
                  </div>
                
                <div style={styles.pane}>
                  <div style={styles.subHeading}>Agent Name</div>
                  {/* <Highlight  attribute="added_by.firstname" hit={item} tagName="mark" />
                  <Highlight  attribute="lastname" hit={item} tagName="mark" /> */}
                  <div style={styles.content}>{ item.added_by?.firstname} {item.added_by?.lastname} </div>
                </div>

                <div style={styles.pane}>
                <div style={styles.subHeading}>Agent Role</div>
                <div style={styles.content}>{item.added_by?.role}</div>
                </div>
                <div style={styles.pane}>
                <div style={styles.subHeading}>Office</div>
                <div style={styles.content}>{item.office}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {role != ROLE.VirtualRealtor &&
        <section style={{
            padding: '15px 5px',
            width: '20%',
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            gap: 10
         }}>
            {role != ROLE.TeamLeads &&
              <>
                <Icon name='check-circle' family='BootstrapIcons' styles={styles.statusIcon(item.approved || item.      contract_approved || item.contract_sent || item.contract_signed)} />
                <Icon name='receipt' family='BootstrapIcons' styles={styles.statusIcon(item.contract_sent || item.contract_signed)} />
                <Icon name='file-signature' family='FontAwesome5' styles={styles.statusIcon(item.contract_signed)} />
                <Icon name='folder2-open' family='BootstrapIcons' styles={styles.statusIcon(item.inventory || item.hot_deal)} />
                {/* {item.inventory && <div> {item.inventory_manager.name} </div>} */}
                <Icon name='hotjar' family='FontAwesome5Brands' styles={styles.statusIcon(item.hot_deal || item.inventory_contract_submitted)} />
              </>
            }
          </section>}
      </div>
      {view == 'hot_deals' && item.hot_dealed_remarks &&
        <div style={{ padding: 10, marginBottom: '10px' }}>
          <div style={{ fontSize: '14px', paddingBottom: 5, fontWeight: 'bolder', }}>{'Inventory Manager Comments:'}  </div>
          <div style={{ fontSize: '13px', lineHeight: 1.3, whiteSpace: 'pre-wrap', }}> {item.hot_dealed_remarks} </div>
        </div>
      }
    </div>
  )
}

const styles = {
  item: (isSelected) => ({
    background: APP_COLORS.SECONDARY_BACKGROUND,
    width: '100%',
    minHeight: '200px',
    boxSizing: "border-box",
    color: APP_COLORS.PRIMARY_TEXT,
    margin: '5px 5px',
    fontSize: "12px",
    borderRadius: '5px',
    border: isSelected ? `1px solid ${APP_COLORS.LINK_ORANGE}` : '',

  }),
  header: {
    height: '30px',
    width: '100%',
    padding: '5px 10px',
    boxSizing: "border-box",
    borderBottom: `1px solid ${APP_COLORS.PAGE_BACKGROUND}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: "center",
    color: APP_COLORS.SECONDARY_TEXT,
    gap: 20
  },
  body: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    alignContent: "center",
    padding: '5px 10px',
    boxSizing: "border-box",
    gap: 10,
  },
  infoHeader: (isTop) => ({
    minHeight: '30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "space-between",
    borderTopLeftRadius: isTop && '5px',
    borderTopRightRadius: isTop && '5px',
    padding: "5px 10px",
    boxSizing: "border-box",
  }),
  heading: {
    paddingBottom: 5,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    color: APP_COLORS.SECONDARY_TEXT
  },
  subHeading: {
    minWidth: '120px',
    fontSize: '11px',
    fontWeight: 'bolder',
    color: APP_COLORS.PRIMARY_TEXT
  },
  content: {
    marginLeft: 5,
    whiteSpace: "no-wrap",
    overflow: ' hidden',
    textOverflow: 'ellipsis'
  },
  optionIconStyle: {
    fontSize: '18px',
    color: APP_COLORS.LINK_ORANGE,
  },
  pane: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    // borderBottom: `1px solid ${APP_COLORS.PAGE_BACKGROUND}`,
    padding: 1,
  },
  actionPane: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: "center",
    gap: 2,
  },
  statusIcon: (achieved) => ({
    color: achieved ? "#1AA260" : APP_COLORS.PRIMARY_TEXT,
    fontSize: achieved ? "26px" : "20px",
    cursor: 'default'
  })
}