import { getUsTimestamp, getLocaleUSDate } from "../utils";

export const transformOldQueryData = (items) => {
  return {  data: items }
}

export const transformData = ( items ) => {
    if (!items || items.length == 0) {
        return {
          data: [],
        }
      }

  let transformedData = [];
  const count = items.length;
  
  for (let i = 0; i < count; i++) {
      let item = { ...items[i] };
     
      if(item.created.local){
       item.timestamp = getUsTimestamp(item.created.time)
       item.dateStr = getLocaleUSDate(item.created.time)
      }else{
        if(!item.timestamp || !item.dateStr ){

          item.timestamp = getUsTimestamp(item.created.toDate())
          item.dateStr = getLocaleUSDate(item.created.toDate())
        }
      }

      if(item.approved){
        if(item.approve_date.local){
          item.approvedTimestamp = getUsTimestamp(item.approve_date.time)
          item.approvedDateStr = getLocaleUSDate(item.approve_date.time)
         }else{
           if(!item.approvedTimestamp){
             item.approvedTimestamp = getUsTimestamp(item.approve_date.toDate())
             item.approvedDateStr = getLocaleUSDate(item.approve_date.toDate())
           }
         }
      }

      if(item.contract_approved){
        if(item.contract_approved_date.local){
          item.contractApprovedTimestamp = getUsTimestamp(item.contract_approved_date.time)
          item.contractApprovedDateStr = getLocaleUSDate(item.contract_approved_date.time)
         }else{
          item.contractApprovedTimestamp = getUsTimestamp(item.contract_approved_date.toDate())
          item.contractApprovedDateStr = getLocaleUSDate(item.contract_approved_date.toDate())
         }
      }

      if(item.contract_sent){
        if(item.contract_sent_date.local){
          item.contractSentTimestamp = getUsTimestamp(item.contract_sent_date.time)
          item.contractSentDateStr = getLocaleUSDate(item.contract_sent_date.time)
         }else{
          item.contractSentTimestamp = getUsTimestamp(item.contract_sent_date.toDate())
          item.contractSentDateStr = getLocaleUSDate(item.contract_sent_date.toDate())
         }
      }
      
      if(item.contract_signed){
        if(item.contract_signed_date.local){
          item.contractSignedTimestamp = getUsTimestamp(item.contract_signed_date.time)
          item.contractSignedDateStr = getLocaleUSDate(item.contract_signed_date.time)
         }else{
          item.contractSignedTimestamp = getUsTimestamp(item.contract_signed_date.toDate())
          item.contractSignedDateStr = getLocaleUSDate(item.contract_signed_date.toDate())
         }
      }

      if(item.inventory){
        if(item.inventoried_date.local){
          item.inventoriedTimestamp = getUsTimestamp(item.inventoried_date.time)
          item.inventoriedDateStr = getLocaleUSDate(item.inventoried_date.time)
         }else{
          item.inventoriedTimestamp = getUsTimestamp(item.inventoried_date.toDate())
          item.inventoriedDateStr = getLocaleUSDate(item.inventoried_date.toDate())
         }
      }

      if(item.hot_deal){
        if(item.hot_dealed_date.local){
          item.inventoryHotdealedTimestamp = getUsTimestamp(item.hot_dealed_date.time)
          item.inventoryHotdealedDateStr = getLocaleUSDate(item.hot_dealed_date.time)
         }else{
          item.inventoryHotdealedTimestamp = getUsTimestamp(item.hot_dealed_date.toDate())
          item.inventoryHotdealedDateStr = getLocaleUSDate(item.hot_dealed_date.toDate())
         }
      }
      
      if(item.listing) {
        if(item.listing_date.loca){
          item.listingTimeStamp = getUsTimestamp(item.listing_date.time)
          item.listingDateStr = getLocaleUSDate(item.listing_date.time)
        }else{
          item.listingTimeStamp = getUsTimestamp(item.listing_date.toDate())
          item.listingDateStr = getLocaleUSDate(item.listing_date.toDate())
        }
      }

      if(item.inventory_contract_submitted){
        if(item.inventory_contract_submitted_date.local){
          item.inventoryContractSubmittedTimestamp = getUsTimestamp(item.inventory_contract_submitted_date.time)
          item.inventoryContractSubmittedDateStr = getLocaleUSDate(item.inventory_contract_submitted_date.time)
         }else{
          item.inventoryContractSubmittedTimestamp = getUsTimestamp(item.inventory_contract_submitted_date.toDate())
          item.inventoryContractSubmittedDateStr = getLocaleUSDate(item.inventory_contract_submitted_date.toDate())
         }
      }

      item.searchStr =  setSearchString(item) 
      transformedData.push(item);
    } 
    return {  data: transformedData };
}

export const transformCSVData = ( items ) => {
  if (!items || items.length == 0) {
    return {
      data: [],
    }
  }
let rowsData = [];

let columns = [...columnOrders]
items.forEach( (item,index) =>{
  let rowData = {
    index: {
      value: index + 1,
    }
  }
    for(let key in item){
      let value = item[key] || "-"
      let cellData = {
        value,
        originalValue: value,
      }
      if(key == 'hot_deal_on'){
        if(value.local ){
          value = getLocaleUSDate(value.time)
        }
      }
      
      rowData[key] = cellData
      
      let exists = columns.find(c => c.label == key)
        if (!exists) {
          columns.push({
            label: key,
            width: 180,
          })
        }
    }
    rowsData.push(rowData)
})

console.log('COLUMNS', columns)
console.log('ROW DATA' , rowsData )
 

return { columns, rowsData }
}


let columnOrders = [
  {
    label: "index",
    width: 60,
  },
  {
    label: "First Name",
    width: 100,
  },
  {
    label: "Last Name",
    width: 100,
  },
  {
    label: "Street Address",
    width: 220,
  },
  {
    label:"City",
    width:80,
  },
  {
    label: "State",
    width: 60,
  },
  {
    label:'Zip',
    width: 60
  },

  {label:'Cell',
    width:120
  },
  {label:'Cell 2',
    width:120
  },
  {
    label:'Cell 3',
    width:120,
  },
  {
    label: "Landline",
    width: 120,
  },
  {
    label: "Landline 2",
    width: 120,
  },
  {
    label: "Landline 3",
    width: 120,
  },
  {
    label:"Phone",
    width : 120,
  },
  {
    label:'Mail Street Address',
    width:200,
  },
  
 
  {
    label:'Email 1',
    width: 220
  },
  {
    label:'Email 2',
    width: 220
  },

  

]

export const transformCommentsData = ( items ) => {
  if (!items || items.length == 0) {
    return {
      data: [],
    }
  }
let transformedData = [];
const count = items.length;

for (let i = 0; i < count; i++) {
  let item = { ...items[i] };
    if(!item.timestamp || !item.dateStr ){
      item.timestamp = getUsTimestamp(item.created.toDate())
      item.dateStr = time_ago (item.created.toDate())
      // item.dateStr = getLocaleUSDate(item.created.toDate())
    }
    transformedData.push(item);
}
return {  data: transformedData };
}

let uniqueWords = []

const setSearchString = ( item ) => {
        let phoneNumber = item.phone_no;
        let lowerCaseName = item.fullname && item.fullname.toLowerCase();
        let dateStr = item.dateStr && item.dateStr

        let searchStr = (phoneNumber || "")
          + " " +  (lowerCaseName || "")
          + " " +  (dateStr || "")
          + " " + ((item.email && item.email.toLowerCase()) || "")
          + " " + ((item.id && item.id.toLowerCase()) || "")
          + " " + ((item.mailing_address && item.mailing_address.toLowerCase()) || "")
          // + " " + ((roll.date && roll.date.toLowerCase()) || "")
        //   + " " + ((item.created && item.created.toLowerCase()) || "" )
      
        item.properties.forEach(p => {

          let propertyString = (p.address && p.address.toLowerCase() || "")
          + " "+ ((p.state && p.state.abbr.toLowerCase()) || "")
          + " "+ ((p.state && p.state.label.toLowerCase()) || "")
          + " "+ ((p.asking_price && p.asking_price.toLowerCase()) || "")
          + " "+ ((p.price && p.price.toLowerCase()) || "")
          + " "+ ((p.zestimate && p.zestimate.toLowerCase()) || "")
          + " "+ ((p.area && p.area.toLowerCase()) || "")
          + " "+ ((p.condition && p.condition.toLowerCase()) || "")
          + " "+ ((p.moving_toff && p.moving_toff.toLowerCase()) || "" )
          + " "+ ((p.occupation && p.occupation.toLowerCase()) || "" )

          searchStr = searchStr + " " + propertyString
        })

        let addedByStr = ((item.added_by.firstname.toLowerCase())) 
        + " " +  ((item.added_by.lastname.toLowerCase())) 
        + " " +  ((item.added_by.email.toLowerCase())) 
        + " " +  ((item.added_by.role.toLowerCase())) 

        searchStr = searchStr + " "+ addedByStr
        
        let words = searchStr.split(" ");
        for (let i = 0; i < words.length; i++) {
          if (uniqueWords.indexOf(words[i]) == -1) {
            uniqueWords.push(words[i])
          }
        }
        return searchStr;
    }


export const getSearchResults = (queryString, data ) => {
    let counts = []
    let searchValue = queryString?.toLowerCase()
    let words = searchValue.split(" ");
    words.forEach(word => {
      if (word.length > 0) {
        data.forEach(item => {
          let searchIndex = item.searchStr.indexOf(word)
          while (searchIndex !== -1) {
            let existingCount = counts.find(c => c.id === item.id)
            if (!existingCount) {
              counts.push({
                id: item.id,
                count: 0,
                index: searchIndex,
              })
            } else {
              existingCount.count++;
              if (searchIndex < existingCount.index) {
                existingCount.index = searchIndex
              }
            }
            searchIndex = item.searchStr.indexOf(word, searchIndex + 1)
          }
        })
      }

    })
    counts = counts.sort((a, b) => {
      if (b.count > a.count) {
        return 1;
      }
      if (b.count < a.count) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0
    })
    return counts.map(c => data.find(r => r.id === c.id))

}


export const time_ago = (time) => {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 min ago', '1 minute from now'], // 60*2
    [3600, 'mins', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds < 60) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0,
    format;
  while (format = time_formats[i++])
    if (seconds < format[0]) {
      if (typeof format[2] === 'string')
        return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
}

  
    

