
export const ROLE = {
  Admin : 'admin' ,
  // Admin : 'dev',
  PropertyManager : 'property_manager',
  DispositionManager : 'dispo_manager',
  InventoryManager:'inventory_manager',
  Management : 'management',
  TeamLeads: 'team_leads',
  Contractor : 'contractor',
  VirtualRealtor : 'virtual_realtor',
}

export const TABS = {
  QUERY: { id: 'queries', label: 'Queries' },
  ALLQUERIES: { id: 'allqueries', label: 'All Queries' },
  MYQUERIES : { id: 'myqueries', label: 'My Queries' },
  OLDQUERIES : { id : 'oldqueries', label:'Old Queries'},
  CSVQUERIES : {id:'csvqueries', label : 'CSV Queries'}
}
