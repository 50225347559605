import { app, db , auth,  storage} from './config';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import {collection, query, where, getDocs, doc, setDoc,  getDoc, orderBy , arrayUnion, increment, endAt,limit, startAt, addDoc } from "firebase/firestore";
import { ROLE } from '../../constants';



export async function authenticateUser(data) {
  let {email, password} = data;
  
  return signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {    
    return {user:userCredential.user}
  })
  .catch((error) => {
    console.log(error)
    const errorCode = error.code;
    const errorMessage = error.message;
    return {error:{code:errorCode,message:errorMessage}}
  })
}

export async function logout(){
  auth.signOut();
}

export const registerWithEmailAndPassword = async (userCredentials) => {
  let {email, password, firstname, lastname, role, office } = userCredentials;
  try {
    const res = await createUserWithEmailAndPassword ( auth, email, password);
    const user = res.user;

    setDocById({collection:'users', data:{
      id: user.uid,
      firstname,
      lastname,
      authProvider: "local",
      email,
      role, 
      office,
    }})

    if(role == ROLE.InventoryManager){
      const inventoryDataRef = doc ( db, "stats", "inventory_management" );
      await setDoc( inventoryDataRef, {
        inventory_managers: arrayUnion({ id: user.uid, name: firstname +' '+ lastname })
      }, {merge:true});
    }
    if(role == ROLE.PropertyManager){
      const pmsRef = doc(db, "stats", "inventory_management");
      await setDoc(pmsRef, {
        property_managers: arrayUnion({ id: user.uid, name: firstname +' '+ lastname })
      }, {merge:true});
    }
    if(role == ROLE.DispositionManager){
      const dmsRef = doc(db, "stats", "inventory_management");
      await setDoc(dmsRef, {
        dispo_managers: arrayUnion({ id: user.uid, name: firstname +' '+ lastname })
      }, {merge:true});
    }
    return {
      id: user.uid,
      firstname,
      lastname,
      authProvider: "local",
      email,
      role,
      office,
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


export async function getDocById({table, id }){
  const docRef = doc(db, table, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

}

export async function addDocument ({collectionName, data}){
  
  const docRef = await addDoc(collection(db, collectionName), data);
  return docRef.id
}

export async function getDocIdBeforeAdd({collectionName}){
  return  doc(collection(db, collectionName));
}


export async function setDocById({collection,data}) {
  const ref = doc(db, collection, data.id);
  await setDoc ( ref, data, {merge:true} );
  return data.id
}

export async function updateCountById({collection,id, field}) {
  const ref = doc(db, collection, id);
  await setDoc(ref, 
    {
      [field]: increment(1)
    }, 
    {merge:true});
}


export async function getAllDocs ({collectionName, wheres, order, limitAt , endsAtDate, startsAtDate }){
  let querySnapshot;
  let whereConditions = []
  
  if(!!wheres){
    for(let i = 0; i< wheres.length ; i++){
      let wher = wheres[i]
      whereConditions.push( where( wher[0], wher[1], wher[2]) )
    }
    let q ;
    if(endsAtDate){
      q = query(collection(db, collectionName), ...whereConditions , orderBy(order,'desc'), endAt(endsAtDate), limit(limitAt) ) ;
    }else if(limitAt){
      q = query(collection(db, collectionName), ...whereConditions , orderBy(order,'desc'), limit(limitAt) ) ;
    }else if(order){
      q = query(collection(db, collectionName), ...whereConditions , orderBy(order,'desc'), ) ;
    }else {
      q = query(collection(db, collectionName), ...whereConditions , ) ;
    }
    querySnapshot = await getDocs(q)
  }
  else if(endsAtDate){
    const q = query(collection(db, collectionName), ...whereConditions, orderBy( order,'desc' ), endAt(endsAtDate));
    querySnapshot = await getDocs(q)
  }
  else if(order){
    const q = query(collection(db, collectionName), orderBy(order,'desc'), limit(limitAt));
    querySnapshot = await getDocs(q)
  }else{
    const q = query(collection(db, collectionName), );
    querySnapshot = await getDocs(q)
  }
  let data = []

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    data.push(doc.data())
  });
  return data;
}




