import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import { ToastContainer, toast } from 'react-toastify';
import PropertyView from '../components/addQuery/PropertyView';
import AddPropertyInfo from '../components/addQuery/AddPropertyInfo';
import TextField from '../components/common/textField';
import Loader from '../components/common/Loading';
import Button from '../components/common/Button';
import { APP_COLORS } from '../styles';
import { validate, checkForErrors, } from '../utils';
import { editQuery, selectIsEditingQuery, selectIsUploadingImages, selectIsQueryEdited, resetIsEdited } from '../store/query';


export default function EditQuery() {

  let params = useParams();
  let { state } = useLocation();

  console.log(params.id, state);
  let item = state.item;
  let navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
    return () => {
      dispatch(resetIsEdited(false))
    }
  }, [])

  let isEditingQuery = useSelector(selectIsEditingQuery)
  let isUploadingImages = useSelector(selectIsUploadingImages)
  let isQueryEdited = useSelector(selectIsQueryEdited)

  let [queryData, setQueryData] = useState({
    phone_no: item.phone_no,
    fullname: item.fullname,
    email: item.email,
    mailing_address: item.mailing_address,
  });

  let [properties, setProperties] = useState(item.properties)
  let [lightBoxImage, setLightBoxImage] = useState(null)
  let [showForm, setShowForm] = useState(false)

  let [errors, setErrors] = useState({})
  let errorRef = useRef(errors);


  useEffect(() => {
    if (isQueryEdited)
      navigate('/dashboard/' + state.view)
  }, [isQueryEdited])

  const { openLightbox, closeLightbox } = useLightbox()
  const options = {
    settings: {
      autoplaySpeed: 0,
    },
    button: {
      showAutoplayButton: false
    }
  }

  useEffect(() => {
    if (!!lightBoxImage) {
      openLightbox(lightBoxImage.index);
    }
  }, [lightBoxImage])

  const showLightBoxImages = (previewImages, imgIdx) => {
    setLightBoxImage({ images: previewImages, index: imgIdx })
  }

  const handleChange = (target) => {
    const { name, value } = target;
    let returnedErrors = validate(name, value)
    // console.log(returnedErrors[name])
    setErrors(errors => ({
      ...errors,
      [name]: returnedErrors[name]
    }));
    setQueryData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const addOrEditProperty = (property, index) => {
    let existingProperties = [...properties]
    if (index !== null) {
      existingProperties[index] = property;
      setProperties(existingProperties);
      setShowForm(false)
    } else {
      existingProperties.push(property)
      setProperties(existingProperties)
      setShowForm(false)
    }
  }
  const removeProperty = (index) => {
    let existingProperties = [...properties]
    existingProperties.splice(index, 1)
    setProperties(existingProperties);
  }


  const handleSubmit = () => {
    for (const key in queryData) {
      let returnedErrors = validate(key, queryData[key])
      errorRef.current[key] = returnedErrors[key]
      setErrors(errors => ({
        ...errors,
        [key]: returnedErrors[key]
      }));
    }
    let hasErrors = checkForErrors(errorRef.current)
    if (!hasErrors) {
      if (properties.length) {
        let data = { ...queryData, properties: properties, id: item.id }
        console.log(data)
        dispatch(editQuery(data, state.view,))
      } else {
        toast.error('Add a property!')
      }
    } else {
      toast.error('Fill Required Fields!')
    }
  }



  return (
    <div style={styles.container}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: APP_COLORS.SECONDARY_TEXT
      }}>
        <h3>Edit Query</h3>
        <div>Status: <span style={{ color: APP_COLORS.SIGNEDCONTRACT }}> {state.view} </span></div>
      </div>

      <div style={styles.formContainer}>
        <div style={{ margin: 10, borderBottom: '1px solid rgba(0,0,0,0.3)' }}>
          <h4> Client Info </h4>

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer} >
              <div style={{ padding: '10px 0px' }}> Phone Number <sup style={{ color: APP_COLORS.LINK_ORANGE }}>*</sup> </div>
              <TextField
                name={'phone_no'}
                text={queryData.phone_no}
                type={'text'}
                placeholder={'Enter Phone number'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
                disabled={true}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.phone_no}</span>
            </div>

            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Full Name   </div>
              <TextField
                name={'fullname'}
                text={queryData.fullname}
                type={'text'}
                placeholder={'Enter Full Name'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.fullname}</span>
            </div>
          </div>

          <div style={styles.fieldPanel}>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Email  </div>
              <TextField
                name={'email'}
                text={queryData.email}
                type={'text'}
                placeholder={'Enter email address'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.email}</span>
            </div>
            <div style={styles.fieldContainer}>
              <div style={{ padding: '10px 0px' }}> Mailing Address </div>
              <TextField
                name={'mailing_address'}
                text={queryData.mailing_address}
                type={'text'}
                placeholder={'Enter Mailing Address'}
                onTextChange={handleChange}
                onEnter={() => { }}
                autoFocus={true}
                style={{ color: APP_COLORS.PRIMARY_TEXT }}
                autocomplete={"off"}
              // onCancel = {} 
              // onFocus = {}
              // onClear = {} 
              />
              <span style={styles.errors}>{errors.mailing_address}</span>
            </div>
          </div>
        </div>

        <div style={{ padding: 20, }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4> Properties </h4>
            {!showForm &&
              <Button
                label={'+ Add a Property'}
                onClick={() => { setShowForm({ show: true, index: null }) }}
                style={{ textAlign: "center", width: '200px', fontSize: '16px', float: 'right' }}
              />
            }
          </div>

          {!showForm && properties?.length > 0 ?
            <div> {properties.map((p, i) => (
              <PropertyView
                key={i}
                idx={i}
                data={p}
                onEdit={(idx) => setShowForm({ show: true, index: idx })}
                onDelete={(idx) => removeProperty(idx)}
                onImagePreview={(previewIndex) => { showLightBoxImages(p.previewImages, previewIndex) }}
              />
            ))}
            </div>

            :
            null}
        </div>
        {showForm.show &&
          <AddPropertyInfo
            index={showForm.index}
            data={properties[showForm.index]}
            onAddorEdit={addOrEditProperty}
            onClose={() => { setShowForm(false) }}
            images={true}
            onImagePreview={(previewImages, previewIndex) => { showLightBoxImages(previewImages, previewIndex) }}
          />
        }

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          {isEditingQuery ?
            <><span> Updating Data </span><Loader /> </> :
            isUploadingImages ?
              <> <span> Uploading Images...</span> <Loader /> </>
              :
              <Button
                label={'Update Query'}
                onClick={handleSubmit}
                style={{ textAlign: "center", width: '50%' }}
              />
          }
        </div>
      </div>
      <SRLWrapper elements={lightBoxImage?.images} options={options} />
      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    padding: 20,
    color: APP_COLORS.PRIMARY_TEXT
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: '20px'
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: APP_COLORS.SECONDARY_BACKGROUND,
    padding: ' 20px 20px',
    borderRadius: 5,
  },
  fieldPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 30
  },
  fieldContainer: {
    width: '100%',
  },
  errors: {
    padding: 5,
    fontSize: '12px',
    color: 'red',
  },

}

