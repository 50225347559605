import { APP_COLORS } from '../../styles';
import Button from './Button';
import Icon from './Icon';

function Section({ title, rightButtons, content }) {
  return (
    <div
      style={{
        background: APP_COLORS.SECONDARY_BACKGROUND,
        borderRadius: "5px",
        padding: 20,
        // boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
      }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0px 30px',
      }}>
        <div style={{
          fontSize: 16,
          color: APP_COLORS.SECONDARY_TEXT,
          letterSpacing: 1,
          whiteSpace: 'nowrap',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginRight: '10px'
        }}>{title}</div>
        <div style={{
          borderBottom: `1px solid ${APP_COLORS.BORDER_COLOR} `,
          width: '100%',
        }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rightButtons && rightButtons.length > 0 && rightButtons.map((button, idx) => ((button && button.onClick) ? (
            <Button
              key={idx}
              style={{ margin: '0px 5px', fontSize: '14px', padding: '10px', width: '100px' }}
              onClick={button.onClick}
              label={button.label} />) : null))}

        </div>
      </div>
      {content}
    </div>
  )

}

export default Section;