import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {  APP_COLORS } from "../styles";
import TextField from '../components/common/textField';
import Button from '../components/common/Button';
import Loading from '../components/common/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../app.css';
import logo from '../assets/logo.png';



import { login, selectIsLogginIn, selectLoginError, selectIsLoggedIn } from '../store/user';

function Loginpage() {

  const dispatch = useDispatch();
  let navigate = useNavigate();
  let isLoggingIn = useSelector(selectIsLogginIn);
  let loginError = useSelector(selectLoginError);
  let isLoggedIn = useSelector(selectIsLoggedIn);
  let [formData, setFormData] = useState({
    email: '',
    password: '',
    errors: {}
  })
  let location  = useLocation()
  const handleChange = (target) => {
    const { name, value } = target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    })
    )
  }

  const handleSubmit = () => {
    dispatch(login(formData))
  }

  useEffect(() => {
    if (isLoggedIn) {
      console.log(location.pathname)
      if(location.pathname === '/' || location.pathname === "/login" )
      navigate('/dashboard');
    }
    if (!isLoggedIn && loginError) {
      console.log(loginError)
      if(loginError.code.includes('the-provided-api-key-has-an-ip-address-restriction.')){
        toast.error('There is something wrong, please contact support.')  
      }else{
        toast.error(loginError.code)
      }
    }
  }, [isLoggedIn, loginError])


  return (
    <div style={styles.container }>
       
      <form style={styles.formContainer}>
       <div className={'logo-rotate'} style={{display:'flex', justifyContent:'center'}}>
        <div style={{maxWidth:'200px',}}>
          <img src={logo} style={{width:'100%', width:'100%'}} />
        </div>
        
       </div>
        <div style={styles.formHeader}>
          Log in
        </div>
        <section style={styles.formBody}>
          <TextField
            name={'email'}
            text={formData.email}
            placeholder={'email'}
            onEnter={handleSubmit}
            // onCancel = {} 
            // style = {} 
            onTextChange={handleChange}
            autoFocus={true}
          // onFocus = {}
          // onClear = {} 
          />
          <TextField
            name={'password'}
            text={formData.password}
            type={'password'}
            placeholder={'password'}
            onEnter={handleSubmit}
            // onCancel = {} 
            // style = {} 
            onTextChange={handleChange}
            autoFocus={true}
          // onFocus = {}
          // onClear = {} 
          />
          {isLoggingIn ?
            <Loading />
            :
            <Button
              label={'Submit'}
              onClick={handleSubmit}
            />
          }

        </section>
        <section style={styles.formFooter}>
          Kabir Corporation © 2022. All rights reserved.
        </section>
      </form>

      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}
export default Loginpage;


const styles = {
  container: {
    padding: 25,
    paddingTop: 50,
    boxSizing: 'border-box',
    color: APP_COLORS.SECONDARY_TEXT,
    minHeight: '100vh',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: APP_COLORS.META_BACKGROUND
   
  },
  formContainer: {
    width: "40%",
    padding: "30px",
    background: APP_COLORS.SECONDARY_BACKGROUND,
    borderRadius: '10px',
  },
  formHeader: {
    fontSize: '32px',
    fontWeight:'bold',
    padding: '20px',
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    padding: '20px 40px',
  },
  formFooter: {
    marginTop: '10px',
    padding: 20,
  }
}
