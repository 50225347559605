import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getCSVQueries, selectCSVCollection, addToCSVHotdeals } from "../store/csv";
import { APP_COLORS, pageStyles } from "../styles";
import Button from "./common/Button";
import { confirmAlert } from "./common/ConfirmationPrompt";

export default function CSVCollectionView() {
    let dispatch = useDispatch()
    const { id } = useParams();
    
    let { state } = useLocation();

    const [selectedRow, setSelectedRow] = useState(false)
    let collectionInfo = state.collection

    let data = useSelector((state)=> selectCSVCollection(state,id))
    console.log(data)

    useEffect(()=>{
        console.log(id)
        dispatch(getCSVQueries(id))
    },[])
    
    let { columns, rowsData } = data;
    if (!columns || !columns.length) {
    return null
    }
    return(
    <div 
        onClick={()=>{
            setSelectedRow(false)
        }}
        style={{color: APP_COLORS.SECONDARY_TEXT, padding:20, boxSizing:'border-box' }}>
     <div style={{display:'flex', justifyContent:'space-between', boxSizing:'border-box', }}>
     <div style={{  maxWidth:700, padding:'0px 0px 20px', fontSize:'14px', fontWeight:'bold', textTransform:'capitalize'}}>
      Showing {collectionInfo.name}
     </div>
    
     {selectedRow &&
     <div style={{paddingLeft:20}}>
        <Button
            label={`Move row ${selectedRow} to hot deals`}
            style={{ width:180, height:24, fontSize:'14px', padding:'0px 10px',  }}
            onClick={(e)=>{
                e.stopPropagation()
                e.preventDefault()
                confirmAlert({
                  title: 'Confirm Move To HotDeal',
                  onConfirm: () => {
                    dispatch(addToCSVHotdeals({id:id ,data:rowsData[selectedRow-1]}))
                  },
                  onReject: () => { },
              })
            }}
        />  
        </div>
     }
     </div>
     
            
     {rowsData.length > 0 && 
         <div style={{ alignItems: 'center', overflowX: 'scroll', width:' 88vw ', height:'85vh' }}>
            <HeaderRow 
            // page = {page}
            // sortBy={sortBy} 
            // sortDirection={sortDirection} 
            headerKeys={columns}
            // onClick={(index) => {
            //     if (index == sortBy) {
            //     if (sortDirection == -1) {
            //     setSortDirection(1)
            //     } else {
            //     setSortBy(-1)
            //     setSortDirection(-1)
            //     }
            //     }else {
            //     setSortBy(index)
            //     setSortDirection(-1)
            //     }
            // }}
            />
            {rowsData.map((tr, index) => 
            <DataRow 
                // page = {page}
                headerKeys={columns} 
                rowData={tr} 
                isSelected={ selectedRow == index + 1} 
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSelectedRow( index + 1)
                }}
            />)}
        </div>
      }
        </div>)
}


const HeaderRow = ({ headerKeys, onClick, sortBy, sortDirection }) => (
    <div 
    // className = {'tr'}
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      height: 40,
      marginTop: 1,
      position: 'sticky',
      top: 0,
      zIndex: 99,
    }}>
      {headerKeys.map((headerCell, index) => {
        let stickyCols = (index < 0 )
        let left = 0;
        left = calculateLeftPosition(headerKeys, index )
        let label = headerCell.label.replace(/_/gi, " ");
        return <div
          style={styles.headerLabel(headerCell.width, stickyCols, left)}
        //   onClick={() => onClick && onClick(headerCell.label)}
          >
        <div className = {'th'} 
            style={{ 
              width: '100%', 
              height: '100%', 
             }}>
            {label}  
            {/* ((sortBy == headerCell.label && index != 0) ? sortDirection == 1 ? " ⬇" : " ⬆" : "") */}
          </div>
        </div>
      })}
    </div>)


const DataRow = ({ headerKeys, rowData, isSelected, onClick,  }) => (
    <div
    //   className = {'tr'}
      onClick={onClick}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      {headerKeys.map(( headerCell, idx) => {
        let stickyCols = idx < 0 
        let left = 0;
        left = calculateLeftPosition(headerKeys, idx )
        let { width, label } = headerCell
        let cellData = rowData[label]
        if (!cellData) {
          return null;
        }
        let { text, value } = cellData
        return <div style={styles.cell(cellData, width, isSelected, stickyCols, left)} >
          {/* {label == 'url' && <a className = {'td'} href={value} target={"blank"}>{text}</a>} */}
          {label && <div 
            //   className = {'td'}  
          style={{ 
              width: '100%', 
              height: '100%', 
        }}  >{(value || "-")}</div>}
        </div>
      })}
    </div>)


const calculateLeftPosition = (headerKeys, index) => {
    let left = 0
    if(index == 0){
      return left
    }else {
        for( let i = index ; i > 0 ; i -- ){
          left = left + headerKeys [ index - i ].width
        }
    }
    return left 
  }

const styles = {
    headerLabel: (width, stickyCols, left ) => ({
      whiteSpace: 'nowrap',
      lineHeight: '40px',
      height: '100%',
      border: '0.5px solid #888',
      width,
      minWidth: width,
      maxWidth: width,
      overflow: 'hidden',
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      color: "#ccc",
      cursor: 'pointer',
      backgroundColor:  APP_COLORS.SECONDARY_BACKGROUND,
      textTransform: 'capitalize',
      left: stickyCols && left,  
      zIndex: stickyCols && 999, 
      position: stickyCols && 'sticky',
    }),
    cell: (data, width, isSelected, stickyCols, left) => ({
      whiteSpace: 'nowrap',
      lineHeight: '40px',
      height: '100%',
      border: '0.5px solid #888',
      width,
      minWidth: width,
      maxWidth: width,
      overflow: 'hidden',
      fontSize: 14,
      fontWeight: data.isBold ? 'bold' : 'normal',
      textAlign: 'center',
      color: data.color || "#ccc",
      backgroundColor: isSelected ? "#000" : stickyCols ? APP_COLORS.SECONDARY_BACKGROUND : data.backgroundColor || "",
      left: stickyCols && left,  
      zIndex: stickyCols && 3, 
      position: stickyCols && 'sticky' ,
      
    })
  }