import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {APP_COLORS} from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import Select from '../common/Select';
import {addToInventory, selectIsInventoried, selectIsAddingToInventory } from '../../store/query';


export default function AddToInventoryForm ({id, onClose , view}){
  const dispatch = useDispatch();

  let isInventoried = useSelector(state => selectIsInventoried(state,id))
  let isAddingInventory = useSelector(selectIsAddingToInventory) 

  let [inventoryRemarks, setInventoryRemarks ] = useState('');
  // let [inventoryStatus, setInventoryStatus] = useState('');

  const onAddToInventory = () => {
    let data = {
      // inventoryStatus, 
      inventoryRemarks, 
    }
    dispatch(addToInventory({id,data, view}))
  }

  useEffect(()=>{
    if(isInventoried){
      onClose()
    }
  },[isInventoried])

  return (
  <div style={{}}>
      <TextField 
        name={'inventory_remarks'}
        text = {inventoryRemarks} 
        type={'text'}
        placeholder = {'Add Remarks'} 
        onTextChange = {(target)=>{console.log(target.name, target.value); setInventoryRemarks(target.value)}} 
        onEnter = {()=>{}} 
        autoFocus = {true} 
        style = {{color:APP_COLORS.SECONDARY_TEXT}} 
        multiLine={true}
        // onCancel = {} 
        // onFocus = {}
        // onClear = {} 
      />
      {/* <Select
        onChange={(e) => setInventoryStatus(e.target.value)}
        name={'Status'}
        value={inventoryStatus}
        style={{ background: 'inherit', color: APP_COLORS.SECONDARY_TEXT, height: '35px', }}
        options=
        {[
          { id: 'interested_client', text: 'Interested Client', value: 'interested_client', },
          { id: 'hot_deal', text: 'Hot Deal', value: 'hot_deal', },
        ]}
       /> */}

      <div style={{display:"flex", justifyContent:"center",paddingTop:30}}>
      {isAddingInventory ?
      <Loader/> :
       <Button
       label={'Move to Inventory'}
       onClick={onAddToInventory}
       style={{textAlign:"center",padding:10, fontSize:"16px"}}
       />} 
      </div>
      
    </div>
  )
}