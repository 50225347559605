
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { APP_COLORS } from "../../styles"
import Button from "../common/Button"
import Select from "../common/Select"
import TextField from "../common/textField"
import { onBulkInventory, selectIsAddingBulkInventory } from "../../store/query"

export default function InventoryDialog({ items, inventoryManagers, }) {
    const dispatch = useDispatch()
    let [actionType, setActionType] = useState('auto')
    let [allocations, setAllocations] = useState([])
    let [total, setTotal] = useState(0)
    let isAddingBulkInventory = useSelector(selectIsAddingBulkInventory)

    useEffect(() => {
        if(inventoryManagers.length>0 ){
            let copyInventoryManagers = inventoryManagers.map(im => {
                return im = {...im, count:0}
            })
            setAllocations(copyInventoryManagers)
        }
    }, [inventoryManagers])

    const handleAllocation = (im, count) => {
        let copyAllocations = [...allocations]
        let index = copyAllocations.findIndex(a => a.id == im.id)
        copyAllocations[index]  = {
            ...copyAllocations[index],
            count : parseInt(count)
        }
        let total = copyAllocations.reduce((n, {count}) => n + count, 0)
        setTotal (total)
        setAllocations(copyAllocations)
    }

    return (
        <div style={{ padding: 0 }}>
            <div style={{ margin: '10px 0px' }}>
                {items?.length} items for inventory.
            </div>

            <Select
                onChange={(e) => {
                    setActionType(e.target.value)
                    setTotal(0)
                }}
                name={'action_type'}
                value={actionType}
                style={{ color: APP_COLORS.SECONDARY_TEXT, height: '35px' }}
                options=
                {[
                    { id: 'auto', text: 'Automatic Distribution', value: 'auto', },
                    { id: 'manual', text: 'Manually Allocate', value: 'manual', },
                ]}
            />
            <div style={{ marginTop: 20,}}>
                {actionType == 'auto' ?
                    <div>
                        This will automatically distribute the inventory to all inventory managers.
                    </div>
                    :
                    <div>
                        <div style={{ margin: '10px 0px' }}>
                            Allocate Inventory to the agents.
                        </div>

                         <div style={{height:'300px', overflow:'scroll', padding:10}}>   
                            {inventoryManagers.length && inventoryManagers.map(im => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '80%' }}>
                                        {im.name}
                                    </div>
                                    <TextField text={'0'} onTextChange={(target)=>{handleAllocation(im, target.value)}} style={{ width: '100px' }} type='number' />
                                </div>
                            ))}
                        </div>
                            <div style={{ fontWeight:'bold', boxSizing:'border-box', padding:10, width:'100%', display:'flex',  justifyContent:'space-between', }}>
                                <div> Total </div>
                                <div style={{color: total > items.length && 'red'  }}> {total} </div>
                            </div>
                        
                    </div>
                }
                <div style={{width:'100%', display:'flex', justifyContent:'center', paddingTop:'20px', boxSizing:'border-box'}}>
                    { !isAddingBulkInventory && ((actionType=='manual' && items.length == total) || actionType == 'auto')  
                        &&
                        <Button
                        style={{fontSize:'13px', width:'100px'}}
                        label={'PROCEED'}
                        onClick={()=>{
                            dispatch(onBulkInventory({actionType, items, allocations }))
                        }}
                    /> 
                    }   
                    {isAddingBulkInventory && 
                    <div> Processing please wait... </div>} 
                </div>
            </div>


        </div>)
}