import React, {  useState } from "react";
import { APP_COLORS } from "../../styles";


function Dropdown({
  id="id",
  button,
  onItemClick,
  dropDownItems,
  style,
  dropdownStyle,
  onOpen,
  onClose,
  headerSection,
  cellStyle,
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onDropdownButtonClick = (e) => {
    if (!dropdownOpen) {
      setTimeout(() => {
        window.addEventListener("click", onWindowClick);
      }, 100);
      onOpen && onOpen()
      setDropdownOpen(true);
    }
  };

  const onWindowClick = (e) => {
    if (!e.target.closest('.dropdown'+id)) {
      close()
    }
  };
  const close = () => {
    onClose && onClose()
    setDropdownOpen(false);
    window.removeEventListener("click", onWindowClick);
  }
  return <div
    className={"dropdown"+id}
    style={{ ...containerStyles, ...style }}
    onClick={onDropdownButtonClick}>
    {button}
    {dropdownOpen && <div style={{ ...dropDownStyles, ...dropdownStyle }}>
      {headerSection && 
        headerSection
      }
      {dropDownItems.map((item) => {
        if (item.key === "separator") {
          return <div style={separator} />;
        }
        if (item.type === "component") {
          return item.component;
        }
        return (
          <div style={{...cellStyles(item?.headerIcon), ...cellStyle}} className={"hovercell"} onClick={(e) => {
            setDropdownOpen(false);
            if (item.onClick) {
              item.onClick()
            } else if (onItemClick) {
              onItemClick(item)
            }
            onClose && onClose()
            close()
          }}>
            {item.headerIcon && <div style={{ display: 'flex', alignSelf: 'center', padding: '0px 10px 30px 10px' }}> {item.headerIcon} </div>}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
              {item.icon && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 18, height: 18 }}>{item.icon}</div>}
              {item.title}
              {item.subtitle && <>
                <br />
                <span style={{ fontSize: '12px' }}>
                  {item.subtitle}
                </span></>}
            </div>
          </div>
        );
      })}
    </div>}
  </div>
}

export default Dropdown;

const containerStyles = {
  position: 'relative',
  fontSize: "14px",
  color: "#444",
  display: 'flex',
  alignItems: 'center',
};

const dropDownStyles = {
  position: "absolute",
  // color: THEME.SECONDARY_TEXT, 
  zIndex:1,
  borderRadius: 5,
  top: 28,
  right: 0,
  overflow:'hidden',
  fontSize: 14,
  boxShadow: '0px 0px 30px rgba(0,0,0,0.9)',
};

const cellStyles = (hasHeaderIcon) => ({
  cursor: "pointer",
  padding:12,
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  // marginBottom: 1,
  background: APP_COLORS.SECONDARY_BACKGROUND,
  color:'white',
  minWidth: 170,
  display: 'flex',
  justifycontent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
});
const separator = {
  borderBottom: "1px solid rgb(220,220,220)",
};
