import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Outlet, useMatch } from 'react-router-dom';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import InventoryDialog from '../components/queries/InventoryDialog';
import Dialogue from '../components/common/Dialogue';
import { selectIM } from '../store/app';
import { selectUserData } from '../store/user';
import {
  selectAllQueries,
  selectMyQueries,
  selectQueries,
  getQueries,
  getAllQueries,
  getQueriesByUserId,
  selectIsFetchingMyQueries,
  selectIsFetchingQueries,
  selectIsFetchingAllQueries,
  selectAllQueriesSearchString,
  selectQueriesSearchString,
  selectMyQueriesSearchString,
  getQueriesForTeamLeads,
  onBulkInventory,
  fetchOldQueries,
  selectOldQueries,
  selectOldQueryString,
  selectIsFetchingOldQueries,
  getQueriesByDateRange
} from '../store/query';
import { ROLE, TABS } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';

import CSVQueries from '../components/queries/CSVQueries';

export default function Queries() {

  let isAddForm = useMatch('/dashboard/queries/add');
  let dispatch = useDispatch()
  let navigate = useNavigate()

  let [activeTab, setActiveTab] = useState(null)
  let allQueries = useSelector(selectAllQueries)
  let allQueriesSearchString = useSelector(selectAllQueriesSearchString)

  let queries = useSelector(selectQueries)
  let queriesSearchString = useSelector(selectQueriesSearchString)

  let myQueries = useSelector(selectMyQueries)
  let myQueriesSearchString = useSelector(selectMyQueriesSearchString)

  let oldQueries = useSelector(selectOldQueries)
  let oldQueriesString = useSelector(selectOldQueryString)

  let isFetchingQueries = useSelector(selectIsFetchingQueries)
  let isFetchingAllQueries = useSelector(selectIsFetchingAllQueries)
  let isFetchingMyQueries = useSelector(selectIsFetchingMyQueries)
  let isFetchingOldQueries = useSelector(selectIsFetchingOldQueries)

  let inventoryManagers = useSelector(selectIM)

  let userData = useSelector(selectUserData);
  let role = userData?.role;
  let [inventoryDialog, showInventoryDialog] = useState({ show: false, items: null })

  useEffect(() => {
    if (role && (role == ROLE.Admin)) {
      dispatch(getQueries())
      dispatch(getAllQueries())
      dispatch(fetchOldQueries())
      setActiveTab(TABS.QUERY.id);
    } else if (role && (role == ROLE.TeamLeads)) {
      dispatch(getQueriesForTeamLeads(''))
      setActiveTab(TABS.ALLQUERIES.id);
    }
    else if (role && (role == ROLE.Management || role == ROLE.PropertyManager )) {
      dispatch(getAllQueries())
      dispatch(fetchOldQueries())
      setActiveTab(TABS.ALLQUERIES.id);
    }else if( role == ROLE.DispositionManager || role == ROLE.InventoryManager   ){
      dispatch(fetchOldQueries())
      setActiveTab(TABS.OLDQUERIES.id);
    } 
    else {
      setActiveTab(TABS.MYQUERIES.id);
    }
    dispatch(getQueriesByUserId(userData?.id, '', role))
  }, [])



  const onAdminSearchEnter = (queryString) => {
    if (role && (role == ROLE.Admin)) {
      dispatch(getQueries(queryString))
    }
  }
  const onAllQuerySearchEnter = (queryString) => {
    if (role == ROLE.TeamLeads) {
      dispatch(getQueriesForTeamLeads(queryString))
    } else {
      dispatch(getAllQueries(queryString))
    }
  }
  const onMySearchEnter = (queryString) => {
    dispatch(getQueriesByUserId(userData?.id, queryString, role))
  }


  const onDateFilter = (start, end, view ) => {
    if(start && end){
      dispatch(getQueriesByDateRange({start,end,view, userId:userData?.id, role}))
    }else if(start==null && end==null){
      console.log(start, end)
      if(view == 'myQueries'){
        dispatch(getQueriesByUserId(userData?.id, '', role))
      }else if(view == 'allQueries'){
        dispatch(getAllQueries())
      }else if(view == 'queries'){
        dispatch(getQueries())
      }
    }
  }

  // const onOldQueriesSearchEnter = (queryString) => {
  //   dispatch(getQueriesByUserId( userData?.id, queryString))
  // }

  const Tab = ({ id, title, }) => {
    let isActive = id == activeTab;
    return (
      <div
        style={{
          color: APP_COLORS.SECONDARY_TEXT,
          padding: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          fontWeight: 'bold',
          borderBottom: isActive && `3px solid ${APP_COLORS.LINK_ORANGE}`
        }}
        onClick={() => setActiveTab(id)}>
        {title}
      </div>)
  }
  return (
    <div style={{ ...pageStyles, ...styles.container }}>
      <Section
        title={role == ROLE.VirtualRealtor ? "Queries (current month)" : 'Queries'}
        rightButtons={[
          !isAddForm && {
            onClick: () => { navigate('/dashboard/queries/add') },
            label: 'Add a Query'
          }
        ]}
        content={
          <>
            {!isAddForm &&
              <div style={{ display: 'flex', gap: 10, margin: 10 }}>
                {role == ROLE.Admin && <Tab id={TABS.QUERY.id} title={TABS.QUERY.label} />}
                {(role == ROLE.Admin || role == ROLE.PropertyManager || role == ROLE.Management) && <Tab id={TABS.ALLQUERIES.id} title={TABS.ALLQUERIES.label} />}
                {(role == ROLE.Admin || role == ROLE.PropertyManager || role == ROLE.DispositionManager ||  role == ROLE.Management || role == ROLE.InventoryManager ) && <Tab id={TABS.OLDQUERIES.id} title={TABS.OLDQUERIES.label} />}
                {(role == ROLE.Admin || role == ROLE.PropertyManager) && <Tab id = {TABS.CSVQUERIES.id} title = {TABS.CSVQUERIES.label} /> }
                <Tab id={TABS.MYQUERIES.id} title={TABS.MYQUERIES.label} />
              </div>
            }

            {
              !isAddForm &&
              role == ROLE.Admin &&
              activeTab == TABS.QUERY.id &&
              <QueryListView
                role={role}
                data={queries}
                activeTab={TABS.QUERY.id}
                isFetching={isFetchingQueries}
                onSearchEnter={onAdminSearchEnter}
                searchString={queriesSearchString}
                onBulkInventory={(items) => {
                  showInventoryDialog({ show: true, items: items })
                }}
                onDateFilter = {onDateFilter}
              />}

            {
              !isAddForm &&
              role != ROLE.VirtualRealtor &&
              activeTab == TABS.ALLQUERIES.id &&
              <QueryListView
                role={role}
                data={allQueries}
                activeTab={TABS.ALLQUERIES.id}
                isFetching={isFetchingAllQueries}
                onSearchEnter={onAllQuerySearchEnter}
                searchString={allQueriesSearchString}
                onDateFilter = {onDateFilter}
              />
            }

            {
              !isAddForm &&
              activeTab == TABS.MYQUERIES.id
              &&
              <QueryListView
                data={myQueries}
                role={role}
                activeTab={TABS.MYQUERIES.id}
                isFetching={isFetchingMyQueries}
                onSearchEnter={onMySearchEnter}
                searchString={myQueriesSearchString}
                onDateFilter = {onDateFilter}
              />}

            {oldQueries &&
              !isAddForm &&
              role != ROLE.VirtualRealtor &&
              activeTab == TABS.OLDQUERIES.id &&
              <QueryListView
                role={role}
                data={oldQueries}
                activeTab={TABS.OLDQUERIES.id}
                isFetching={isFetchingOldQueries}
              // onSearchEnter = {onOldQueriesSearchEnter}
              // searchString = {oldQueriesString}
              />
            }

           
            { !isAddForm &&
              activeTab == TABS.CSVQUERIES.id
              && 
                <CSVQueries />
              }

            {!!inventoryDialog && inventoryDialog.show &&
              <Dialogue
                headerText={'Bulk Inventory'}
                content={<InventoryDialog
                  items={inventoryDialog.items}
                  inventoryManagers={inventoryManagers}
                />}
                show={!!inventoryDialog}
                onClose={() => showInventoryDialog(null)}
              />}
            <Outlet />
          </>
        }
      />

    </div>
  )
}

const styles = {
  container: {

  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: '20px'
  },
  section: {
    margin: 20,
    padding: 15,
  },

}
