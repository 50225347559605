import React, { useState, useRef } from 'react';
import { ROLE } from '../../constants';
import { Link, useNavigate } from 'react-router-dom';
import { APP_COLORS } from '../../styles';
import Icon from '../common/Icon';
import Button from '../common/Button';
import Loader from '../common/Loading';
import { HOME_PAGE_ROUTE } from '../../routes';
import CommentsPanel from './CommentsPanel';

export default function QueryCard(
  { item,
    onOpenImageGallery,
    role,
    view,
    isSelected,
    onItemClick,
    onApproveClicked,
    onAddRemarks,
    onApproveContractClicked,
    onSendContractClicked,
    onSignContractClicked,
    onInventoryClicked,
    onMoveToHotDealsClicked,
    onMoveToInventoryContracts,
    onMoveToListing,
    onAddImages,
  }) {

  const navigate = useNavigate();
  let [activeProperty, setActiveProperty] = useState(0);
  let [loading, setLoading] = useState(true)

  let imageContainerRef = useRef()

  function imagesLoaded(parentNode) {
    const imgElements = [...parentNode.current.querySelectorAll("img")];
    for (let i = 0; i < imgElements.length; i += 1) {
      const img = imgElements[i];
      if (!img.complete) {
        return false;
      }
    }
    return true;
  }

  const handleImageChange = () => {
    setLoading({ loading: !imagesLoaded(imageContainerRef) })
  };

  const renderSpinner = () => {
    if (!loading) {
      // Render nothing if not loading
      return null;
    }
    return (
      <Loader />
    );
  }

  return (
    <div style={styles.item(isSelected)}
      onClick={(e) => {
        e.stopPropagation();
        onItemClick(item.id)
        console.log(item.id);
      }}
    >
      {(view == 'approved_queries') && role != ROLE.VirtualRealtor &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BLUE, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>  Approve Remarks:  {item.approved_remarks && item.approved_remarks} </div>
          <div> Approved By {item.approved_by?.role} @
            {item.approvedDateStr}
          </div>
        </div>
      }
      {(view == 'approved_contracts') && role != ROLE.VirtualRealtor &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BLUE, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>  Remarks:  {item.contract_approved_remarks && item.contract_approved_remarks} </div>
          <div> Contract Approved By {item.contract_approved_by?.firstname + ' ' + item.contract_approved_by?.lastname}  @
            {item.contractApprovedDateStr}
          </div>
        </div>
      }

      {(view == 'sent_contracts') && role != ROLE.VirtualRealtor &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BLUE, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>
            Contract Remarks:  {item.contract_sent_remarks && item.contract_sent_remarks}
          </div>
          <div> Contract Sent by {item.contract_sent_by?.firstname + ' ' + item.contract_sent_by?.lastname} @
            {item.contractSentDateStr}
          </div>
        </div>
      }
      {view == 'signed_contracts' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.GREEN, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>
            Sign Remarks:  {item.contract_signed_remarks && item.contract_signed_remarks}
          </div>
          <div> Contract Signed by {item.contract_signed_by?.firstname + ' ' + item.contract_signed_by?.lastname} @
            {item.contractSignedDateStr}
          </div>
        </div>
      }


      {view == 'inventory' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BORDER_COLOR, color: "white", fontStyle: "italic", }}>
          <div style={{ flex: 2 / 3 }}>
            <div> <b> Moved from</b>   {item.inventoried_from} </div>
            <div>  <b> Inventory Remarks: </b>   {item.inventory_remarks && item.inventory_remarks} </div>
          </div>
          <div>
            <div>  <b>Inventory Manager </b>   {item.inventory_manager && item.inventory_manager.name} </div>
          </div>
          <div> <b> Inventoried by</b>  {item.inventoried_by?.firstname + ' ' + item.inventoried_by?.lastname} @
            {item.inventoriedDateStr}
          </div>
        </div>
      }

      {view == 'hot_deals' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.RED, color: "white", fontStyle: "italic", }}>
          <div>  <b>Property Manager </b>   {item.property_manager ? item.property_manager.name : 'NA'} </div>
          <div> <b> Moved By</b>  {item.hot_dealed_by?.firstname + ' ' + item.hot_dealed_by?.lastname} @
            {item.inventoryHotdealedDateStr}
          </div>
        </div>
      }
      {view == 'listings' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.BUTTON_BACKGROUND, color: "white", fontStyle: "italic", }}>
          <div> <b> Moved By</b>  {item.listing_by?.firstname + ' ' + item.listing_by?.lastname} @
            {item.listingDateStr}
          </div>
        </div>
      }
      {view == 'inventory_contracts' &&
        <div style={{ ...styles.infoHeader(true), background: APP_COLORS.GREEN, color: 'white', fontStyle: "italic", }}>
          <div>
            <div><b>Remarks: </b> {item.inventory_contract_submitted_remarks && item.inventory_contract_submitted_remarks} </div>
          </div>
          <div><b> Moved By</b>  {item.inventory_contract_submitted_by?.firstname + ' ' + item.inventory_contract_submitted_by?.lastname} @
            {item.inventoryContractSubmittedDateStr}
          </div>
        </div>
      }

      <div style={styles.header}>
        <header style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: 10 }}>
          <div style={{ fontSize: '12px', fontWeight: 'bolder' }}>
            Properties
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: 5 }}>
            {item.properties?.length > 1 && item.properties?.map((item, idx) => (
              <div
                key={idx}
                onClick={() => { setActiveProperty(idx) }}
                style={{
                  padding: 5,
                  height: '10px',
                  width: '10px',
                  background: activeProperty == idx ? APP_COLORS.LINK_ORANGE : 'none',
                  border: `1px solid ${APP_COLORS.LINK_ORANGE}`,
                  borderRadius: '50%',
                  textAlign: 'center',
                  color: activeProperty == idx ? 'white' : '',
                  cursor: 'pointer',
                  fontSize: '9px'
                }}>
                {idx + 1}
              </div>
            ))
            }
          </div>
        </header>
        <div style={{display:'flex', gap:10,}}>
        {role != ROLE.VirtualRealtor && view != 'oldQueries' &&
        <Link 
          to={ HOME_PAGE_ROUTE+'/query/' + item.id}
          state = {{item, view}}
          style={{textDecoration:'none'}}
        >
        <Button 
          label={'open fullview'} 
          icon={{family:'MaterialCommunityIcons',name:'fullscreen'}}
          style={{fontSize:'12px', padding: '4px 10px'}}
        />
        </Link>
        }
        <div>
        {item.dateStr}
        </div>
        </div>
      </div>

      <div style={styles.body}>

        <section style={{ paddingTop: '5px', width: '60%' }}>
          {item.properties ? item.properties?.length && item.properties.filter((p, i) => { return i == activeProperty }).map(p => (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", gap: 10 }}>
              <div style={{ width: '80%' }}>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Address </div>
                  {role == ROLE.VirtualRealtor ?
                    <div style={styles.content}>{p.address.split(',').pop()}</div>
                    :
                    <div style={styles.content}>{p.address}</div>
                  }
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> State </div>
                  <div style={styles.content}>{p.state.label}</div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Condition </div>
                  <div style={styles.content}>{p.condition}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Area of Property </div>
                  <div style={styles.content}>{p.area}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Occupation </div>
                  <div style={styles.content}>{p.occupation}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Moving TimeOff </div>
                  <div style={styles.content}>{p.moving_toff}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Offered Price </div>
                  <div style={styles.content}>{p.price}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Client Demand  </div>
                  <div style={styles.content}>{p.asking_price}</div>
                </div>


                <div style={styles.pane}>
                  <div style={styles.subHeading}> Zillow Price </div>
                  <div style={styles.content}>{p.zestimate}</div>
                </div>

                {role !== ROLE.VirtualRealtor &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Links </div>

                    <div style={{ maxWidth: 300 }}>
                      {p.links?.length && p.links.map(link => (
                        <div style={styles.content}>
                          <a href={link} style={{ color: APP_COLORS.BUTTON_BACKGROUND, opacity: 0.8, }} target='_blank'>{link} </a>
                        </div>
                      ))}
                    </div>
                  </div>
                }

                <div style={styles.pane}>
                  <div style={styles.subHeading}> Floor Agent Remarks </div>
                  <div style={styles.content}>{p.remarks}</div>
                </div>
                <br />

                {item.management_remarks && role !== ROLE.VirtualRealtor &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Management Remarks </div>
                    <div style={{ width: '100%' }}>
                      {item.management_remarks}
                    </div>
                  </div>
                }
                {view == 'approved_queries' && item.contracter_remarks && role !== ROLE.VirtualRealtor &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Contractor Remarks </div>
                    <div style={{ width: '100%' }}>
                      {item.contracter_remarks}
                    </div>
                  </div>
                }
                {(view == 'sent_contracts' || view == 'signed_contracts') && item.property_manager_remarks && role !== ROLE.VirtualRealtor &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Property Manager Remarks </div>

                    <div style={{ width: '100%' }}>
                      {item.property_manager_remarks}
                    </div>
                  </div>
                }
                {(view == 'inventory') && item.inventory_manager_remarks && role !== ROLE.VirtualRealtor &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Inventory Manager Remarks </div>
                    <div style={{ width: '100%', whiteSpace: 'pre-wrap', }}>
                      {item.inventory_manager_remarks}
                    </div>
                  </div>
                }
                
              </div>


              <div ref={(element) => imageContainerRef = element}
                style={{
                  width: '10%',
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: 10,
                  height: '180px',
                }}>
                {p.images?.map((img, idx) => (
                  <div>
                    <img key={img}
                      // onLoad={() => handleImageChange()} 
                      style={{ width: '50px', height: '50px', marginRight: '10px' }}
                      src={img} onClick={() => { onOpenImageGallery(idx, p.images) }} />
                  </div>
                ))}
              </div>



            </div>
          )) :

            //FOR OLD QUERIES
            //HAVE LESS DATA AND FIELDS
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", gap: 10 }}>
              <div style={{ width: '80%' }}>
                <div style={styles.pane}>
                  <div style={styles.subHeading}> Address </div>
                  <div style={styles.content}>{item.address}</div>
                </div>

                <div style={styles.pane}>
                  <div style={styles.subHeading}> State </div>
                  <div style={styles.content}>{item.state}</div>
                </div>

                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Condition </div>
                    <div style={styles.content}>{item.condition}</div>
                  </div>
                }
                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Area of Property </div>
                    <div style={styles.content}>{item.area}</div>
                  </div>
                }
                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Occupation </div>
                    <div style={styles.content}>{item.occupation}</div>
                  </div>
                }
                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Moving TimeOff </div>
                    <div style={styles.content}>{item.moving_toff}</div>
                  </div>
                }
                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Offered Price </div>
                    <div style={styles.content}>{item.offered_price}</div>
                  </div>
                }
                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Client Demand  </div>
                    <div style={styles.content}>{item.asking_price}</div>
                  </div>
                }
                {(role !== ROLE.VirtualRealtor && role !== ROLE.TeamLeads) &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Zillow Price </div>
                    <div style={styles.content}>{item.zestimate}</div>
                  </div>
                }

                {role !== ROLE.VirtualRealtor &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Floor Agent Remarks </div>
                    <div style={styles.content}>{item.agent_remarks}</div>
                  </div>
                }
                <br />


              </div>

            </div>
          }
        </section>

        <section style={{ paddingTop: 10, width: '40%', }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }}>


            {role !== ROLE.VirtualRealtor &&
              <div>
                <div style={styles.heading}>CLIENT INFO</div>
                <div style={{ paddingLeft: 10 }}>


                  <div style={styles.pane}>
                    <div style={styles.subHeading}> Phone </div>
                    <div style={styles.content}>{item.phone_no ? item.phone_no : 'NA'} </div>
                  </div>


                  <div style={styles.pane}>
                    <div style={styles.subHeading}>Name</div>
                    <div style={styles.content}>{item.fullname ? item.fullname : 'NA'}</div>
                  </div>

                  {(role !== ROLE.TeamLeads) &&
                    <div style={styles.pane}>
                      <div style={styles.subHeading}>Email</div>
                      <div style={styles.content}>{item.email ? item.email : 'NA'} </div>
                    </div>
                  }

                  {(role !== ROLE.TeamLeads) &&
                    <div style={styles.pane}>
                      <div style={styles.subHeading}>Mailing Address</div>
                      <div style={styles.content}>{item.mailing_address ? item.mailing_address : 'NA'} </div>
                    </div>
                  }
                </div>
              </div>
            }

            <div>
              <div style={styles.heading}>AGENT INFO</div>
              <div style={{ paddingLeft: 10 }}>

                {item.alias &&
                  <div style={styles.pane}>
                    <div style={styles.subHeading}>Agent Alias</div>
                    <div style={styles.content}>{item.alias} </div>
                  </div>
                }
                <div style={styles.pane}>
                  <div style={styles.subHeading}>Agent Name</div>
                  <div style={styles.content}>{item.old_query ? item.added_by : item.added_by.firstname} {item.added_by.lastname} </div>
                </div>

                {!item.old_query && <>
                  <div style={styles.pane}>
                    <div style={styles.subHeading}>Agent Role</div>
                    <div style={styles.content}>{item.added_by.role}</div>
                  </div>

                  <div style={styles.pane}>
                    <div style={styles.subHeading}>Office</div>
                    <div style={styles.content}>{item.office}</div>
                  </div>
                </>}

              </div>
            </div>

          </div>
        </section>

        {role != ROLE.VirtualRealtor &&
          <section style={{
            padding: '15px 5px',
            width: '20%',
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            gap: 10
          }}>
            {(view == 'queries' && role == ROLE.Admin) &&
              <>
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Inventory'}
                  onClick={() => onInventoryClicked(item.id, view)}
                  icon={{ name: 'folder2-open', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.LINK_ORANGE }}
                />
                <Button
                  label={'Approve'}
                  onClick={() => onApproveClicked(item.id)}
                  icon={{ name: 'check-circle', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.GREEN }}
                />
              </>
            }
            {(view == 'allQueries' && role == ROLE.Management) &&
              <>
                <Button
                  label={item.management_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.management_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
              </>
            }
            {view == 'allQueries' && role != ROLE.TeamLeads &&
              <>
                <Icon name='check-circle' family='BootstrapIcons' styles={styles.statusIcon(item.approved || item.contract_approved || item.contract_sent || item.contract_signed)} />
                <Icon name='receipt' family='BootstrapIcons' styles={styles.statusIcon(item.contract_sent || item.contract_signed)} />
                <Icon name='file-signature' family='FontAwesome5' styles={styles.statusIcon(item.contract_signed)} />
                <Icon name='folder2-open' family='BootstrapIcons' styles={styles.statusIcon(item.inventory || item.hot_deal)} />
                {item.inventory && <div> {item.inventory_manager.name} </div>}
                <Icon name='hotjar' family='FontAwesome5Brands' styles={styles.statusIcon(item.hot_deal || item.inventory_contract_submitted)} />
              </>
            }
            {(view == 'myQueries' && role !== ROLE.VirtualRealtor) &&
              <>
                <Icon name='check-circle' family='BootstrapIcons' styles={styles.statusIcon(item.approved || item.contract_approved || item.contract_sent)} />
                <Icon name='receipt' family='BootstrapIcons' styles={styles.statusIcon(item.contract_sent || item.contract_signed)} />
                <Icon name='file-signature' family='FontAwesome5' styles={styles.statusIcon(item.contract_signed)} />
              </>
            }

            {(view == 'approved_queries' && (role == ROLE.Contractor)) &&
              <>
                <Button
                  label={item.contracter_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.contracter_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Approve Contract'}
                  onClick={() => onApproveContractClicked(item.id)}
                  icon={{ name: 'text-box-check', family: 'MaterialCommunityIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.GREEN, }}
                />
                <Button
                  label={'Inventory'}
                  onClick={() => onInventoryClicked(item.id, view)}
                  icon={{ name: 'folder2-open', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.LINK_ORANGE }}
                />
              </>
            }

            {(view == 'approved_queries' && (role == ROLE.Admin)) &&
              <>
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.GREEN }}
                />
                <Button
                  label={'Inventory'}
                  onClick={() => onInventoryClicked(item.id, view)}
                  icon={{ name: 'folder2-open', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.LINK_ORANGE }}
                />
              </>
            }

            {(view == 'approved_contracts' && (role == ROLE.Admin)) &&
              <>
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Send Contract'}
                  onClick={() => onSendContractClicked(item.id)}
                  icon={{ name: 'mail-bulk', family: 'FontAwesome5' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.GREEN, }}
                />
                <Button
                  label={'Inventory'}
                  onClick={() => onInventoryClicked(item.id, view)}
                  icon={{ name: 'folder2-open', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.LINK_ORANGE }}
                />
              </>
            }

            {(view == 'sent_contracts' && (role == ROLE.Admin || role == ROLE.PropertyManager)) &&
              <>
                <Button
                  label={item.property_manager_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.property_manager_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Contract Signed'}
                  onClick={() => onSignContractClicked(item.id)}
                  icon={{ name: 'file-signature', family: 'FontAwesome5' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.GREEN, }}
                />
                <Button
                  label={'Inventory'}
                  onClick={() => onInventoryClicked(item.id, view)}
                  icon={{ name: 'folder2-open', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.LINK_ORANGE }}
                />
              </>
            }

            {/* {(view == 'sent_contracts' && (role == ROLE.Contractor )) &&
            <>
              <Button 
                label = {'Add Images'}
                onClick={() => onAddImages(item.id, activeProperty)}
                icon={{ name: 'images', family: 'BootstrapIcons' }}
                style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.INVENTORY }}
              />
            </>
          }  */}

            {(view == 'signed_contracts' && (role == ROLE.Admin || role == ROLE.PropertyManager)) &&
              <>
                <Button
                  label={item.property_manger_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.property_manger_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                {/* <Button
                  label={'Inventory'}
                  onClick={() => onInventoryClicked(item.id, view)}
                  icon={{ name: 'folder2-open', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.LINK_ORANGE }}
                /> */}

              </>
            }
            {/* {(view == 'signed_contracts' && (role == ROLE.Contractor )) &&
            <>
              <Button 
                label = {'Add Images'}
                onClick={() => onAddImages(item.id)}
                icon={{ name: 'images', family: 'BootstrapIcons' }}
                style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.INVENTORY }}
              />
            </>
          }  */}

            {(view == 'inventory' && (role == ROLE.InventoryManager)) &&
              <>
                <Button
                  label={item.inventory_manager_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.inventory_manager_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Move to Hot-Deals'}
                  onClick={() => onMoveToHotDealsClicked(item.id, item.inventory_manager_remarks)}
                  icon={{ name: 'hotjar', family: 'FontAwesome5Brands' }}
                  style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.RED, }}
                />
                <Button
                  label={'Move to Listings'}
                  onClick={() => onMoveToListing(item.id, item.inventory_manager_remarks)}
                  icon={{ name: 'receipt', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.RED, }}
                />
              </>
            }


            {(view == 'inventory' && (role == ROLE.Admin)) &&
              <>
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Move to Hot-Deals'}
                  onClick={() => onMoveToHotDealsClicked(item.id, item.inventory_manager_remarks)}
                  icon={{ name: 'hotjar', family: 'FontAwesome5Brands' }}
                  style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.RED, }}
                />
                <Button
                  label={'Move to Listings'}
                  onClick={() => onMoveToListing(item.id, item.inventory_manager_remarks)}
                  icon={{ name: 'receipt', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.BUTTON_BACKGROUND, }}
                />
              </>
            }
            
            {view == 'hot_deals' && role == ROLE.InventoryManager && 
              <>
              <Button
                  label={item.inventory_manager_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.inventory_manager_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                </>
            }
            {view == 'hot_deals' && role == ROLE.DispositionManager && 
              <>
              <Button
                  label={item.dispo_manager_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.dispo_manager_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                 <Button
                  label={'Inventory Contract'}
                  onClick={() => onMoveToInventoryContracts(item.id)}
                  icon={{ name: 'mail-bulk', family: 'FontAwesome5' }}
                  style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.GREEN, }}
                />
              </>
            }

            {(view == 'hot_deals' && (role == ROLE.PropertyManager || role == ROLE.Admin )) &&
              <>
                <Button
                  label={item.property_manager_remarks_inventory ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.property_manager_remarks_inventory)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Inventory Contract'}
                  onClick={() => onMoveToInventoryContracts(item.id)}
                  icon={{ name: 'mail-bulk', family: 'FontAwesome5' }}
                  style={{ fontSize: "10px", padding: 10, width: "80px", color: 'white', background: APP_COLORS.GREEN, }}
                />
              </>
            }

            {(view == 'inventory_contracts') && (role == ROLE.DispositionManager) && 
            <>
              <Button
                  label={item.dispo_manager_remarks ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.dispo_manager_remarks)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
            </>}

            {(view == 'inventory_contracts' && (role == ROLE.PropertyManager || role == ROLE.Admin)) &&
              <>
                <Button
                  label={item.property_manager_remarks_inventory ? 'Edit' : 'Add'}
                  onClick={() => onAddRemarks(item.id, view, item.property_manager_remarks_inventory)}
                  icon={{ name: 'chat-right-quote-fill', family: 'BootstrapIcons' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.BLUE }}
                />
                <Button
                  label={'Edit'}
                  onClick={() => { navigate('/dashboard/edit/' + item.id, { state: { item, view } }) }}
                  icon={{ name: 'pencil-square', family: 'BootstrapIcons' }}
                  style={{ fontSize: '10px', padding: 10, width: '60px', color: 'white', background: APP_COLORS.BLUE }}
                />
                 <Button
                  label={'Contract Signed'}
                  onClick={() => onSignContractClicked(item.id)}
                  icon={{ name: 'file-signature', family: 'FontAwesome5' }}
                  style={{ fontSize: "10px", padding: 10, width: "60px", color: 'white', background: APP_COLORS.GREEN, }}
                />
              </>
            }
          </section>}

      </div>

     
      {(view == 'hot_deals' || view == 'inventory_contracts')  && (item.hot_dealed_remarks || item.inventory_manager_remarks ) &&
        <div style={{ padding: 10, marginBottom: '10px' }}>
          <div style={{ fontSize: '14px', paddingBottom: 5, fontWeight: 'bolder', }}>{'Inventory Manager Comments:'}  </div>
          <div style={{ fontSize: '13px', lineHeight: 1.3, whiteSpace: 'pre-wrap', }}> {item.inventory_manager_remarks } </div>
        </div>
      }
      {(view == 'hot_deals' || view == 'inventory_contracts') && item.dispo_manager_remarks &&
        <div style={{ padding: 10, marginBottom: '10px' }}>
          <div style={{ fontSize: '14px', paddingBottom: 5, fontWeight: 'bolder', }}>{'Disposition Manager Comments:'} </div>
          <div style={{ fontSize: '13px', lineHeight: 1.3, whiteSpace: 'pre-wrap', }}> {item.dispo_manager_remarks} </div>
        </div>
      }
      {(view == 'hot_deals' || view == 'inventory_contracts') && item.property_manager_remarks_inventory && role !== ROLE.VirtualRealtor &&
        <div style={{ padding: 10, marginBottom: '10px' }}>
          <div style={{ fontSize: '14px', paddingBottom: 5, fontWeight: 'bolder', }}> Property Manager Remarks </div>
          <div style={{ fontSize: '13px', lineHeight: 1.3,  whiteSpace: 'pre-wrap', }} >
            {item.property_manager_remarks_inventory}
          </div>
        </div>
      }
      {/* {view == 'hot_deals' &&   
        <CommentsPanel queryId = {item.id} />
      } */}

    </div>
  )
}

const styles = {
  item: (isSelected) => ({
    background: APP_COLORS.META_BACKGROUND,
    width: '100%',
    minHeight: '200px',
    boxSizing: "border-box",
    color: APP_COLORS.PRIMARY_TEXT,
    margin: '15px 5px',
    fontSize: "12px",
    borderRadius: '5px',
    border: isSelected ? `1px solid ${APP_COLORS.LINK_ORANGE}` : '',

  }),
  header: {
    height: '30px',
    width: '100%',
    padding: '5px 10px',
    boxSizing: "border-box",
    borderBottom: `1px solid ${APP_COLORS.PAGE_BACKGROUND}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: "center",
    color: APP_COLORS.SECONDARY_TEXT,
    gap: 20
  },
  body: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    alignContent: "center",
    padding: '5px 10px',
    boxSizing: "border-box",
    gap: 10,
  },
  infoHeader: (isTop) => ({
    minHeight: '30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "space-between",
    borderTopLeftRadius: isTop && '5px',
    borderTopRightRadius: isTop && '5px',
    padding: "5px 10px",
    boxSizing: "border-box",
  }),
  heading: {
    paddingBottom: 5,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    color: APP_COLORS.PRIMARY_TEXT
  },
  subHeading: {
    minWidth: '120px',
    fontSize: '11px',
    fontWeight: 'bold',
    color: APP_COLORS.SECONDARY_TEXT
  },
  content: {
    marginLeft: 5,
    whiteSpace: "no-wrap",
    overflow: ' hidden',
    textOverflow: 'ellipsis',
    color: '#ddd'
  },
  optionIconStyle: {
    fontSize: '18px',
    color: APP_COLORS.LINK_ORANGE,
  },
  pane: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    // borderBottom: `1px solid ${APP_COLORS.PAGE_BACKGROUND}`,
    padding: 1,
  },
  actionPane: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: "center",
    gap: 2,
  },
  statusIcon: (achieved) => ({
    color: achieved ? "#1AA260" : APP_COLORS.PRIMARY_TEXT,
    fontSize: achieved ? "26px" : "20px",
    cursor: 'default'
  })
}