import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/common/Section';
import QueryListView from '../components/queries/QueryListView';
import { selectUserData } from '../store/user';
import { getInventoryContracts, selectInventoryContracts, selectInventoryContractsSearchString, selectIsFetchingInventoryContracts} from '../store/query';
import { ROLE } from '../constants';
import { APP_COLORS, pageStyles } from '../styles';
import {  Outlet } from 'react-router-dom';

export default function InventoryContracts ({}){
  const dispatch = useDispatch();
  let role = useSelector(selectUserData)?.role;
  let searchString = useSelector(selectInventoryContractsSearchString)
  let inventoryContracts = useSelector(selectInventoryContracts);
  let isFetching = useSelector(selectIsFetchingInventoryContracts);

  useEffect(() => {
    if (role && (role == ROLE.Admin || role == ROLE.InventoryManager || role ==  ROLE.PropertyManager || role == ROLE.DispositionManager)) {
      dispatch(getInventoryContracts())
    }
  }, [])
  
  const onSearchEnter = (queryString) => {
    console.log('Inventory COntracts', queryString)
    dispatch(getInventoryContracts(queryString))
  }
  return (<div style={{ ...pageStyles, ...styles.container }}>
    <Section
      title={'Inventory Contracts'}
      content={
        <>
        {inventoryContracts && <QueryListView 
        role={role} 
        data={inventoryContracts}  
        isFetching = {isFetching} 
        onSearchEnter={onSearchEnter}
        searchString = {searchString}
        /> } 
        <Outlet/>
        </>
      }
    />

</div>)
}

const styles = { 
  container:{
   
},
}

