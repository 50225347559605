
import { APP_COLORS } from "../../styles"

export default function CommentItem ({comment, index}) {
    
    return ( <div style={styles.commentItem(index)}>
                <div style={ styles.senderInfo}>
                    <div>
                      {comment.sender.alias || comment.sender.name}
                    </div>                       
                </div> 

                <div style={styles.commentContent}>
                <div style={styles.metaData}>
                
                <div style={{textTransform:'capitalize', fontSize:10, opacity:0.8}}>
                    {comment.sender.role.replace('_', ' ') } ~  {comment.dateStr}
                </div>
                
                </div>
                <div style={styles.text}>
                {comment.text}
                </div>
                </div>
                
                
            </div>
    )
}

const styles = {
    commentItem : index => ({
        display:'flex',
        alignItems:'center',
        
        gap:10,
        boxSizing:'border-box',
    }),
    senderInfo:{
        width: 60,
        display:'flex',
        flexDirection: 'column',
        gap:1,
        justifyContent:'center'

    },
    commentContent:{
        display:'flex',
        flexDirection: 'column',
        width:'100%',
        padding: '10px 0px',
        borderBottom: `0.1px solid #555`,
    },
    metaData:{
        alignSelf:'flex-end'
    }
}