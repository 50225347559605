import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {APP_COLORS} from '../../styles';
import TextField from '../common/textField';
import Button from '../common/Button';
import Loader from '../common/Loading';
import Select from '../common/Select';
import {moveToInventoryContracts, selectIsAddedToInventoryContracts, selectIsAddingToInventoryContracts } from '../../store/query';


export default function MoveToInventoryContracts ({id, onClose , view}){
  const dispatch = useDispatch();

  let isAdded = useSelector(state => selectIsAddedToInventoryContracts(state,id))
  let isAdding = useSelector(selectIsAddingToInventoryContracts) 

  let [remarks, setRemarks ] = useState('');
  // let [inventoryStatus, setInventoryStatus] = useState('');

  const onMove = () => {
    dispatch(moveToInventoryContracts({id,remarks}))
  }

  useEffect(()=>{
    if(isAdded){
      onClose()
    }
  },[isAdded])

  return (
  <div style={{}}>
      <TextField 
        name={'Move to Inventory Contracts'}
        text = {remarks} 
        type={'text'}
        placeholder = {'Add Remarks'} 
        onTextChange = {(target)=>{console.log(target.name, target.value); setRemarks(target.value)}} 
        onEnter = {()=>{}} 
        autoFocus = {true} 
        style = {{color:APP_COLORS.SECONDARY_TEXT}} 
        multiLine={true}
        // onCancel = {} 
        // onFocus = {}
        // onClear = {} 
      />
      

      <div style={{display:"flex", justifyContent:"center",paddingTop:30}}>
      {isAdding ?
      <Loader/> :
       <Button
       label={'Move to Inventory Contracts'}
       onClick={onMove}
       style={{textAlign:"center",padding:10, fontSize:"16px"}}
       />} 
      </div>
      
    </div>
  )
}